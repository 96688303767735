import React, { Fragment } from "react";
import {
  List,
  TextField,
  ShowButton,
  EditButton,
  DeleteButton,
  Datagrid,
  Filter,
  TextInput,
  ReferenceField,
  DateField,
  ReferenceInput,
  SelectInput
} from "react-admin";
import { MoneyField, CustomList, CustomField, Avatar } from "../../components/com";
const types = [
  { id: "general", name: "Genral" },
  { id: "express", name: "Express" },
];

/* const Filters = (props) => (
  <Filter {...props}>
    {<ReferenceInput source="order_status_id" reference="orders-status" alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>}
  </Filter>
) */
const AuthorsList = (props) => {
  return (
    <Fragment>
      <h2>Autores</h2>
      <List
        sort={{
          field: "createdAt",
          order: "DESC"
        }}
        /* filters={<Filters />} */
        source={props.source || "authors"}
        {...props}
        exporter={false}
        /*  filterDefaultValues={{ parent_id: 1 }} */
        bulkActionButtons={false}
      >
        <Datagrid>
          <TextField label="ID" source="id" />
          <Avatar shape="circle" label="Foto" source="profile_picture" />
          <TextField label="Nombre" source="name" />
          <ReferenceField label="Usuario" source="user_id" reference="users">
            <CustomField source="first_name" render={(record, source) => (<span>{`${record[source]} ${record['last_name']}`}</span>)} />
          </ReferenceField>
          <ReferenceField label="Email" source="user_id" reference="users">
            <CustomField source="email" />
          </ReferenceField>
          <EditButton label={"Editar"} />
          {/* <DeleteButton label={"Eliminar"} /> */}
        </Datagrid>
      </List>
    </Fragment>
  );
};

export default AuthorsList;
