import React, { Fragment } from "react";
import {
  TextField,
  ShowButton,
  Filter,
  TextInput,
  ReferenceField,
  DateField,
  ReferenceInput,
  SelectInput,
  BooleanField,
  BooleanInput
} from "react-admin";
import { MoneyField, CustomList, CustomField, StatusField } from "../../components/com";
import { money } from "../../utils/Helper"
import m from "moment";
const types = [
  { id: "general", name: "Genral" },
  { id: "express", name: "Express" },
];
const CustomBooleanInput = ({ record, source, ...props }) => {
  record[source] = record[source] === "true";
  return (<BooleanInput
    {...props}
    source={source}
    record={record}
    sort={false}
    source={source}
    label="Recurrente" />)
}
const CustomBooleanField = ({ record, source, ...props }) => {
  record[source] = record[source] === "true";

  return (<BooleanField
    {...props}
    source={source}
    record={record}
    sort={false}
    source={source}
    label="Recurrente" />)
}
const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Id Pedido" source="id" alwaysOn />
    {<ReferenceInput
      label="Estado de Pedio"
      source="order_status_id"
      reference="orders-status"
      /*  filter={{ type: "general" }} */
      alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>}
    {/* <CustomBooleanInput
      source="recurrent"
      label="Recurrente"
      alwaysOn
    /> */}
    <TextInput label="Buscar" source="data_value" alwaysOn />
  </Filter>
)
const OrdersList = (props) => {
  return (
    <Fragment>
      <h2>Todos los pedidos</h2>
      <CustomList
        {...props}
        sort={{
          field: "createdAt",
          order: "DESC"
        }}
        filters={<Filters />}
        source={props.source || "orders"}
      >
        <TextField label="ID" source="id" />
        <ReferenceField label="Usuario" source="user_id" reference="users">
          <CustomField source="first_name"
            render={(record, source) => (<span>{`${record[source]} ${record['last_name']}`}</span>)} />
        </ReferenceField>

        <ReferenceField label="Email" source="user_id" reference="users">
          <CustomField source="email" />
        </ReferenceField>
        {/*<CustomBooleanField sort={false} source="recurrent" label="Recurrente" /> */}
        <ReferenceField sortable={false} label="Estado" source="order_status_id"
          reference="orders-status">
          <StatusField source="name" />
        </ReferenceField>
        <TextField label="Metodo de pago" source="payment_method" />
        <MoneyField label="Total" source="total_price" />
        <MoneyField label="Pago recibido" source="total_payment_received" />
        <CustomField label="Pago pendiente" source="total_payment_pending"
          render={(record, source) => {
            let value = record[source];
            if (value > 0)
              return <span style={{
                color: "red"
              }}>{`${money(record[source])}`}</span>
            return (<span>{`${money(record[source])}`}</span>);
          }}
        />
        <CustomField label="Fecha" source="createdAt" render={(r, s) => m(r[s]).format('DD-MM-YYYY')} showTime />
        <ShowButton label={"Ver"} />
      </CustomList>
    </Fragment>
  );
};

export default OrdersList;
