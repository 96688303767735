import React, { Fragment } from "react";
import {
  List,
  TextField,
  ShowButton,
  EditButton,
  DeleteButton,
  Datagrid,
  Filter,
  TextInput,
  ReferenceField,
  DateField,
  ReferenceInput,
  SelectInput
} from "react-admin";
import { MoneyField, CustomList, CustomField, Avatar } from "../../components/com";
const types = [
  { id: "general", name: "Genral" },
  { id: "express", name: "Express" },
];

/* const Filters = (props) => (
  <Filter {...props}>
    {<ReferenceInput source="order_status_id" reference="orders-status" alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>}
  </Filter>
) */
const PolygonsList = (props) => {
  return (
    <Fragment>
      <h2>Zonas de Envíos</h2>
      <List
        sort={{
          field: "createdAt",
          order: "DESC"
        }}
        /* filters={<Filters />} */
        {...props}
        source={"shipping-costs"}
        exporter={false}
        /*  filterDefaultValues={{ parent_id: 1 }} */
        bulkActionButtons={false}
      >
        <Datagrid>
          <TextField label="ID" source="id" />
          <TextField label="Zona" source="name" />
          <ReferenceField label="Ciudad" source="city_id" reference="locations-cities">
            <CustomField source="name" />
          </ReferenceField>
          <MoneyField label="Precio" source="price" />
          <EditButton label={"Editar"} />
          <DeleteButton label={"Eliminar"} />
        </Datagrid>
      </List>
    </Fragment>
  );
};

export default PolygonsList;
