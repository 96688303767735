import React, { useState, useEffect } from "react";
import { Row, Col, Button, Tag, message, Modal, Icon } from "antd";
import { getService } from "../../services/";
import { money } from "../../utils/Helper";
import { Avatar, HeadLine, Grid } from "../../components/com";
import UserCard from "../../components/UserCard";
import HistoryTable from "../../components/orders/HistoryTable";
import Shipping from "./Shipping";
import ShippingDetail from "./ShippingDetail";
import ButtonGroup from "antd/lib/button/button-group";
const status = [3, 5, 6, 7];

const columns_payments = [
  {
    title: "Referencia",
    dataIndex: "payment_reference",
    key: "payment_reference"
  },
  {
    title: "Valor",
    dataIndex: "value",
    render: value => money(value),
    key: "value"
  },
  {
    title: "Estado",
    dataIndex: "status",
    key: "status"
  },
  {
    title: "Respuesta",
    dataIndex: "response",
    key: "response"
  },
  {
    title: "Codigo Respuesta",
    dataIndex: "cod_response",
    key: "cod_response"
  },
  {
    title: "Banco",
    dataIndex: "bank",
    key: "bank"
  },
  {
    title: "Franquicia",
    dataIndex: "franchise",
    key: "franchise"
  },
  {
    title: "Ip",
    dataIndex: "ip",
    key: "ip"
  },
  {
    title: "Fecha",
    dataIndex: "date",
    //render: value => (moment(value).format("DD/MM/YYYY H:m:s")),
    key: "date"
  }
];
const columns_products = [
  {
    title: "ID Producto",
    dataIndex: "product_id",
    key: "product_id",
    width: 110
  },
  {
    title: "Foto",
    width: 80,
    dataIndex: "product_main_image",
    //<Avatar record={record} source="product_main_image" size="small" />
    render: (value, record) => (
      <Avatar size="small" record={record} source="product_main_image" />
    ),
    key: "picture"
  },
  {
    title: "Nombre",
    dataIndex: "product_name",
    key: "name",
    width: "20%"
  },
  {
    title: "Precio",
    dataIndex: "unit_price_tax_incl",
    render: value => money(value),
    key: "price"
  },
  {
    title: "Cantidad Comprada",
    dataIndex: "quantity",
    key: "quantity"
  },
  {
    title: "Cantidad Enviada",
    dataIndex: "sent",
    key: "sent"
  },
  {
    title: "Restante",
    dataIndex: "quantity",
    key: "quantity",
    render: (value, record) => `${Number(value) - Number(record.sent)}`
  }
];
const columns_shppings = [
  {
    title: "Transportado por",
    dataIndex: "fulfillment_company.name"
  },
  {
    title: "Total Costo Envío",
    dataIndex: "price",
    render: value => value && `${money(value)}`
  },

  {
    title: "Número de Guía",
    dataIndex: "delivery_number",
    render: (value, record) => `${value || ""}`
  },
  {
    title: "Estado",
    dataIndex: "shipping_status.name"
  }
];

const ShowOrder = props => {
  const [payments, setPayments] = useState([]);
  const [details, setDetails] = useState([]);
  const [shipping_detail, setShippingDetail] = useState();
  const [show_shipping, setShowShipping] = useState(false);
  const [visible_guide, setVisibleGuide] = useState(false);
  const [state, setState] = useState({
    details: false,
    payments: false
  });
  const [record, setRecord] = useState({});
  const [order_id, setOrderId] = useState();
  const [user_id, setUserId] = useState();
  const [shippingaddress, setShippingAddreess] = useState({});

  const [loading, setLoading] = useState(false);

  const [shipping, setShipping] = useState({});
  const [shipping_loading, setShippingLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [visible, setVisible] = useState(false);

  const getData = id => {
    const service = getService("orders");
    /* setShippingLoading(true); */
    service.get(id).then(record => {
      setRecord(record);
      /*  getOrderExpress(id); */
      getOrderDetails(id);
      /* setShippingLoading(false); */
      /*
       * AQUI DEBE VENIR LA INFORMACION DEL shippin_address_meta_data
       * el servicio de orders no trae la informacion del Cliente
       * */
      console.log(">>>>>>>>", record);
      if (record.shipping_address_meta_data) {
        let shipping = JSON.parse(record.shipping_address_meta_data);
        setUserId(record.user_id);
        setShippingAddreess(shipping);
      }
    });
  };
  const getOrderDetails = id => {
    const service = getService("orders-details");
    setState({
      ...state,
      details: true
    });
    service
      .find({
        query: {
          order_id: id || props.id,
          $limit: Number.MAX_SAFE_INTEGER
        }
      })
      .then(({ data }) => {
        setState({
          ...state,
          details: false
        });
        setDetails(data);
      });
  };
  const getPayments = id => {
    const service = getService("payment-confirmations");
    setState({
      ...state,
      payments: true
    });
    service
      .find({
        query: {
          order_id: id
        }
      })
      .then(({ data }) => {
        setState({
          ...state,
          payments: false
        });
        setPayments(data);
      });
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name
    })
  };
  const handleGenerate = async () => {
    const service = getService("shipping");
    try {
      if (details.length == 0) throw new Error("No hay registros para enviar.");
      setLoading(true);
      let shipping = await service
        .find({
          query: {
            shipping_status_id: { $in: [1] },
            order_id
          }
        })
        .then(({ data }) => data[0]);
      setLoading(false);
      if (!shipping) {
        shipping = await service.create({
          order_id
        });
      }
      setShipping(shipping);
      setVisible(true);
    } catch (error) {
      message.error(`${error.message}`);
      setLoading(false);
    }
  };
  const init = () => {
    let { match } = props;
    let { id } = match.params;
    if (id) {
      getData(id);
      setOrderId(Number(id));
      getPayments(id);
    }
  };
  const onShowShippingDetail = record => {
    setShippingDetail(record);
    setShowShipping(true);
  };

  const handleApproveOrRejectOrder = (type) => {
    Modal.confirm({
      title: `¿Seguro que desea ${type === 'accept' ? 'aceptar' : 'rechazar'} el pedido?`,
      type: 'question',
      centered: true,
      content: 'Una vez realizada la acción no se podrá deshacer.',
      okText: 'Seguro',
      cancelText: 'Cancelar',
      okType: 'danger',
      onOk: async () => {
        await getService('process-payment-response').create({
          type: 'orders',
          order_id: props.id,
          order: record,
          response_code: type === 'accept' ? 1 : 2,
          payment_info: type === 'accept' ? 'Crédito aprobado' : 'Crédito rechazado',
          notification: 'true',
        }).then(() => {
          init()
        }).catch(err => {
          message.error(err?.message || 'Se ha presentado un error.')
        })
      }
    })
  }

  useEffect(() => {
    init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  return (
    <>
      <Row type="flex" justify="start">
        <Col span={24}>
          <Row gutter={8} type="flex" justify="start" align="middle">
            <Col>
              <HeadLine>Pedido # {order_id}</HeadLine>
            </Col>
            <Col>
              <>
                {record.payment_method && (
                  <Tag
                    style={{
                      fontSize: 24,
                      height: 30
                    }}
                    size="large"
                  >
                    Método de pago: {record.payment_method}
                  </Tag>
                )}
              </>
            </Col>
          </Row>
          {record?.integration_id && (
            <Row gutter={8} type="flex" justify="start" align="middle">
              <Col>
                <HeadLine size={"medium"}>Integration Id # {record?.integration_id}</HeadLine>
              </Col>
            </Row>
          )}
          <Row type="flex" justify="start" gutter={8}>
            <Col span={12}>
              <div
                style={{
                  margin: "10px 0px",
                  display: "flex",
                  gap: 20,
                  alignItems: 'center',
                }}
              >
                <HeadLine
                  size="medium"
                  style={{
                    marginBottom: 0
                  }}
                >
                  Estados del pedido
                </HeadLine>
                {record.order_status_id === 10 && (
                  <ButtonGroup>
                    <Button type="primary" onClick={() => handleApproveOrRejectOrder('accept')}>Aceptar</Button>
                    <Button danger onClick={() => handleApproveOrRejectOrder('reject')}>Rechazar</Button>
                  </ButtonGroup>
                )}
              </div>
              {order_id && (
                <HistoryTable
                  params={{
                    order_id
                  }}
                  id={order_id}
                />
              )}
            </Col>
            <Col span={12}>
              <div>
                <Row>
                  <Col>
                    <div
                      style={{
                        margin: "10px 0px"
                      }}
                    >
                      <HeadLine
                        size="medium"
                        style={{
                          marginBottom: 0
                        }}
                      >
                        Datos del cliente
                      </HeadLine>
                    </div>
                    <UserCard id={user_id} params={shippingaddress} />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row type="flex">
            <Col span={24}>
              {
                <Grid
                  title="Detalles del Pedido"
                  loading={state.details}
                  search={false}
                  actions={{
                    delete: false
                  }}
                  onRefresh={getOrderDetails}
                  scroll={{ x: 285 }}
                  size="small"
                  dataSource={details}
                  columns={columns_products}
                  pagination={false}
                />
              }
              <div style={{textAlign: 'right', padding: 10}}>
                {console.log(record)}
                {record?.total_price_shipping_cost_excl ? <div><b>Total Precio sin costo de envió:</b> {money(record?.total_price_shipping_cost_excl)}</div> : null}
                {record?.discount_total_amount ? <div><b>Total descuento:</b> {money(record?.discount_total_amount)}</div> : null}
                {record?.total_price ? <div><b>Total Precio:</b> {money(record?.total_price)}</div> : null}
                {record?.total_shipping_cost ? <div><b>Valor del envío:</b> {money(record?.total_shipping_cost)}</div> : null}
                {record?.discount_shipping_cost_amount ? <div><b>Valor de descuento de envío:</b> - {money(record?.discount_shipping_cost_amount)}</div> : null}
                {record?.total_payment_received ? <div><b>Total Pago recibido:</b> {money(record?.total_payment_received)}</div> : null}
              </div>
            </Col>
            <Col span={24}>
              {order_id && (
                <Grid
                  loading={state.payments}
                  onRefresh={getPayments}
                  filterDefaultValues={{
                    order_id
                  }}
                  title="Confirmación de Pagos"
                  search={false}
                  dataSource={payments}
                  actions={{
                    delete: false
                  }}
                  columns={columns_payments}
                  pagination={false}
                />
              )}
            </Col>
            <Col span={24}>
              {record && record.fulfillment_company && (
                <HeadLine
                  size="medium"
                  style={{
                    margin: "0px 10px"
                  }}
                >
                  Método de envío escogido por el cliente: {record.fulfillment_company.name}
                </HeadLine>
              )}
              {order_id && (
                <Grid
                  loading={shipping_loading}
                  filterDefaultValues={{
                    order_id
                  }}
                  source={"shipping"}
                  title="Envíos"
                  search={false}
                  actions={{
                    delete: false
                  }}
                  extra={
                    record && status.includes(record.order_status_id) ? (
                      <>
                        <Button
                          type="primary"
                          size="large"
                          icon="upload"
                          onClick={() => handleGenerate()}
                        >
                          Generar
                        </Button>
                      </>
                    ) : (
                      <div>
                        El estado actual del pedido no permite generar envios.
                      </div>
                    )
                  }
                  columns={[
                    {
                      width: 80,
                      render: (value, record) =>
                        record.fulfillment_company_delivery_guide && (
                          <a
                            href={record.fulfillment_company_delivery_guide}
                            target="_blank"
                          >
                            <Icon size="large" type="file-pdf" />
                          </a>
                        )
                    },
                    {
                      width: 80,
                      render: (value, record) => (
                        <>
                          <Button
                            onClick={() => onShowShippingDetail(record)}
                            type="link"
                            icon="eye"
                            size="large"
                          />
                        </>
                      )
                    },
                    ...columns_shppings
                  ]}
                  pagination={false}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        title="Generar Envío"
        width={"80%"}
        centered
        visible={visible}
        footer={null}
        destroyOnClose={true}
        onOk={() => setVisible(false)}
        onCancel={() => {
          init();
          setVisible(false);
        }}
      >
        <>
          <Shipping
            id={order_id}
            visible={show}
            fulfillment_company_id={record.fulfillment_company_id}
            onCancel={() => {
              init();
              setShippingLoading(laoding => !loading);
              setVisible(false);
            }}
            shipping={shipping}
          />
        </>
      </Modal>
      <Modal
        title={"Imprimir Guía"}
        width={"80%"}
        centered
        visible={visible_guide}
        footer={null}
        destroyOnClose={true}
        onOk={() => setVisibleGuide(false)}
        onCancel={() => {
          setVisibleGuide(false);
        }}
      >
        <>
          <iframe
            src={`${shipping_detail &&
              shipping_detail.fulfillment_company_delivery_guide}&embedded=true`}
            style={{
              width: "100%",
              height: 600
            }}
            frameborder="0"
          ></iframe>
        </>
      </Modal>
      <Modal
        title="Detalle del Envío"
        width={"80%"}
        centered
        visible={show_shipping}
        footer={null}
        destroyOnClose={true}
        onOk={() => setShowShipping(false)}
        onCancel={() => {
          setShippingDetail({});
          setShowShipping(false);
        }}
      >
        <>
          <ShippingDetail
            visible={show}
            order_id={order_id}
            record={shipping_detail}
            onCancel={() => {
              init();
              setShippingDetail({});
              setShowShipping(false);
            }}
          />
        </>
      </Modal>
    </>
  );
};
export default ShowOrder;