import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getService } from "../../services/";
import { message } from "antd";
const WrapperItem = styled.div`
  text-align: center;
  padding: 10px 20px;
  margin: 10px;
  font-size: 16px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 3px 3px 3px rgba(0,0,0,.2);
  & .value{
    font-size: 24px;
  }
  & .label{
    font-weight:bold;
  }
`;
const CountField = ({ reference, label, query, ...props }) => {
    const [value, setValue] = useState(0);
    const getCount = () => {
        let service = getService(reference);
        service.find({
            query: {
                $limit: 0,
                ...query
            }
        })
            .then(({ total }) => {
                setValue(total);
            })
            .catch(err => message.error(err.message));
    }
    useEffect(() => {
        if (reference)
            getCount();
    }, [reference]);
    return (<WrapperItem>
        {label && <div className="label">{label}</div>}
        <div className="value">{value}</div>
    </WrapperItem>)
}
export default CountField;