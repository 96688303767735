import React, { Fragment, useCallback, useState } from 'react';
import {
	List,
	Datagrid,
	TextField,
	EditButton,
	Filter,
	TextInput,
	ShowButton,
  FunctionField,
  ReferenceField,
    SelectInput,
} from 'react-admin';
import { CustomField, StatusField } from '../../components/com';
import { URL_S3 } from '../../constants';



const status = [
    {
      id: "Pendiente",
      name: "Pendiente"
    },
    {
      id: "Procesando",
      name: "Procesando"
    },
    {
      id: "Procesado",
      name: "Procesado"
    },
    {
      id: "Rechazada",
      name: "Rechazada"
    },
  ]

  const Filters = props => (
    <Filter {...props}>
      <SelectInput label="Estado" source="status" choices={status} alwaysOn />
    </Filter>
  );

  const columns = [

    {
      xtype: "customfieldUser",
      label: "Nombre de usuario",
      placeholder: "Nombre de usuario",
      render: (record) => (`${record.user.first_name || ""} ${record.user.last_name || ""}`)
    },
    {
      xtype: "customfieldCompany",
      source: ["company","name"],
      label: "Nombre de compañía",
      placeholder: "Nombre de compañía",
      render: (value, record) =>{
        console.log({record});
      return(
        <div>
          {record.id}
        </div>
      )}
    },
  ]

const PurchaseOrdersList = (props) =>{

    return (
      <List
      {...props}
      title="Órdenes de compra"
      exporter={false}
      bulkActionButtons={false}
      filters={<Filters />}
    >
      <Datagrid rowClick='edit' optimized {...props}>
        <TextField source="id" />
        <StatusField source="status" label="Estado" />
        <FunctionField  label="Archivo" render={(record) => (<a target='_blank' onClick={(e)=>{
          e.preventDefault()
          e.stopPropagation()
          window.open(`${URL_S3}/${record.path_file}`, "_blank")
        }} href={`${URL_S3}/${record.path_file}`}>Descarga aquí</a>)}/>
        <ReferenceField label="Nombre de usuario" source="user_id" reference="users">
          <CustomField source="user" render={(record, source) => (<span href={`${URL_S3}/${record}`}>{`${record['first_name']} ${record['last_name']}`}</span>)} />
        </ReferenceField>
        <ReferenceField label="Nombre de compañía" source="company_id" reference="companies">
          <CustomField source="company" render={(record, source) => (<span>{record.name}</span>)} />
        </ReferenceField>
        <EditButton label={"Editar"} />
      </Datagrid>
    </List>
    )
  }

  export default PurchaseOrdersList;

//   <div>
//   <Route path="/purchases-orders">
//       {() => (
//               <Fragment>
//                   <List
//                       {...props}
//                       title="Órdenes de compra"
//                       exporter={false}
//                       bulkActionButtons={false}
//                       sort={{ field: 'id', order: 'DESC' }}
//                       filters={<Filters />}
//                   >
//                       <Datagrid rowClick="edit" optimized {...props}>
//                           <TextField source="id" />
//                           <TextField source="status" label="Estatus" />
//                           <TextField source="user_id" label="Usuario" />
//                           <TextField source="company_id" label="Compañia" />
//                           {/* <ShowButton label="Editar" /> */}
//                       </Datagrid>
//                   </List>
//               </Fragment>
//           )
//       }
//   </Route>
// </div>