import React, { Fragment, useCallback, useState } from 'react';
import {
	List,
	Datagrid,
	TextField,
	EditButton,
	Filter,
	TextInput,
	ShowButton,
} from 'react-admin';
import { Title } from '.';
import { Route, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	list: {
		flexGrow: 1,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginRight: 0,
	},
	listWithDrawer: {
		marginRight: 400,
	},
	drawerPaper: {
		zIndex: 100,
	},
}));


const CustomFilters = (props) => (
	<Filter {...props}>
		<TextInput label="Buscar" source="data_value" alwaysOn />
	</Filter>
);
const UserCompanyList = (props) => {
	const [isMatch, setIsMatch] = useState(false);
	const classes = useStyles();
	const history = useHistory();
	const handleClose = useCallback(() => {
		history.push('/user-comercial');
	}, [history]);

	return (
		<div>
			<Route path="/user-comercial">
				{({ match }) => {
					const isMatch = !!(match && match.params && match.params.id !== 'create');
					return (
						<Fragment>
							<List
								{...props}
								title={<Title />}
								exporter={false}
								filter={{is_commercial: "true"}}
								bulkActionButtons={false}
								filters={<CustomFilters />}
							>
								<Datagrid rowClick="edit" optimized {...props}>
									<TextField source="id" />
									<TextField source="first_name" label="Nombre" />
									<TextField source="last_name" label="Apellido" />  
									<TextField source="email" label="Email" />   
									<ShowButton label="Editar" />		
								</Datagrid>
							</List>
						</Fragment>
					);
				}}
			</Route>
		</div>
	);
};
export default UserCompanyList;
