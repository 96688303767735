import React, { useState, useEffect } from "react";
import { SelectField, SimpleForm } from "../../components/com/form/";
import {
  Row,
  Col,
  Layout,
  message,
  List,
  Card,
  Table,
  Input,
  Button,
  InputNumber
} from "antd";
import { getService } from "../../services/";
import { Grid, HeadLine } from "../../components/com";
import { money } from "../../utils/Helper";
const { Meta } = Card;
const { Content } = Layout;
const types = [
  {
    id: "weight",
    name: "Peso"
  },
  {
    id: "units",
    name: "Unidades"
  },
  {
    id: "price",
    name: "Precio"
  },
  {
    id: "volume",
    name: "Volumen"
  }
];

const ColumnField = ({ record = {}, onSave, source, ...props }) => {
  let { added, id } = record;
  const [edit, setEdit] = useState(added || false);
  const [value, setValue] = useState(props.defaultValue);
  const onSubmit = value => {
    setEdit(edit => !edit);
    if (edit)
      onSave(id, {
        [source]: Number(value)
      });
  };
  return (
    <Row type={"flex"} gutter={4}>
      <Col>
        {
          <Input
            disabled={!edit}
            onChange={e => setValue(e.target.value)}
            defaultValue={value}
            {...props}
          />
        }
      </Col>
      <Col>
        <Button
          type={edit ? "primary" : "dashed"}
          icon={edit ? "save" : "edit"}
          onClick={() => onSubmit(value)}
        />
      </Col>
      {edit && (
        <Col>
          <Button
            type={"dashed"}
            icon={"close"}
            onClick={() => setEdit(false)}
          />
        </Col>
      )}
    </Row>
  );
};

const PriceGrid = ({ id, record = {}, editable, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [add, setAdd] = useState(false);
  const [precies, setPrices] = useState([]);
  const { location_city_id } = record;
  const getPrices = type => {
    const service = getService("fulfillment-matrix");
    setLoading(true);
    /* 
            fulfillment_company_id
            destination_city_id
            type
        */
    service
      .find({
        query: {
          fulfillment_company_id: id,
          destination_city_id: location_city_id,
          type
        }
      })
      .then(({ data }) => {
        setPrices(data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        message.error(error.message);
      });
  };
  const handleSave = async (_id, payloads) => {
    const service = getService("fulfillment-matrix");
    const response = await service.get(_id);
    if (response) {
      payloads = {
        ...payloads,
        fulfillment_company_id: Number(id),
        destination_city_id: location_city_id,
        type: props.type
      };
      service
        .patch(response.id, payloads)
        .then(({ data }) => {
          message.success("Registro Actualizado!");
          getPrices(props.type);
        })
        .catch(error => {
          message.error(error.message);
        });
    }
  };
  useEffect(() => {
    if (props.type) {
      getPrices(props.type);
    }
  }, [props.type]);

  return (
    <Row gutter={16}>
      <Col span={24}>
        {props.type && !add && (
          <Button
            type="primary"
            icon="plus"
            onClick={() => {
              setAdd(add => !add);
            }}
          >
            Agregar
          </Button>
        )}
        {add && (
          <SimpleForm
            redirect={false}
            source="fulfillment-matrix"
            style={{
              display: "flex"
            }}
            onSubmit={(err, data) => {
              getPrices(props.type);
            }}
            layout="horizontal"
          >
            <InputNumber
              type="number"
              validations={[
                {
                  required: true,
                  message: "El valor Minimo es requerido"
                }
              ]}
              placeholder="Min"
              name="min"
            />
            <InputNumber
              type="number"
              validations={[
                {
                  required: true,
                  message: "El valor Máximo es requerido"
                }
              ]}
              placeholder="Max"
              name="max"
            />
            <InputNumber
              type="number"
              validations={[
                {
                  required: true,
                  message: "El Precio es requerido"
                }
              ]}
              placeholder="Precio"
              name="price"
            />
            <Input type="hidden" name="type" initial={props.type} />
            <Input
              type="hidden"
              name="fulfillment_company_id"
              initial={Number(id)}
            />
            <Input
              type="hidden"
              name="destination_city_id"
              initial={location_city_id}
            />
          </SimpleForm>
        )}
        <Table
          pagination={false}
          loading={loading}
          dataSource={precies}
          columns={[
            {
              dataIndex: "id",
              title: "ID",
              width: 80
            },
            {
              dataIndex: "min",
              title: "Min",
              render: (value, record) => (
                <ColumnField
                  onSave={handleSave}
                  source="min"
                  defaultValue={value}
                  record={record}
                />
              )
            },
            {
              dataIndex: "max",
              title: "Max",
              render: (value, record) => (
                <ColumnField
                  onSave={handleSave}
                  source="max"
                  defaultValue={value}
                  record={record}
                />
              )
            },
            {
              dataIndex: "price",
              title: "Precio",
              render: (value, record) =>
                editable ? (
                  <ColumnField
                    onSave={handleSave}
                    source="price"
                    defaultValue={value}
                    record={record}
                  />
                ) : (
                  <span>{money(value)}</span>
                )
            }
          ]}
        />
      </Col>
      <Col span={24}></Col>
    </Row>
  );
};
//weight','units','price','volume'
const Prices = ({ id, record, ...props }) => {
  const service = getService("fulfillment-cities");
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [cities, setCities] = useState([]);
  const [type, setType] = useState();
  const [cities_ids, setCitiesIds] = useState([]);

  const renderItem = item => {
    return (
      <PriceGrid
        type={type}
        id={id}
        editable={record.integration == "false"}
        record={item}
      />
    );
  };
  const getCities = () => {
    setLoading(true);
    service
      .find({
        query: {
          fulfillment_company_id: record.id
        }
      })
      .then(({ data }) => {
        setCities(data);
        setCitiesIds(data.map(it => it.location_city_id));
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        message.error(err.message);
      });
  };
  useEffect(() => {
    if (record.id) getCities();
  }, [record.id]);

  const expandedRowRender = record => <p>HolA!</p>;
  if (loading) return null;
  return (
    <>
      <Layout>
        <Content
          style={{
            margin: "4px 10px"
          }}
        >
          <HeadLine size="medium">{record.name}</HeadLine>
          <Row type="flex" justify="start" align="middle">
            <Col span={4}>
              <SelectField
                onChange={(field, value) => {
                  setRefresh(refresh => !refresh);
                  setType(value);
                }}
                placeholder="Seleccione un tipo"
                style={{
                  width: "100%"
                }}
                choices={types}
              />
            </Col>
          </Row>
          <Row>
            <Table
              box
              /* loading={refresh} */
              showHeader={false}
              pagination={false}
              refresh={false}
              search={false}
              searchKey="name"
              actions={{
                edit: false
              }}
              expandedRowRender={renderItem}
              /* expandedRowRender={expandedRowRender} */
              columns={[
                {
                  dataIndex: "location_city.name",
                  title: "Ciudad"
                }
              ]}
              dataSource={cities}
            />
          </Row>
        </Content>
      </Layout>
    </>
  );
};
export default Prices;
