export const URL_BASE =
  window.location.hostname === "localhost"
    ? /* ? "http://localhost:3030" */ "https://api.estrategias-ltda.com"
    : "https://api.estrategias-ltda.com";
export const URL_AUTHENTICATION = "/authentication";

export const DEFAULT_AVATAR_IMAGE = "/images/avatar.png";
export const DEFAULT_IMAGE = "/images/mockup.png";

export const URL_S3_SERVER =
  window.location.hostname === "localhost"
    ? /* ? "http://localhost:3030" */ "https://api.estrategias-ltda.com"
    : "https://api.estrategias-ltda.com";


/* S3 configurations */
export const URL_S3 = "https://estrategia-web.s3.amazonaws.com";
export const BUCKET = "estrategia-web";
export const ACCESS_KEY_ID = "AKIA3SGPONEDOF6DVEGL";
export const SECRET_ACCESS_KEY = "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";

export const URL_API_EME = 'http://181.48.25.108:3032';

/*
export const BUCKET = "estrategialtda";
export const ACCESS_KEY_ID = "AKIA3SGPONEDNMMJZIPL";
export const SECRET_ACCESS_KEY = "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";
*/