import React, { useState, useEffect } from "react";
import 'moment/locale/es';
import { Row, Col, Table, Card, Button, Icon, Tag, message, Input, InputNumber, Divider } from "antd";
import { Avatar, HeadLine } from "../../components/com";
import styled from "styled-components";
import moment from "moment";
import { money } from "../../utils/Helper";
import { SimpleForm, SelectField } from "../../components/com/form";
import { getService } from "../../services/";
moment.locale("es");
const Form = styled(SimpleForm)`
    box-shadow: none!important;
    min-width: 100%!important;
    min-height: 480px!important;
    margin: 0px auto!important;
    padding: 0px 30px!important;
    & .login-form-button{
        margin: 0px auto!important;
    }
`;
const MyTable = styled(Table)`
& .ant-table{
  box-shadow:3px 3px 3px #ccc;
}`;
const columns = [
    {
        title: 'ID',
        width: 40,
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Foto',
        width: 80,
        dataIndex: 'sub_order_detail.express_product.image_main',
        render: value => (<Avatar size="small" src={value} />),
        key: 'image_main',
    },
    {
        title: 'Nombre',
        dataIndex: 'sub_order_detail.express_product.name',
        key: 'express_product_name',
    },
    {
        title: 'Cantidad Enviada',
        dataIndex: 'quantity',
        key: 'quantity',
    },
    {
        title: 'Fecha',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (value) => (moment(value).format("DD-MM-YYYY hh:mm A"))
    },

]
const columns_history = [
    {
        title: 'ID',
        width: 40,
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Estado',
        dataIndex: 'shipping_status.name',
        key: 'shipping_status',
    },
    {
        title: 'Enviado por',
        dataIndex: 'user',
        key: 'user',
        render: (record) => (<>
            {`${record.first_name} ${record.last_name || ""}`}
        </>),
    },
    {
        title: 'Fecha',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (value) => (moment(value).format("DD-MM-YYYY hh:mm A"))
    },

];
const columns_payment = [
    {
        title: 'ID',
        width: 40,
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Forma de pago',
        dataIndex: 'bank',
        key: 'bank',
    },
    {
        title: 'Referencia',
        dataIndex: 'payment_reference',
        key: 'payment_reference',
    },
    {
        title: 'Valor',
        dataIndex: 'value',
        key: 'value',
        render: (value) => (money(value))
    },
    {
        title: 'Descripción',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Fecha',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (value) => (moment(value).format("DD-MM-YYYY hh:mm A"))
    },
]
const banks = [
    {
        id: "dataphone",
        name: "Datáfono",
    },
    {
        id: "cash",
        name: "Efectivo",
    }

]
const DetailShipping = ({ record = {}, onSubmit, ...props }) => {

    let { shipping_details = [], shipping_history = [] } = record;

    const [isDataphone, setIsDataphone] = useState(false);
    const [payments, setPayments] = useState([]);
    const getPaymentConfirmation = id => {
        const service = getService("payment-confirmations");
        service.find({
            query: {
                shipping_id: record.id
            }
        })
            .then(({ data }) => {
                setPayments(data);
                console.log("DATA:::: ", data);
            })
            .catch(err => message.error(err.message))
    }
    useEffect(() => {
        if (record.id)
            getPaymentConfirmation(record.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record.id])
    return (<>
        <HeadLine>
            Pedido #{record.order_id}-{record.sub_order_id}
        </HeadLine>
        <Row type="flex" justify="space-between" align="top" gutter={16}>
            <Col span={record.order.payment_method == "cash_on_delivery" ? 16 : 24}>
                <HeadLine size="medium">
                    Detalles del Envío:
                </HeadLine>
                <MyTable
                    scroll={{ y: 450 }} size="small"
                    dataSource={shipping_details}
                    columns={columns}
                    pagination={false}

                />
                <HeadLine size="medium">
                    Historial del Envío:
                </HeadLine>
                <MyTable
                    scroll={{ y: 450 }} size="small"
                    dataSource={shipping_history}
                    columns={columns_history}
                    pagination={false} />
                {record.order && record.order.payment_method == "cash_on_delivery" && <>
                    <HeadLine size="medium">
                        Pagos del Envío:
                        </HeadLine>
                    <MyTable
                        scroll={{ y: 450 }} size="small"
                        dataSource={payments}
                        columns={columns_payment}
                        pagination={false} />
                </>}
            </Col>
            {record.order && record.order.payment_method == "cash_on_delivery" && <Col span={8}>
                <Row gutter={16}>
                    <Col>
                        <HeadLine size="medium">
                            Pago pendiente # {record.id} <strong style={{
                                color: "red"
                            }}> {money(record.pending_payment)}</strong>
                        </HeadLine>
                    </Col>
                    <Col>
                        <>{/* JSON.stringify(record) */}</>
                        <Form
                            source="payment-confirmations"
                            onSubmit={onSubmit}
                            layout="vertical"
                            textAcceptButton=" REGISTRAR PAGO"
                        >
                            <Input
                                type="hidden"
                                initial={record.id}
                                name="shipping_id"
                            />
                            <SelectField
                                flex={1}
                                onSelect={value => {
                                    setIsDataphone(value == "dataphone")
                                }}
                                label="Forma de pago"
                                placeholder="Forma de pago"
                                choices={banks}
                                name="bank"
                            />
                            {isDataphone && <Input
                                flex={1}
                                size="large"
                                label="Referencia de pago"
                                placeholder="Referencia de pago"
                                name="payment_reference"
                            />}
                            <InputNumber
                                flex={1}
                                size="large"
                                label="Valor"
                                initial={record.pending_payment}
                                placeholder="Valor"
                                name="value"
                            />
                            <Input.TextArea
                                flex={1}
                                size="large"
                                label="Descripción"
                                placeholder="Descripción"
                                name="description"
                            />
                        </Form>
                    </Col>
                </Row>
            </Col>}
        </Row>
    </>)
}
export default DetailShipping;