import React, { Fragment } from "react";
import {
    ShowButton,
    Filter,
    TextInput,
    TextField,
    DateField,
    SelectField,
    ReferenceInput,
    SelectInput,
    ReferenceField,
    ChipField,
    BooleanField,
    BooleanInput
} from "react-admin";
import { statusList } from "."
import { MoneyField, CustomList, CustomField, StatusField } from "../../components/com"
import m from "moment";
const Filters = (props) => (
    <Filter {...props}>
        <TextInput label="Id Pedido Express" source="id" alwaysOn />
        <TextInput label="Id Pedido" source="order_id" alwaysOn />
        {<ReferenceInput
            label="Estado de Pedio"
            source="order_status_id"
            reference="orders-status"
            /* filter={{ type: "products" }} */
            alwaysOn>
            <SelectInput optionText="name" />
        </ReferenceInput>}
        <CustomBooleanInput
            source="recurrent"
            label="Recurrente"
            alwaysOn
        />
        <TextInput label="Buscar" source="data_value" alwaysOn />
    </Filter>
);
const CustomBooleanField = ({ record, source, ...props }) => {
    record[source] = record[source] === "true";
    return (<BooleanField
        {...props}
        source={source}
        record={record}
        sort={false}
        source={source}
        label="Recurrente" />)
}
const CustomBooleanInput = ({ record, source, ...props }) => {
    record[source] = record[source] === "true";
    return (<BooleanInput
        {...props}
        source={source}
        record={record}
        sort={false}
        source={source}
        label="Recurrente" />)
}
const OrderExpress = (props) => {

    return (
        <Fragment>
            <h2>Pedidos Express</h2>
            <CustomList
                {...props}
                source={props.source || "products-orders"}
                sort={{
                    field: "createdAt",
                    order: "DESC"
                }}
                filters={<Filters />}
                exporter={false}
                bulkActionButtons={false}
            >
                <TextField label="ID" source="id" />
                <TextField label="Pedido" source="order_id" />
                <ReferenceField label="Usuario" source="user_id" reference="users">
                    <CustomField source="first_name" render={(record, source) => (<span>{`${record[source]} ${record['last_name']}`}</span>)} />
                </ReferenceField>

                <ReferenceField label="Email" source="user_id" reference="users">
                    <CustomField source="email" />
                </ReferenceField>
                <CustomBooleanField sort={false}
                    source="recurrent"
                    label="Recurrente" />
                <ReferenceField sortable={false} label="Estado" source="order_status_id"
                    reference="orders-status">
                    <StatusField source="name" />
                </ReferenceField>
                <TextField
                    label="Metodo de pago" source="order.payment_method" />
                <MoneyField label="Total" source="total_price" />
                <CustomField label="Fecha" source="createdAt" render={(r, s) => m(r[s]).format('DD-MM-YYYY')} showTime />
                <ShowButton label="Ver" />
            </CustomList>
        </Fragment>
    );
};

export default OrderExpress;
