import React from "react";
import CRUD from "../../components/com/crud/CRUD";

const columns = [
  {
    xtype: "textfield",
    source: "gateway",
    fullWidth: true,
    label: "Metodo de pago",
  },
  {
    xtype: "textfield",
    source: "paymment_method",
    fullWidth: true,
    label: "Pasarela de pago",
  },
  {
    xtype: "statusfield",
    source: "status",
    label: "Estado",
  },
];
const fields = [
  {
    xtype: "statusfield",
    source: "status",
    fullWidth: true,
    label: "Estado",
  },
  {
    xtype: "selectfield",
    source: "gateway",
    label: "Metodo de pago",
    fullWidth: true,
    choices: [
      {
        id: "epayco",
        name: "epayco",
      },
      {
        id: "wompi",
        name: "wompi",
      },
    ],
  },
];
const PaymentConfiguration = (props) => {
  return (
    <CRUD
      title="Configuración de pago"
      create={true}
      actions={{ delete: false, create: false }}
      columns={columns}
      fields={fields}
    />
  );
};
export default PaymentConfiguration;
