import React from "react";
import {
  NumberInput,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  ReferenceInput,
  Create,
  SimpleForm,
  DisabledInput,
  TextInput,
  SimpleShowLayout,
  Show,
  SelectInput,
  DeleteButton,
  CardActions,
  ReferenceArrayInput,
  SelectArrayInput,
  TabbedShowLayout,
  Tab,
  DateInput,
  maxLength
} from "react-admin";
import { Button, Grid } from "@material-ui/core/";
import { typeList, statusList, statusLiquidacion } from "./";
import { Link } from "react-router-dom";
const validations = [maxLength(40, "El campo debe ser  menor a 40 caracteres")];
export const ExpressProductCreate = props => {
  const { state = {} } = props.location;
  return (
    <Create {...props} /* actions={<PostActions id={props.id} />} */>
      <SimpleForm>
        <Grid container spacing={16} fullWidth>
          <Grid item xs={12} fullWidth>
            <TextInput source="name" label="Nombre" fullWidth maxLength={40}
              validate={validations}
            />
          </Grid>
          <Grid item xs={2}>
            <TextInput fullWidth source="code" label="Código" />
          </Grid>
          <Grid item xs={3}>
            <ReferenceInput
              perPage={10000}
              fullWidth
              label="Categoria"
              source="category_id"
              reference="categories"
            >
              <SelectInput source="name" />
            </ReferenceInput>
          </Grid>
          <Grid item xs={3}>
            <ReferenceInput
              perPage={10000}
              fullWidth
              label="Categoria 2"
              source="category_id_2"
              reference="categories"
            >
              <SelectInput source="name" />
            </ReferenceInput>
          </Grid>
          <Grid item xs={3}>
            <ReferenceInput
              perPage={10000}
              fullWidth
              label="Categoria 3"
              source="category_id_3"
              reference="categories"
            >
              <SelectInput source="name" />
            </ReferenceInput>
          </Grid>
          <Grid item xs={3}>
            <ReferenceInput
              perPage={10000}
              fullWidth
              label="Categoria 4"
              source="category_id_4"
              reference="categories"
            >
              <SelectInput source="name" />
            </ReferenceInput>
          </Grid>
          <Grid item xs={3}>
            <ReferenceInput
              fullWidth
              label="Marca"
              source="brand_id"
              reference="brands"
            >
              <SelectInput source="name" />
            </ReferenceInput>
          </Grid>
          <Grid item xs={3}>
            <SelectInput
                fullWidth
                label="El producto esta en liquidación"
                source="settlement"
                choices={statusLiquidacion}
                optionText="name"
                optionValue="id"
              />
          </Grid>
          <Grid item xs={3}>
            <SelectInput
                fullWidth
                label="Producto oversize"
                source="oversize"
                choices={statusLiquidacion}
                optionText="name"
                optionValue="id"
              />
          </Grid>


          <Grid item xs={12} > <h2>Precios:</h2></Grid>
          <Grid item xs={3} fullWidth>
            <NumberInput source="price" label="Precio sin IVA" fullWidth />
          </Grid>

          <Grid item xs={3}>
            <ReferenceInput
              fullWidth
              label="IVA %"
              source="tax_rule_id"
              reference="tax-rule"
            >
              <SelectInput source="name" />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} ></Grid>
          <Grid item xs={3} >
            <NumberInput
              source="discount_percentage"
              label="Porcentaje de descuento"
              fullWidth
            />
          </Grid>
          <Grid item xs={3} >
            <DateInput
              source="discount_end_date"
              label="Caducidad del descuento"
              fullWidth
            />
          </Grid>
          {/* <Grid item xs={3} >
            <NumberInput
              source="discount_price"
              label="Precio en descuento sin IVA"
              disabled
              fullWidth
            />
          </Grid> */}
          <Grid item xs={12} > <h2>Peso y Medidas:</h2></Grid>
          <Grid item xs={3} fullWidth>
            <NumberInput
              source="weight"
              label="Peso en kg"
              fullWidth
            />
          </Grid>
          <Grid item xs={3} fullWidth>
            <NumberInput
              source="heigh"
              label="Alto en cm"
              fullWidth
            />
          </Grid>
          <Grid item xs={3} fullWidth>
            <NumberInput
              source="width"
              label="Ancho en cm"
              fullWidth
            />
          </Grid>
          <Grid item xs={3} fullWidth>
            <NumberInput
              source="long"
              label="Largo en cm"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} > <h2>Cantidades:</h2></Grid>
          <Grid item xs={4}>
            <NumberInput fullWidth source="quantity" label="Cantidad Disponible" />
          </Grid>
          <Grid item xs={3} >
            <TextInput fullWidth source="unit_measure" label="Unidad de Medida" />

          </Grid>
          <Grid item xs={3} >
            <NumberInput fullWidth source="quantity_per_unit_measure" label="Cantidad por Unidad de Medida" />
          </Grid>
          <Grid item xs={12} > <h2>Descripción:</h2></Grid>
          <Grid item xs={12}>
            <TextInput multiline source="description" label="Descripcion" fullWidth />
          </Grid>
          <Grid item xs={4} fullWidth>
            <SelectInput
              fullWidth
              label="Estado"
              source="status"
              choices={statusList}
              optionText="name"
              optionValue="id"
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};
