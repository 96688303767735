import React, { Component } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  NumberInput,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { statusList, typeList } from "./";
import { Grid } from "@material-ui/core";

export default class BlogsAndGuidesCreate extends Component {
  render() {
    return (
      <Create {...this.props} title="Crear Guia o blog">
        <SimpleForm>
          <Grid fullWidth container spacing={3}>
            <Grid item xs={12} md={6} spacing={6}>
              <TextInput fullWidth source="title" label="Titulo" />
              <SelectInput
                fullWidth
                label="Estado"
                source="status"
                choices={statusList}
                optionText="name"
                optionValue="id"
              />
            </Grid>

            <Grid item xs={12} md={6} spacing={6}>
              <NumberInput fullWidth source="priority" label="Prioridad" />
              <SelectInput
                label="Tipo"
                fullWidth
                source="type"
                choices={typeList}
                optionText="name"
                optionValue="id"
              />
            </Grid>
            <Grid item xs={12} spacing={6} fullWidth>
              <TextInput
                source="description"
                label="Descripción"
                fullWidth
                multiline
              />
              <TextInput multiline fullWidth source="short_description" label="Descripcion Corta" />
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
    );
  }
}
