import React, { useState } from "react";
import { message, List, Avatar, InputNumber, Card, Button, Divider } from "antd";
import { DragAndDropUploader, MyModal, UploadDragAndDrop } from "../../../components/com";
import { getService } from "../../../services/";
import { URL_S3 } from "../../../constants/";
import styled from "styled-components";
import _ from 'lodash'
import { useEffect } from "react";
const { Meta } = Card;
const MyList = styled(List)`
    & .ant-card-bordered{
        /* height:150px!important;    */
    }
    & .ant-card-cover img{
        object-fit: contain!important;
        height: 100%!important;
        background-color:#fff;
    }
    & .ant-card{
        max-width: 260px;
    }
    & .ant-card-body{
        padding:4px 0px!important;
    }
    & .ant-card-meta-description{
        margin: 10px!important;
    }
    & .ant-card-cover {
        height: 200px!important;
        background: #ccc;
        overflow: hidden!important;
    }
    & .ant-card-meta-description > div{
        width:100%!important;
    }
    & .ant-card-bordered ul.ant-card-actions{
        display:none!important;
    }
    & .ant-card-bordered .ant-btn{
        background: #fff!important;
    }
    & .ant-card-bordered .ant-btn.delete{
        color: red!important;
    }
    & .ant-card-bordered:hover ul.ant-card-actions {
        position: absolute;
        top: 0;
        background: transparent;
        width: 100%;
        background: #0c0c0c75;
        min-height: 200px;
        display: flex!important;
        justify-content: space-between;
        align-items: center;
    }
`;
const service = getService("products-media")
const PropertyGalleryUploader = ({ dataSource, onChange, record, ...props }) => {
    const [media, setMedia] = useState([]);
    const [loading, setLoading] = useState(false);
    const [current_media, setCurrentMedia] = useState();
    const [show, setShow] = useState(false);

    // const UpdateListing = file => {
    //     if (file.key)
    //         service.create({
    //             product_id: record.id,
    //             path: file.key,
    //             type: file.type,
    //             priority: file.index
    //         })
    //             .then(response => {
    //                 getMedia();
    //                 /* setMedia([
    //                     ...media,
    //                     response
    //                 ].filter(it => (it.position))); */
    //             })
    //             .catch(err => message.error(err.message));
    // };
    const handleDelete = id => {
        service.remove(id)
            .then(response => {
                getMedia();
                message.info("Media eliminado!");
            })
            .catch(err => message.error(err.message));
    }
    const handleShow = record => {
        setShow(true);
        setCurrentMedia(record);
    }
    // const handleOnChange = ({ key, type, ...file }) => {
    //     delete file.url;
    //     let files = media;
    //     file["uid"] = `${files.length}`;
    //     file["key"] = key;
    //     file["type"] = type;
    //     file["url"] = file.location;
    //     file["path"] = file.location;
    //     file["status"] = file.status === 204 ? "done" : "error";
    //     files.push(file);
    //     UpdateListing(file);
    // };
    const handleposition = (value, record) => {
        setMedia([]);
        if (record.id)
            service.patch(record.id, {
                priority: value
            })
                .then(response => {
                    /*  if (onChange) onChange(); */
                    getMedia();
                    message.success("Prioridad Actualizada")
                })
                .catch(err => message.error(err.message));
    }
    const getMedia = () => {
        setLoading(true);
        service.find({
            query: {
                product_id: record.id,
                $limit: 999999999,
                $sort: {
                    priority: -1
                }
            }
        })
            .then(({ data }) => {
                setLoading(false);
                setMedia(data);
            })
            .catch(err => message.error(err.message));
    }
    var handleOnposition = _.debounce(handleposition, 1000, { 'maxWait': 1000 });
    const renderItems = (record, index) => {
        let url = record.path || record.url;
        if (url && url.indexOf(URL_S3) == -1)
            url = `${URL_S3}/${url}`;
        return (<List.Item>
            <Card
                actions={[
                    <Button
                        className="delete"
                        shape="circle"
                        type="ghost"
                        icon="delete"
                        onClick={() => handleDelete(record.id)} />,
                    <Button
                        className="show"
                        shape="circle"
                        type="ghost"
                        icon="eye"
                        onClick={() => handleShow(record)} />

                ]}
                cover={<img src={url} />}>
                <Meta
                    description={<>
                        <span>Prioridad</span>
                        <InputNumber
                            min={0}
                            defaultValue={record.priority}
                            name="priority" onChange={(value) => handleOnposition(value, record)} />
                    </>}
                />
            </Card>
        </List.Item >);
    }
    useEffect(() => {
        getMedia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<>
        {/* <DragAndDropUploader
            dataSource={media}
            onChange={handleOnChange}
            path={`products/${record.id}`}
        /> */}
        <UploadDragAndDrop
            fileMultiple={true}
            filePath="productsGallery"
            onUploadedFile={(files) => {
                const file = files[0];
                if(files.length === 1){
                  service.create({
                    type: "image",
                    product_id: record?.id,
                    path: file.fileKey,
                    priority: 0,
                  });
                  message.success("Se ha actualizado la foto");
                  getMedia();
                } else if(files.length > 1){
                  files.map((fileSingle)=> {
                    service.create({
                      type: "image",
                      product_id: record?.id,
                      path: fileSingle.fileKey,
                      priority: 0,
                    });
                    message.success("Se ha actualizado la foto");
                    getMedia();
                  })
                }
              }}
        />
        <Divider type="horizontal" dashed />
        {<MyList
            loading={loading}
            grid={{ gutter: 4, column: 6 }}
            renderItem={renderItems}
            dataSource={media.map((it, key) => ({
                uid: key,
                url: it.path,
                status: "done",
                name: key,
                index: it.priority,
                ...it
            }))}
        />}
        {current_media && <MyModal
            closable={true}
            height={"50%"}
            width={"calc(50% - 20px)"}
            style={{
                height: "50%"
            }}
            title={current_media.path.substring(current_media.path.lastIndexOf("/") + 1, current_media.path.lenght) || "Preview"}
            onCancel={() => setShow(false)}
            visible={show}
        >
            <img
                alt="example"
                style={{ width: "400px", margin: "0px 0px 20px 0px" }}
                src={`${URL_S3}/${current_media.path}`}
            />
        </MyModal>}
    </>);
}
export default PropertyGalleryUploader;