import React, { Component } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  useGetOne,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import { Title, ImgField } from ".";
import S3File from "../../../components/S3-field";
import { expressCategoriesService, getService } from "../../../utils/Api";
import { URL_S3 } from "../../../constants";
const service = getService("categories");
export default class CompanyEdit extends Component {
  state = {
    path_image: null,
    path_cover: null,
    expanded: false,
  };

  async fetchData() {
    console.log(this.props);
    service
      .get(this.props.id)
      .then((it) => this.setState({ path_image: it.path_image,  path_cover: it.path_cover}));
  }

  componentDidMount() {
    this.fetchData();
  }

  handleUploadFinish = (url, id) => {
    service.patch(this.props.id, { path_image: url })
      .then((it) => {
        this.fetchData();
      });
  };

  handleUploadFinishCover = (url, id) => {
    service.patch(this.props.id, { path_cover: url })
      .then((it) => {
        this.fetchData();
      });
  };
  render() {
    const { path_image } = this.state;
    // console.log(path_image);
    const { path_cover } = this.state;
    console.log(path_cover)
    

    return (
      <Edit title={<Title />} {...this.props}>
        <SimpleForm>
          <Grid container fullWidth spacing={16}>
            <Grid item xs={6}>
              <TextInput fullWidth source="name" label="Nombre" />
            </Grid>
            <Grid item xs={6}>
              <ReferenceInput
                fullWidth
                label="Padre"
                source="parent_id"
                reference="categories"
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            </Grid>
            <Grid item xs={6}>
              {path_image ? (
                <img
                  src={`${URL_S3}/${path_image}`}
                  width="200px"
                  height="200px"
                  className="custom-img-field"
                  style={{backgroundColor: '#f0f0f0'}}
                />
              ) : null}
              <S3File
                idComponent="category-image"
                path="categories"
                handleUploadFinish={this.handleUploadFinish}
                id={this.props.match.id}
              />
            </Grid>
            <Grid item xs={6}>
              {path_cover ? (
                <img
                  src={`${URL_S3}/${path_cover}`}
                  width="200px"
                  height="200px"
                  className="custom-img-field"
                />
              ) : null}
              <S3File
                idComponent="category-cover"
                path="categories"
                handleUploadFinish={this.handleUploadFinishCover}
                id={this.props.match.id}
              />
            </Grid>
          </Grid>
        </SimpleForm>
      </Edit>
    );
  }
}
