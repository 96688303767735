import React, { useState } from "react";
import { Input, AutoComplete, message } from "antd";
import styled from "styled-components";
import { getService } from "../../../services/";
import _ from "lodash";
const Wrapper = styled(AutoComplete)`
 /*  & .ant-input {
    border-radius: 20px !important;
    box-shadow: 3px 3px 3px rgba(224, 224, 224, 0.4) !important;
    border: 1px solid rgba(224, 224, 224, 0.8) !important;
  } */
  & .ant-input{
    height:40px!important;
  }
`;
const SearchField = ({ placeholder, width = 150, source, onChange, defaultFilterValues = {}, optionText = "name", optionValue = "id", searchKey = "q", reference, ...props }) => {
  const [data, setData] = useState([]);
  const [value, setValue] = useState(props.defaultValue);

  const OnChange = value => {
    console.log(source, value)
    setValue(value);
    /* if (onChange && !value) */
    onChange(source, value);
  }
  const handleOnSelect = value => {
    console.log(source, value)
    if (onChange)
      onChange(source, value)
  }

  const handleSearch = value => {
    if (onChange)
      onChange(source, value)
  }
  const handleOnChange = _.debounce(value => OnChange(value), 500);
  const search = (value) => {
    if (value && reference) {
      const service = getService(reference);
      service.find({
        query: {
          [searchKey]: value,
          ...defaultFilterValues
        }
      })
        .then(({ data }) => {
          setData(data);
        })
        .catch(error => message.error(error.message));
    }
  }
  const { Option } = AutoComplete;

  return (<>
    <Wrapper
      dropdownClassName="certain-category-search-dropdown"
      dropdownMatchSelectWidth={500}
      style={{ width, ...props.style }}

      onChange={handleOnChange}
      onSelect={handleOnSelect}
      name={source}
      dataSource={value && data.map(it => props.renderItem || (<Option key={it[optionValue]}>
        {props.render ? props.render(it[optionText], it) : it[optionText]}
      </Option>))}
      {...props}
    >
      <Input.Search
        onSearch={handleSearch}
        allowClear size="large" placeholder={placeholder || "Buscar"} />
    </Wrapper>
  </>)
};

export default SearchField;
