import React, { useState, useEffect } from "react";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import { CompaniesService } from "../../../utils/Api";

const { Option } = Select;

const UserRemoteSelect = () => {
  const [data, setData] = useState([]);
  const [value, setValue] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [lastFetchId, setLastFetchId] = useState(0);

  const getCompanies = async (searchParam) => {
    const res = await CompaniesService.find({
      query: { $limit: Number.MAX_SAFE_INTEGER, $client: { searchParam } },
    });
    const data = res.data.map((it) => ({
      text: it.name,
      value: it.id,
    }));
    return data;
  };

  const fetchUser = debounce((value) => {
    setLastFetchId(lastFetchId + 1);
    const fetchId = lastFetchId + 1;
    setData([]);
    setFetching(true);
    getCompanies(value).then((data) => {
      if (fetchId !== lastFetchId + 1) {
        // for fetch callback order
        return;
      }
      setData(data);
      setFetching(false);
    });
  }, 800);

  const handleChange = (value) => {
    setValue(value);
    setData([]);
    setFetching(false);
  };

  return (
    <Select
      mode="default"
      labelInValue
      value={value}
      placeholder="Select users"
      notFoundContent={fetching ? <Spin size="small" /> : null}
      filterOption={false}
      onSearch={fetchUser}
      onChange={handleChange}
      style={{ width: "100%" }}
    >
      {data.map((d) => (
        <Option key={d.value}>{d.text}</Option>
      ))}
    </Select>
  );
};

export default UserRemoteSelect;
