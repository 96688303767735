import React, { useState, useEffect } from 'react';
import { message, Select } from "antd";
import { getService } from '../../../services';

const { Option } = Select;
const SelectField = ({ optionValue, width = 150, filterDefaultValues, reference, source, record, form, optionText, placeholder, name, onChange, onSearch, ...props }) => {
    const [initialized, setInitialized] = useState(false);
    const [defaultValue, setDefaultValue] = useState(props.defaultValue);
    const [choices, setChoices] = useState(props.choices || []);
    const getData = async () => {
        if (reference) {
            const service = getService(reference);
            let token = localStorage.getItem("feathers-jwt");
            if (!token) return;
            service.find({
                ...filterDefaultValues
            })
                .then(({ data }) => {
                    setChoices(data)
                })
                .catch(err => {
                    message.error(err.message);
                });
        }
    }
    useEffect(() => {
        getData();
    }, []);
    useEffect(() => {
        setChoices(props.choices)
    }, [props.choices]);

    const handleOnChange = (value) => {
        if (onChange)
            onChange(source, value)
    }
    // if (!initialized) return <span>Loading...</span>;
    return (<Select
        style={{ width, ...props.style }}
        initial={defaultValue}
        showSearch
        size={props.size || "large"}
        placeholder={placeholder || "Seleccionar"}
        optionFilterProp="children"
        onChange={handleOnChange}
        onSearch={onSearch}
        allowClear={props.allowClear || false}
        filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        name={name || source}
        {...props}
    >
        {choices && choices.map((it, index) => {
            return (<Option key={index} value={it[optionValue || "id"]}>
                {it[optionText || "name"]}
            </Option>)
        })}
    </Select>)
};

export default SelectField;