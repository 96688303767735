import React from "react";
import BlogGuidesList from "./blogGuidesList";
import BlogGuidesCreate from "./blogGuidesCreate";
import BlogGuidesEdit from "./blogGuiesEdit";

const Title = ({ record }) => {
  return (
    <span>{record ? `Editar ${record.name}` : "Listado de Blobs y Guias"}</span>
  );
};

const statusList = [
  { id: "active", name: "Activo" },
  { id: "inactive", name: "Inactivo" },
];

const typeList = [
  { id: "blog", name: "Blog" },
  { id: "guide", name: "Guia" },
];

export {
  BlogGuidesList,
  Title,
  statusList,
  typeList,
  BlogGuidesCreate,
  BlogGuidesEdit,
};
