import React, { Component, useCallback } from "react";
import { useHistory } from "react-router-dom";
import S3File from "../../components/S3-field";
import { brandsService } from "../../utils/Api";

import {
  SimpleForm,
  TextInput,
  useEditController,
  SelectInput,
  NumberInput,
} from "react-admin";
import CloseIcon from "@material-ui/icons/Close";

import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 40,
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "1em",
  },
  form: {
    /*  [theme.breakpoints.up("xs")]: {
      width: 400
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginTop: -30
    } */
  },
  inlineField: {
    display: "inline-block",
    width: "50%",
  },
}));

const CompanyMembershipEdit = ({ onCancel, ...props }) => {
  const classes = useStyles();
  const controllerProps = useEditController(props);
  const history = useHistory();
  const handleClose = useCallback(() => {
    history.push("/brands");
  }, [history]);

  const handleOnCancel = () => {
    if (onCancel) return onCancel();
    handleClose();
  };
  if (!controllerProps.record) {
    return null;
  }

  const handleUploadFinish = async (url, id) => {
    await brandsService
      .patch(props.id, { path_logo: url })
      .then((it) => console.log(it));
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6">Editar Marca</Typography>
        <IconButton onClick={handleOnCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <SimpleForm
        className={classes.form}
        basePath={controllerProps.basePath}
        record={controllerProps.record}
        save={controllerProps.save}
        version={controllerProps.version}
        redirect="list"
        resource="brands"
      >
        <TextInput fullWidth source="id" label={false} type="hidden" />
        <Grid fullWidth spacing={16}>
          <Grid item xl={12} spacing={6}>
            <TextInput source="name" label="Nombre" fullWidth />
          </Grid>
          <Grid item xl={12} spacing={6}>
            <SelectInput
              fullWidth
              source="status"
              choices={[
                { id: "active", name: "Activo" },
                { id: "inactive", name: "Inactivo" },
              ]}
            />
          </Grid>
          <Grid item xl={12} spacing={6}>
            <TextInput
              source="description"
              label="Descripción"
              fullWidth
              multiline
            />
          </Grid>
          <Grid item xl={12} spacing={6}>
            <NumberInput source="priority" label="Prioridad" fullWidth />
          </Grid>
          <Grid item xl={12} spacing={6}>
            <S3File
              idComponent="logo-brands"
              path="brands"
              source="path_logo"
              handleUploadFinish={handleUploadFinish}
              id={props.match.id}
              record={controllerProps.record}
            />
          </Grid>
        </Grid>
        {/* <Grid item xs={12}>
          <Paper fullWidth>Hola</Paper>
        </Grid> */}
      </SimpleForm>
    </div>
  );
};
export default CompanyMembershipEdit;
