import React, { useEffect, useState } from "react";
import { Table, Input, InputNumber, Popconfirm, Form, Select, Button, message } from 'antd';
import Factory from "../com/crud/Factory";
import { getService } from "../../services/";
const { Option } = Select;
const EditableContext = React.createContext();
const SelectField = ({ input, record, onChange, onSearch, source, dataIndex, placeholder, reference, ...props }) => {

    const [choices, setChoices] = useState([]);
    const [optionText, setOptionText] = useState();
    const [optionValue, setOptionValue] = useState();
    const [loading, setLoading] = useState(false);
    const getData = async () => {
        const service = getService(reference);
        setLoading(true);
        await service.find({})
            .then(({ data }) => {
                console.log("..........", optionValue, data, record[dataIndex])
                setLoading(false);
                setChoices(data);

            })
            .catch(err => {
                setLoading(false);
                message.error(err.message)
            });
    }
    useEffect(() => {
        if (props.choices) {
            setChoices(props.choices);
            let choices = props.choices || [];
            let value = choices.filter(it => (it.id == record[dataIndex]));
            let text = value.length > 0 ? value[0]["name"] : "";
            value = value.length > 0 ? value[0]["id"] : "";
            setOptionText(text);
            setOptionValue(value);
        } else if (record[source || dataIndex]) {
            setOptionText(record[source || dataIndex]["name"]);
            setOptionValue(record[source || dataIndex]["id"]);
        }
        if (reference) {
            getData();
        }
    }, [])

    return (
        input ? <Select
            loading={loading}
            defaultValue={optionValue || record[dataIndex]}
            showSearch
            style={{ width: 200 }}
            placeholder={placeholder || "Seleccione un registro"}
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {choices && choices.map(it => (<Option value={it[it.optionValue || "id"]}>
                {it[it.optionText || "name"]}
            </Option>))}
        </Select> : <>{optionText}</>);
}


class EditableCell extends React.Component {
    getInput = () => {

        let { xtype, renderEdit, record } = this.props;
        console.log("Input: ", record)

        switch (xtype) {
            case 'numberfield':
                if (renderEdit) return renderEdit(this.props, record);
                return <InputNumber  {...this.props} />;
                break;
            case 'selectfield':
                return <SelectField input {...this.props} />
                break;
            default:
                return <Input />;
                break;
        }
    };

    renderField = () => {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            xtype,
            record,
            index,
            children,
            ...restProps
        } = this.props;
        switch (xtype) {
            case 'selectfield':
                return <SelectField {...this.props} />
                break;
            default:
                return children;
                break;
        }
    }

    renderCell = ({ getFieldDecorator }) => {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            xtype,
            record,
            index,
            children,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            rules: [
                                {
                                    required: true,
                                    message: `Please Input ${title}!`,
                                },
                            ],
                            initialValue: record[dataIndex],
                        })(this.getInput(record))}
                    </Form.Item>
                ) : (
                        this.renderField(this.props)
                    )}
            </td>
        );
    };

    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
}

class EditableTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], editingKey: '' };
        this.columns = [
            ...this.props.columns,
            {
                title: 'Acciones',
                dataIndex: 'operation',
                render: (text, record) => {
                    const { editingKey } = this.state;
                    const editable = this.isEditing(record);
                    return editable ? (
                        <span>
                            <EditableContext.Consumer>
                                {form => (
                                    <Button
                                        type="link"
                                        icon="save"
                                        onClick={() => this.save(form, record)}
                                        style={{ marginRight: 8 }}
                                    >
                                    </Button>
                                )}
                            </EditableContext.Consumer>
                            <Popconfirm title="Desea Cancelar?" onConfirm={() => this.cancel(record.key)}>
                                <Button
                                    type="link"
                                    icon="close"></Button>
                            </Popconfirm>
                        </span>
                    ) : (<>
                        <Button
                            icon="edit"
                            type="link" disabled={editingKey !== ''} onClick={() => this.edit(record.key)}>
                        </Button>
                        <Popconfirm title="Desea Eliminar?" onConfirm={() => this.delete(record.key)}>
                            <Button
                                icon="delete"
                                type="link">
                            </Button>
                        </Popconfirm>

                    </>
                        );
                },
            },
        ];
    }

    isEditing = record => record.key === this.state.editingKey;

    cancel = () => {
        this.setState({ editingKey: '' });
    };

    save(form, record) {
        let { key } = record;

        form.validateFields((error, row) => {
            if (error) {
                return;
            }
            const newData = [...this.state.data];
            const index = newData.findIndex(item => key === item.key);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                this.setState({ data: newData, editingKey: '' });
            } else {
                newData.push(row);
                this.setState({ data: newData, editingKey: '' });

                if (this.props.onSubmit)
                    this.props.onSubmit({
                        id: record.id,
                        ...row
                    }, record);
            }

        });
    }

    edit(key) {
        this.setState({ editingKey: key });
    }
    delete = (key) => {
        if (this.props.onDelete)
            this.props.onDelete(key);
    }

    render() {
        const components = {
            body: {
                cell: EditableCell,
            },
        };

        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    inputType: col.dataIndex === 'age' ? 'number' : 'text',
                    xtype: col.xtype,
                    choices: col.choices,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                    ...col
                }),
            };
        });

        return (
            <EditableContext.Provider value={this.props.form}>
                <Table
                    size="small"
                    bordered={false}
                    rowKey={this.props.rowKey || "id"}
                    components={components}
                    dataSource={this.props.dataSource && this.props.dataSource.map(it => ({
                        key: it.id,
                        ...it
                    }))}
                    columns={columns}
                    showHeader
                    onExpand={this.props.onExpand}
                    expandedRowRender={this.props.expandedRowRender}
                    rowClassName="editable-row"
                    pagination={false}
                />
            </EditableContext.Provider>
        );
    }
}
const EditableFormTable = Form.create()(EditableTable);
export default EditableFormTable;