import React, { Component } from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    useGetOne,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import { Title, ImgField } from "./";
import S3File from "../../components/S3-field";
import { expressCategoriesService } from "../../utils/Api";
import { URL_S3 } from "../../constants";
import { getService } from "../../services"
import { Avatar } from "../../components/com";

const service = getService("authors");

export default class AuthorEdit extends Component {
    state = {
        profile_picture: null,
        expanded: false,
    };

    async fetchData() {
        console.log(this.props.id);
        if (this.props.id)
            service.get(this.props.id)
                .then(({ profile_picture }) => {
                    this.setState({ profile_picture })
                })
    }

    componentDidMount() {
        this.fetchData();
    }

    handleUploadFinish = async (url) => {


        this.setState({
            profile_picture: url
        });
        if (this.props.id)
            service.patch(this.props.id, { profile_picture: url })
    };
    render() {
        const { profile_picture } = this.state;
        console.log(profile_picture);

        return (
            <Edit title={<Title />} {...this.props}>
                <SimpleForm>
                    <Grid container fullWidth spacing={16}>
                        <TextInput source="id" fullWidth type="hidden" />
                        <Grid item xs={6}>
                            <TextInput fullWidth source="name" label="Nombre" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput fullWidth
                                label="ID Usuario"
                                source="user_id" />
                            {/* <ReferenceInput
                                fullWidth
                                label="Usuario"
                                source="user_id"
                                reference="users"
                            >
                                <SelectInput optionText="first_name" />
                            </ReferenceInput> */}
                        </Grid>
                        <Grid item xs={6}>
                            {
                                <Avatar
                                    {...this.props}
                                    size="large"
                                    style={{
                                        width: "100px",
                                        height: "100px",
                                    }}
                                    source="profile_picture"
                                    src={profile_picture}
                                    className="custom-img-field"
                                />
                            }
                            <S3File
                                idComponent="category-image"
                                path="categories"
                                handleUploadFinish={this.handleUploadFinish}
                                id={this.props.match.id}
                            />
                        </Grid>
                    </Grid>
                </SimpleForm>
            </Edit>
        );
    }
}
