import styled from 'styled-components';
import { TextInput } from "react-admin";
export const HiddenField = styled(TextInput)`
    width:100%;
    height:0px!important;
    padding: 0px!important;
    background:red;
    & .MuiFilledInput-marginDense::before{
        content:none!important;
    }
`; 

export const FormContainer = styled.div`
    min-width:400px;
    min-height:100vh!important;

    padding:0px;
    margin:0px;

    & .MuiToolbar-root{
       /*  position: absolute!important; */
       /*  bottom: 0!important; */
        width: 100%!important;
    }
    & .head-title{
        display: flex;
        color: var(--color-white)!important;
        justify-content: space-between;
        padding: 0px 16px;
        margin-top: 10px!important;
        margin-bottom: 0px!important;
    }
    & .MuiPaper-root{
        box-shadow:none!important;
    }
`;