import React, { Fragment, useCallback, useState } from "react";
import {
  List,
  BulkDeleteButton,
  Datagrid,
  TextField,
  EditButton,
  SelectField,
  TextInput,
  EmailField,
  ReferenceInput,
  SelectInput,
  Filter
} from "react-admin";
import { role_list, Title } from "./";
import { Route, useHistory } from "react-router-dom";
import { Drawer, useMediaQuery, makeStyles } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { CreateButton, ExportButton, RefreshButton } from "react-admin";
import Toolbar from "@material-ui/core/Toolbar";
import BulkAcceptButton from "./BulkAcceptButton";
import BulkRejectButton from "./BulkRejectButton";
import { MoneyField, StatusField, StatusSelectInput, CountField } from "../../components/com"
import { CRUD } from "../../components/com/crud/"
import UserEdit from "./UserEdit";
import { userCommercialCompaniesService } from "../../utils/Api";
import { getService } from "../../services";
import { message } from "antd";

const status = [
  {
    id: "active",
    name: "Activo"
  },
  {
    id: "inactive",
    name: "Inactivo"
  },
  {
    id: "pending security verification",
    name: "Pendiente Verificación Seguridad"
  },
  {
    id: "pending user data",
    name: "Pendiente Datos Usuario"
  },
  {
    id: "pending validation",
    name: "Pendiente Validación"
  }
]


const owner_company = [
  { id: "true", name: "Si" },
  { id: "false", name: "No" }
];

const Filters = props => (
  <Filter {...props}>
    <TextInput label="Título" source="title" alwaysOn />
    <SelectInput label="Rol" source="role" choices={role_list} alwaysOn />
    <StatusSelectInput choices={status} alwaysOn />
    <TextInput label="Buscar" source="data_value" alwaysOn />
  </Filter>
);
const PostActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button"
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
      {/* Add your custom actions */}
      <Button color="primary" /* onClick={customAction} */>Custom Action</Button>
    </Toolbar>
  );
const ReviewsBulkActionButtons = props => (
  <Fragment>
    <BulkAcceptButton {...props} />
    <BulkRejectButton {...props} />
    <BulkDeleteButton {...props} />
  </Fragment>
);
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(["all"], {
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  },
  listWithDrawer: {
    marginRight: 400
  },
  drawerPaper: {
    zIndex: 100
  }
}));

const UserName = ({ record }) => {
  return record ? `${record.first_name} ${record.last_name}` : null;
};

const GuideList = props => {
  const [isMatch, setIsMatch] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const handleClose = useCallback(() => {
    history.push("/users");
  }, [history]);

  const handleAdd = useCallback(() => {
    history.push("/users");
  }, [history]);

  const columns = [
    {
      source: "id",
      label: "ID",
      placeholder: "ID",
    },
    {
      xtype: "customfield",
      source: "first_name",
      label: "Nombres",
      placeholder: "Nombres",
      render: (value, record) => (`${record.first_name || ""} ${record.last_name || ""}`)
    },
    {
      source: "dni",
      label: "DNI",
      placeholder: "DNI",
    },
    {
      source: "email",
      label: "Correo Electrónico",
      placeholder: "Correo Electrónico",
    },
    {
      xtype: "textfield",
      source: "company_id",
      reference: "companies",
      label: "Compañia",
      placeholder: "Compañia",
    },
    {
      xtype: "selectfield",
      source: "role",
      label: "Rol",
      choices: role_list,
      placeholder: "Rol",
    },
    {
      xtype: "selectfield",
      source: "owner_company",
      label: "Owner Company",
      choices: owner_company,
      placeholder: "Rol",
    },
    {
      xtype: "selectfield",
      source: "status",
      label: "Estado",
      filter: true,
      choices: status,
      placeholder: "Estado"
    }
  ]
  const fields = [
    {
      source: "first_name",
      label: "Nombre",
      placeholder: "Nombre",
    },
    {
      source: "last_name",
      label: "Apellido",
      placeholder: "Apellido",
    },
    {
      source: "dni",
      label: "DNI",
      placeholder: "DNI",
    },
    {
      source: "email",
      label: "Correo Electrónico",
      placeholder: "Correo Electrónico",
    },
    {
      source: "phone",
      label: "Teléfono",
      placeholder: "Teléfono",
    },
    {
      xtype: "textfield",
      source: "company_id",
      reference: "companies",
      label: "Compañía",
      placeholder: "Compañía",
    },
    {
      xtype: "selectfield",
      source: "role",
      label: "Rol",
      choices: role_list,
      placeholder: "Rol",
    },
    {
      source: "password",
      label: "Contraseña",
      placeholder: "●●●●●●●●●●",
    },
    {
      xtype: "selectfield",
      source: "owner_company",
      fullWidth: true,
      label: "Administrador de su compañía",
      choices: owner_company,
      placeholder: "Rol",
    },
    {
      xtype: "selectfield",
      source: "is_commercial",
      fullWidth: true,
      label: "Es Comercial",
      choices: owner_company,
      placeholder: "Rol",
    },
    {
      xtype: "statusfield",
      source: "status",
      label: "Estado",
      fullWidth: true,
      choices: status,
      placeholder: "Estado"
    }
  ]

  const handleDonwload = async () => {
    const user = getService('users');
    await user.find({
      query: {
        $client: {exportData: 'true'}
      }
    })
    .then(({data}) => {
        window.open(data?.[0]?.objectUrl)
    })
    .catch(err => {
        message.error(err?.message)
    });
  }

  return (
    <div style={{padding: '20px 0px 0px'}}>
      <div style={{float: 'right'}}>
        <Button onClick={handleDonwload} color="primary" type="button">Donwload</Button> 
      </div>
      <CRUD
        tools={<div>
          <CountField
            label="Pendiente Verificación Seguridad"
            reference="users"
            query={{
              status: "pending security verification"
            }} />
        </div>}
        filters={<Filters />}
        columns={columns}
        fields={fields}
      />
    </div>
  );
};
export default GuideList;
