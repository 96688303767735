import React, { useState, useEffect } from "react";
import { GalleryProduct } from "./views/";
import {
  NumberInput,
  Datagrid,
  TextField,
  Edit,
  ReferenceInput,
  SimpleForm,
  TextInput,
  Show,
  SelectInput,
  List,
  CardActions,
  TabbedShowLayout,
  useEditController,
  maxLength,
  number,
  DateInput,
  Tab,
} from "react-admin";
import { Button, Grid, Paper } from "@material-ui/core/";
import { typeList, statusList, statusLiquidacion } from "./";
import { Link } from "react-router-dom";
import { Avatar } from "../../components/com";
import { getService } from "../../services";
import { message } from "antd";
import PriceList from "./PriceList";
import ProductCharacteristics from "./ProductCharacteristics";

const CreateRelatedCommentButton = (props) => {
  console.log("id!", props);
  return (
    <Button
      component={Link}
      to={{
        pathname: "/services-categories/create",
        state: { category_id: props.id },
      }}
    >
      Create
    </Button>
  );
};

const PostActions = ({ id }) => (
  <CardActions>
    <CreateRelatedCommentButton id={id} />
  </CardActions>
);
const validations = [maxLength(40, "El campo debe ser  menor a 40 caracteres")];
export const SCategoryShow = (props) => {
  const { state = {} } = props.location;
  const controllerProps = useEditController(props);
  const [nutritional_table_path, setImage] = useState();
  const updateImageProduct = (field, url) => {
    let { id } = controllerProps.record;
    const service = getService("products");
    service.patch(id, {
      [field]: url
    }).then(response => message.info("Imágen Actualizada!"))
      .catch(err => message.error(err.message));
  }

  if (!controllerProps.record) return null;

  delete controllerProps.record.media;
  delete controllerProps.record.brand;
  delete controllerProps.record.product_price_list;
  delete controllerProps.record.category;

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Datos del producto">
          <Edit {...props} /* actions={<PostActions id={props.id} />} */>
            <SimpleForm>
              <Grid container spacing={16} fullWidth>
                <Grid container fullWidth xs={12}>
                  <Grid item xs={3}>
                    <SelectInput
                      label="Estado"
                      source="status"
                      choices={statusList}
                      optionText="name"
                      optionValue="id"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <SelectInput
                      label="Producto en liquidación"
                      source="settlement"
                      choices={statusLiquidacion}
                      optionText="name"
                      optionValue="id"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <SelectInput
                      label="Producto oversize"
                      source="oversize"
                      choices={statusLiquidacion}
                      optionText="name"
                      optionValue="id"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid item xs={4} fullWidth>
                  <TextInput source="name" label="Nombre"
                    fullWidth
                  /* validate={validations} */
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextInput fullWidth source="code" label="Código" />
                </Grid>
                <Grid item xs={3}>
                  <ReferenceInput
                    fullWidth
                    sort={{
                      field: "name",
                      order: "ASC"
                    }}
                    perPage={10000}
                    label="Categoria"
                    source="category_id"
                    reference="categories"
                  >
                    <SelectInput source="name" />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={3}>
                  <ReferenceInput
                    fullWidth
                    sort={{
                      field: "name",
                      order: "ASC"
                    }}
                    perPage={10000}
                    label="Categoria 2"
                    source="category_id_2"
                    reference="categories"
                  >
                    <SelectInput source="name" />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={3}>
                  <ReferenceInput
                    fullWidth
                    sort={{
                      field: "name",
                      order: "ASC"
                    }}
                    perPage={10000}
                    label="Categoria 3"
                    source="category_id_3"
                    reference="categories"
                  >
                    <SelectInput source="name" />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={3}>
                  <ReferenceInput
                    fullWidth
                    sort={{
                      field: "name",
                      order: "ASC"
                    }}
                    perPage={10000}
                    label="Categoria 4"
                    source="category_id_4"
                    reference="categories"
                  >
                    <SelectInput source="name" />
                  </ReferenceInput>
                </Grid>

                <Grid item xs={3}>
                  <ReferenceInput
                    fullWidth
                    label="Marca"
                    sort={{
                      field: "name",
                      order: "ASC"
                    }}
                    perPage={10000}
                    source="brand_id"
                    reference="brands"
                  >
                    <SelectInput source="name" />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12} > <h2>Precios:</h2></Grid>

                <Grid item xs={3} >
                  <NumberInput fullWidth source="price" label="Precio SIN IVA" />
                </Grid>
                <Grid item xs={3}>
                  <ReferenceInput
                    fullWidth
                    label="IVA %"
                    source="tax_rule_id"
                    reference="tax-rule"
                  >
                    <SelectInput source="value" />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={3} >
                  <NumberInput disabled fullWidth source="price_with_tax" label="Precio CON IVA" />
                </Grid>
                <Grid item xs={12} ></Grid>
                <Grid item xs={3} >
                  <NumberInput
                    fullWidth
                    source="discount_percentage"
                    label="Porcentaje de descuento"
                  />
                </Grid>
                <Grid item xs={3} >
                  <DateInput
                    fullWidth
                    source="discount_end_date"
                    label="Caducidad del descuento"
                  />
                </Grid>
                <Grid item xs={3} >
                  <NumberInput
                    fullWidth
                    disabled
                    source="discount_price"
                    label="Precio en descuentos sin IVA"
                  />
                </Grid>
                <Grid item xs={3} >
                  <NumberInput disabled fullWidth source="discount_price_whit_tax" label="Precio en descuento CON IVA" />
                </Grid>
                <Grid item xs={12} > <h2>Cantidades:</h2></Grid>
                <Grid item xs={3}>
                  <NumberInput fullWidth source="quantity" label="Cantidad Disponible" />
                </Grid>
                <Grid item xs={3} >
                  <TextInput fullWidth source="unit_measure" label="Unidad de Medida" />

                </Grid>
                <Grid item xs={3} >
                  <NumberInput fullWidth source="quantity_per_unit_measure" label="Cantidad por Unidad de Medida" />
                </Grid>
                <Grid item xs={12} > <h2>Peso y Medidas:</h2></Grid>
                <Grid item xs={3} fullWidth>
                  <NumberInput
                    source="weight"
                    label="Peso en kg"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3} fullWidth>
                  <NumberInput
                    source="heigh"
                    label="Alto en cm"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3} fullWidth>
                  <NumberInput
                    source="width"
                    label="Ancho en cm"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3} fullWidth>
                  <NumberInput
                    source="long"
                    label="Largo en cm"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} > <h2>Descripción:</h2></Grid>
                <Grid item xs={12} fullWidth>
                  <TextInput multiline source="description" label="Descripcion" fullWidth />
                </Grid>
              </Grid>
              <Grid item xs={12} > <h2>Integración:</h2></Grid>
              <Grid item xs={3}>
                <TextInput disabled fullWidth source="integration_id" label="Integration ID" />
              </Grid>
              <Grid item xs={9} fullWidth>
                <TextInput fullWidth disabled source="integration_hash" label="Integration Hash" />
              </Grid>

            </SimpleForm>
          </Edit>
        </Tab>
        <Tab label="Galeria">
          <GalleryProduct />
        </Tab>
        {<Tab label="Listas de Precio">
          <PriceList />
        </Tab>}
        <Tab label="Caracteristicas del producto">
          <ProductCharacteristics />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
