import React, { Component, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { blogsAndGuidesService } from "../../utils/Api";
import S3File from "../../components/S3-field";

import {
  SimpleForm,
  TextInput,
  Edit,
  SelectInput,
  NumberInput,
} from "react-admin";

import { Grid } from "@material-ui/core";
import { URL_S3 } from "../../constants";

export default class BlogsAndGuidesEdit extends Component {
  state = {
    path_image: null,
  };

  fetchData(props) {
    blogsAndGuidesService
      .find({ query: { id: this.props.id } })
      .then((it) => this.setState({ path_image: it.data[0].image_cover }));
  }

  componentDidMount() {
    console.log(this.props);
    this.fetchData(this.props);
  }

  handleUploadFinish = (url, id) => {
    blogsAndGuidesService
      .patch(this.props.id, { image_cover: url })
      .then((it) => this.fetchData());
  };

  render() {
    const { path_image } = this.state;
    return (
      <Edit {...this.props}>
        <SimpleForm>
          <Grid container fullWidth spacing={16}>
            <Grid container fullWidth item xs={12}>
              <Grid item xs={6} spacing={6} fullWidth>
                <TextInput source="title" label="Titulo" fullWidth />
              </Grid>
              <Grid item xs={6} spacing={6} fullWidth>
                <NumberInput source="priority" label="Prioridad" fullWidth />
              </Grid>
              <Grid container fullWidth item xs={12}>
                <Grid item xs={6} spacing={6}>
                  <SelectInput
                    fullWidth
                    label="Estado"
                    source="status"
                    choices={[
                      { id: "active", name: "Activo" },
                      { id: "inactive", name: "Inactivo" },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} spacing={6} fullWidth>
                  <TextInput
                    source="description"
                    label="Descripción"
                    fullWidth
                    multiline
                  />
                  <TextInput multiline fullWidth source="short_description" label="Descripcion Corta" />
                </Grid>
              </Grid>

              <Grid item xs={12} fullWidth style={{ textAlign: "center" }}>
                {path_image ? (
                  <img
                    style={{ borderRadius: "10px", margin: 10 }}
                    fullWidth
                    src={`${URL_S3}${path_image}`}
                    width="400px"
                    height="400px"
                    className="custom-img-field"
                  />
                ) : null}
                <S3File
                  idComponent="wiki"
                  path="blogs-and-guides"
                  handleUploadFinish={this.handleUploadFinish}
                  id={this.props.id}
                />
              </Grid>
            </Grid>

          </Grid>
        </SimpleForm>
      </Edit>
    );
  }
}
