import React, { useState, useEffect } from "react"
import { Row, Col, Table, Card, Button, Modal, Tag, message } from "antd";
import moment from "moment";
import { getService } from '../../services'
import styled from 'styled-components'
import { money } from "../../utils/Helper"
import { Avatar, HeadLine } from "../../components/com";
import UserCard from '../../components/UserCard';
import HistoryTable from "../../components/orders/HistoryTable";
import Shipping from "./Shipping";
import DetailShipping from "./DetailShipping";
import 'moment/locale/es';
moment.locale("es");

const MyTable = styled(Table)`
& .ant-table{
  box-shadow:3px 3px 3px #ccc;
}`;
const columns_products = [
    {
        title: 'Foto',
        width: 50,
        dataIndex: 'express_product.image_main',
        render: value => (<Avatar size="tiny" src={value} />),
        key: 'picture',
    },
    {
        title: 'Nombre',
        dataIndex: 'express_product.name',
        key: 'name',
        width: 100
    },
    {
        title: 'Precio',
        dataIndex: 'unit_price_tax_incl',
        render: value => (money(value)),
        key: 'price',
    },
    {
        title: 'Cantidad',
        dataIndex: 'quantity',
        key: 'quantity',
    },
    {
        title: 'Cantidad Enviada',
        dataIndex: 'sent',
        key: 'sent',
    },
    {
        title: 'Restantes',
        dataIndex: 'quantity',
        key: 'quantity',
        render: (value, record) => (`${Number(value) - Number(record.sent)}`)
    },
]
const columns_shipping = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 50
    },
    {
        title: 'Enviado por',
        width: 200,
        dataIndex: 'delivery_guy_user',
        key: 'delivery_guy_user',
        render: (record) => (<>
            {`${record.first_name} ${record.last_name || ""}`}
        </>),
    },
    {
        title: 'Estado',
        dataIndex: 'shipping_status.name',
        key: 'status',
    },
    {
        title: 'Fecha',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (value) => (<>
            {moment(value).format("LLLL")}
        </>),
    },

]
//products-orders-details
const expressProductDetailService = getService("products-orders-details");

const ShowOrder = (props) => {
    const [historyList, sethistoryList] = useState([]);
    const [record, setRecord] = useState({});
    const [current_shipping, setCurrentShipping] = useState({});
    const [show, setShow] = useState(false);
    const [show_detail, setShowDetail] = useState(false);
    const [type_product, setTypeProduct] = useState();
    const [loading, setLoading] = useState(false);
    const [order_id, setOrderId] = useState();
    const [order_express_id, setOrderExpressId] = useState();
    const [details, setDetails] = useState([]);
    const [shipping, setShipping] = useState({});
    const [shippings, setShippingDaata] = useState([]);
    const [shippingaddress, setShippingAddreess] = useState({});
    const [user_id, setUserId] = useState();

    const getProductOrderDetails = id => {
        expressProductDetailService.find({
            query: {
                express_product_order_id: id
            }
        })
            .then(({ data }) => {
                setDetails(data)
            })
    }
    const getData = id => {
        const service = getService("products-orders");
        setLoading(true);
        service.get(id)
            .then(record => {

                console.log("RECORD: ", record)
                setRecord(record);
                setOrderId(record.order_id);
                if (record.shipping_address_meta_data) {
                    let shipping = JSON.parse(record.shipping_address_meta_data);
                    /* alert("EXPRESS USER!" + JSON.stringify(shipping)) */
                    setUserId(shipping.user_id);
                    setShippingAddreess(shipping);
                }
                setLoading(false);
            });
    }
    const getShipping = (id) => {
        const service = getService("shipping");
        service.find({
            query: {
                sub_order_id: id
            }
        })
            .then(({ data }) => {
                console.log(data);
                if (data.length > 0) {
                    setShippingDaata(data);
                }
            })
    }
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: record => ({
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
        }),
    };
    const handleShipping = async (type) => {
        let shipping = {};
        const service = getService("shipping");
        let sub_orders = details.filter(it => it.type_product == type);
        if (sub_orders.length == 0) return message.warn("No hay registros para enviar.")
        try {
            setLoading(true);
            const [shipping_result] = await Promise.all([service.find({
                query: {
                    shipping_status_id: 1,
                    sub_order_id: order_express_id
                }
            })]);
            let { data } = shipping_result;
            if (data)
                shipping = data[0];
            if (typeof data === "undefined" || data.length == 0) {
                const [new_shipping] = await Promise.all([
                    service.create({
                        sub_order_id: Number(order_express_id),
                        "type_sub_order": "express products",
                        order_id: Number(order_id)
                    })
                ]);
                shipping = new_shipping;
            }

            console.log("SHIPPING: ", shipping)
            setShipping(shipping);
            setTypeProduct(type);
            setLoading(false);
            setShow(true);
        } catch (error) {
            message.error(`Error al crear Shipping: ${error.message}`);
            setLoading(false);
        }
    }
    const init = () => {
        let { match } = props;
        let { id } = match.params;
        if (id) {
            setOrderExpressId(id);
            getData(id);
            getProductOrderDetails(id);
            getShipping(id);
        }
    }
    const handleShowDetail = record => {
        setCurrentShipping(record);
        setShowDetail(true);
    }
    const handlePayment = record => {
        init();
        setShowDetail(false);
    }
    useEffect(() => {
        init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id])
    return (
        <Row gutter={8}>
            <Col>

                <Row gutter={8} type="flex" justify="start" align="middle">
                    <Col>
                        <HeadLine>Pedido # {order_id} - {order_express_id}</HeadLine>
                    </Col>
                    <Col>
                        <>{record.order && <Tag style={{
                            fontSize: 24,
                            height: 30
                        }} size="large">Metodo de pago: {record.order.payment_method}</Tag>}</>
                    </Col>
                </Row>

                {record?.integration_id && (
                    <Row gutter={8} type="flex" justify="start" align="middle">
                        <Col>
                            <HeadLine size={"medium"}>Integration Id # {record?.integration_id}</HeadLine>
                        </Col>
                    </Row>
                )}

                <Row gutter={8}>
                    <Col span={12}>
                        <div style={{
                            margin: "10px 0px"
                        }}>
                            <HeadLine size="medium" style={{
                                marginBottom: 0
                            }}>Estados de Pedidos</HeadLine>
                            {/* user && <div>
                            <span>
                                {user.first_name}
                            </span>
                        </div> */}
                        </div>
                        {<HistoryTable
                            source="products-orders-history"
                            loading={loading}
                            params={{ express_product_order_id: order_express_id }} />}
                    </Col>
                    <Col span={12}>
                        <div>
                            <Row>
                                <Col>
                                    <div style={{
                                        margin: "10px 0px"
                                    }}>
                                        <HeadLine size="medium" style={{
                                            marginBottom: 0
                                        }}>Datos del cliente</HeadLine>
                                    </div>
                                    <UserCard id={user_id} params={shippingaddress} />
                                </Col>
                            </Row>
                        </div>

                    </Col>
                </Row>
            </Col>
            <Col>
                <Row gutter={8} type="flex" justify="start" align="middle">
                    <Col>
                        <HeadLine size="medium">Envíos del Pedido </HeadLine>
                    </Col>
                    <Col>
                        <>{record.order && <Tag style={{
                            fontSize: 24,
                            height: 30
                        }} size="large">Metodo de pago: {record.order.payment_method}</Tag>}</>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {<MyTable
                            loading={loading}
                            scroll={{ y: 285 }} size="small"
                            dataSource={shippings}
                            columns={[...columns_shipping,
                            record.order && record.order.payment_method == "cash_on_delivery" && {
                                title: "Pago recibido",
                                dataIndex: "payment_received",
                                render: value => (money(value)),
                            },
                            record.order && record.order.payment_method == "cash_on_delivery" && {
                                title: "Pago pendiente",
                                dataIndex: "pending_payment",
                                render: (value, record) => {
                                    if (value > 0)
                                        return <span style={{
                                            color: "red"
                                        }}>{`${money(value)}`}</span>;
                                    return (<span>{`${value}`}</span>);
                                }
                            },
                            {
                                title: "Acciones",
                                render: (record) => {
                                    return (<Button
                                        onClick={() => handleShowDetail(record)} icon="eye" type="link">
                                        Ver Detalle
                                    </Button>)
                                }
                            }].filter(it => (typeof it != "undefined"))}
                            pagination={false} />}
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={12}>
                        {<div className="table-operations">
                            <HeadLine size="medium" style={{
                                marginBottom: 0,
                            }}>Entrega Inmediata
                            </HeadLine>
                            {
                                (record.order_status_id == 14 ||
                                    (record.order_status_id == 2 && record.order.payment_method == "cash_on_delivery") ||
                                    record.order_status_id == 6 ||
                                    record.order_status_id == 10) && !loading ?
                                    <Button
                                        disabled={
                                            details
                                                .filter(it => it.type_product == "not_scheduled").length == 0
                                        }
                                        style={{
                                            margin: "15px 0px"
                                        }} loading={loading} onClick={() => handleShipping("not_scheduled")} type="primary">Generar</Button> :
                                    <Tag style={{
                                        margin: "15px 0px",
                                        maxHeight: 22
                                    }} color="green">{record.order_status && record.order_status.name}</Tag>
                            }
                        </div>
                        }
                        {<MyTable
                            loading={loading}
                            /*  rowSelection={rowSelection} */
                            scroll={{ y: 285 }} size="small"
                            dataSource={details
                                .filter(it => it.type_product == "not_scheduled")
                            } columns={
                                columns_products

                            } pagination={false} />}
                    </Col>
                    <Col span={12}>
                        {
                            <div className="table-operations">
                                <HeadLine size="medium" style={{
                                    marginBottom: 0
                                }}>Entrega Programada
                                </HeadLine>
                                {record.order_status_id}
                                {
                                    (
                                        record.order_status_id == 14 ||
                                        (record.order_status_id == 2 && record.order.payment_method == "cash_on_delivery") ||
                                        record.order_status_id == 6 ||
                                        record.order_status_id == 10) && !loading ?
                                        <Button style={{
                                            margin: "15px 0px"
                                        }} loading={loading}
                                            disabled={
                                                details
                                                    .filter(it => it.type_product == "not_scheduled").length == 0
                                            }
                                            onClick={() => handleShipping("scheduled")} type="ghost">Generar</Button> :
                                        <Tag style={{
                                            margin: "15px 0px",
                                            maxHeight: 22
                                        }} color="green">{record.order_status && record.order_status.name}</Tag>
                                }
                            </div>
                        }
                        {details && <MyTable
                            loading={loading}
                            /*  rowSelection={rowSelection} */
                            scroll={{ y: 285 }} size="small" dataSource={details
                                .filter(it => it.type_product != "not_scheduled")
                            } columns={columns_products
                            } pagination={false} />}
                    </Col>
                </Row>
                <Row gutter={8} type="flex" justify="center">

                    <Col span={24} style={{
                        padding: "0px 4px"
                    }}>
                        <HeadLine size="medium">
                            <span>Resumen</span>
                        </HeadLine>
                        <Card>
                            <Row gutter={16} type="flex" justify="space-between">
                                <Col><strong>SubTotal:</strong></Col>
                                <Col>{money(record.total_price_tax_excl)}</Col>
                            </Row>
                            <Row gutter={16} type="flex" justify="space-between">
                                <Col><strong>Costo Envío:</strong></Col>
                                <Col>{money(record.shipping_cost)}</Col>
                            </Row>
                            <Row gutter={16} type="flex" justify="space-between">
                                <Col><strong>Iva:</strong></Col>
                                <Col>{money(record.total_tax)}</Col>
                            </Row>
                            <Row gutter={16} type="flex" justify="space-between">
                                <Col><strong>Total:</strong></Col>
                                <Col>{money(record.total_price)}</Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Col>

            <Modal
                width={"80%"}
                centered
                visible={show}
                footer={null}
                destroyOnClose={true}
                onOk={() => setShow(false)}
                onCancel={() => {
                    init();
                    setShow(false);
                }}
            >
                <>

                    <Shipping
                        id={order_express_id}
                        visible={show}
                        onCancel={() => {
                            init();
                            setShow(false);
                        }}
                        type_product={type_product}
                        shipping={shipping} />
                </>
            </Modal>
            <Modal
                width={"80%"}
                centered
                visible={show_detail}
                footer={null}
                destroyOnClose={true}
                onOk={() => setShowDetail(false)}
                onCancel={() => {
                    setShowDetail(false);
                }}
            >
                {show_detail && <DetailShipping
                    onSubmit={handlePayment}
                    record={current_shipping} />}
            </Modal>
        </Row>

    )
}
export default ShowOrder;
