import React from "react";
import CRUD from '../../components/com/crud/CRUD';
import { CountField, Grid } from "../../components/com/";

const columns = [
   /* {
        source: "id",
        label: "Id",
    },*/
    {
        xtype: "textfield",
        source: "name",
        fullWidth: true,
        label: "Nombre",
    },
    {
        xtype: "textfield",
        source: "status",
        fullWidth: true,
        label: "Estado"
    },
    {
        xtype: "datefield",
        source: "updated_at",
        label: "UpdatedAt",
    },
];
const View = ({ id, ...props }) => {
    return (<CRUD
        title="DMS Estados"
        sort={{ field: 'id', order: 'DESC' }}
        actions={{
            create: false,
            delete: false,
            edit: false,
        }}
        create={false}
        source="eme-integration-status"
        columns={columns}
    />)
}
export default View;