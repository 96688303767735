import React, { useState } from "react";
import axios from 'axios'

import { Box } from "@material-ui/core";
import { Button } from "antd";
import { getService } from "../../utils/Api";


const ImportExportProducts = () => {
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [fileValue, setFileValue] = useState()

  const handleDownload = async () => {
    if (loadingDownload) return
    setLoadingDownload(true)

    await getService('export-import-discount-products')
      .find()
      .then(res => {
        window.open(res, '_blank')
      })
      .catch(() => {
        // TODO
      })
      .finally(() => {
        setLoadingDownload(false)
      })
  }

  const handleUploadFile = async (e) => {
    if (loadingUpload || !e?.target?.files?.length) return
    setLoadingUpload(true)

    const formData = new FormData();
    formData.append("excel_file", e.target.files[0]);

    await axios.post(
      'https://api.estrategias-ltda.com/export-import-discount-products',
      formData,
      {
        headers: {
          'authorization': `Bearer ${window.localStorage.getItem('feathers-jwt')}`
        }
      }
    )
      .then(() => {
        setFileValue(undefined)
      })
      .catch(() => {
        // TODO
      })
      .finally(() => {
        setLoadingUpload(false)
      })
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <h2>
        Importar exportar productos
      </h2>
      <Box display="flex" gridGap={20} justifyContent="center">
        <Button onClick={handleDownload} loading={loadingDownload}>Descargar csv de productos</Button>
        <input type="file" value={fileValue} style={{ display: 'none' }} multiple={false} id="upload-csv" onChange={handleUploadFile} />
        <Button loading={loadingUpload} onClick={() => document.getElementById('upload-csv').click()}>Subir csv de productos</Button>
      </Box>
    </div>
  );
};
export default ImportExportProducts;
