import styled from "styled-components";
export const WrapperCost = styled.div`
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 2px 2px 2px #ccc;
  margin: 4px 9px;
  max-width: 20%;

  cursor: pointer;

  background: ${props => (props.active ? "#e6f7ff" : "#FFF")}!important;
  & h2 {
    margin-bottom: 0px;
    color: ${props =>
      props.active ? "rgba(0, 0, 0, 0.7)" : "rgba(0, 0, 0, 0.7)"}!important;
  }
  & h3 {
    font-size: 24px;
    font-weight: bold;
    color: ${props =>
      props.active ? "rgba(0, 0, 0, 0.7)" : "rgba(0, 0, 0, 0.8)"}!important;
  }
`;
