import React from 'react';
import { SimpleForm, SelectField, DinamicForm } from "../../components/com/form/";
import { Input, InputNumber } from 'antd';
import { Box } from '../../components/com/';
const status = [
    {
        id: "PENDIENTE",
        name: "PENDIENTE"
    },
    {
        id: "TERMINADO",
        name: "TERMINADO"
    },
    {
        id: "CORRIENDO",
        name: "CORRIENDO"
    },
];
let fields = [
    {
        xtype: "textfield",
        source: "name",
        placeholder: "Nombre",
        label: "Nombre"
    },
    {
        xtype: "selectfield",
        source: "status",
        label: "Estado",
        choices: status
    },
]
const Form = props => {
    return (
        <Box show title="Estado">
            <DinamicForm
                source="eme-integration-status"
                fields={fields}
            />
        </Box>
    )
}
export default Form;