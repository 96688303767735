import React, { Fragment, useCallback, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  SelectField,
  SelectInput,
  Filter,
  TextInput
} from "react-admin";
import { Title } from ".";
import { Route, useHistory } from "react-router-dom";
import { Drawer, makeStyles } from "@material-ui/core";
import { Avatar, CustomList, StatusField } from "../../components/com"
import MembershipEdit from "./BrandsEdit";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(["all"], {
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  },
  listWithDrawer: {
    marginRight: 400
  },
  drawerPaper: {
    zIndex: 100
  }
}));
const statusList = [
  { id: "active", name: "Activo" },
  { id: "inactive", name: "Inactivo" }
]


const CustomFilters = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Estado"
      source="status"
      choices={statusList}
      optionText="name"
      optionValue="id"
      alwaysOn
    />
    <TextInput label="Buscar" source="data_value" alwaysOn />
  </Filter>
)
const BrandsList = props => {
  const [isMatch, setIsMatch] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const handleClose = useCallback(() => {
    history.push("/brands");
  }, [history]);

  const ImgField = ({ record }) => {
    return (
      <img
        src={`https://estrategia-web.s3.amazonaws.com/${record.path_logo}`}
        height="40px"
        width="40px"
      />
    );
  };

  return (
    <div>
      <Route path="/brands/:id">
        {({ match }) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== "create"
          );
          return (
            <Fragment>
              <List
                {...props}
                title={<Title />}
                exporter={false}
                sort={{ field: "priority", order: "DESC" }}
                bulkActionButtons={false}
                filters={<CustomFilters />}
              >

                <Datagrid rowClick="edit" optimized {...props}>
                  <TextField source="id" />
                  <Avatar source="path_logo" label="Logo" />
                  <TextField label="Nombre" source="name" />
                  <SelectField
                    label="Estado"
                    source="status"
                    choices={statusList}
                    optionText="name"
                    optionValue="id"
                  />

                  <TextField label="Prioridad" source="priority" />
                  <StatusField
                    source="status"
                    label="Estado"
                  />
                  <EditButton label="Editar" />
                </Datagrid>
              </List>
              <Drawer
                variant="persistent"
                open={isMatch}
                anchor="right"
                onClose={handleClose}
                classes={{
                  paper: classes.drawerPaper
                }}
              >
                {isMatch ? (
                  <MembershipEdit
                    id={match.params.id}
                    onCancel={handleClose}
                    {...props}
                  />
                ) : null}
              </Drawer>
            </Fragment>
          );
        }}
      </Route>
    </div>
  );
};
export default BrandsList;
