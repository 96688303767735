import React, { Fragment, useCallback, useState } from 'react';
import {
	List,
	Datagrid,
	TextField,
	EditButton,
	SelectField,
	SelectInput,
	Filter,
	TextInput,
	DeleteButton,
} from 'react-admin';
import { Title } from '.';
import { Route, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { Avatar, StatusField } from '../../components/com';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	list: {
		flexGrow: 1,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginRight: 0,
	},
	listWithDrawer: {
		marginRight: 400,
	},
	drawerPaper: {
		zIndex: 100,
	},
}));

const typeList = [
	{ id: 'HOMETOP', name: 'Home' },
	{ id: 'HOMEPROMO', name: 'Home Promo' },
	{ id: 'FLASH_SALE', name: 'Flash Sale' },
];

const CustomFilters = (props) => (
	<Filter {...props}>
		<SelectInput label="Tipo" source="type" choices={typeList} optionText="name" optionValue="id" alwaysOn />
		<TextInput label="Buscar" source="data_value" alwaysOn />
	</Filter>
);
const BannerList = (props) => {
	const [isMatch, setIsMatch] = useState(false);
	const classes = useStyles();
	const history = useHistory();
	const handleClose = useCallback(() => {
		history.push('/Banner');
	}, [history]);

	const ImgField = ({ record }) => {
		return (
			<>
				<img
					src={`${record.path_movile}`}
					height="40px"
					width="40px"
					alt="banner movile"
				/>
				<img
					src={`${record.path_desktop}`}
					height="40px"
					width="40px"
					alt="banner en escritorio"
				/>
			</>
		);
	};

	return (
		<div>
			<Route path="/banner/:id">
				{({ match }) => {
					const isMatch = !!(match && match.params && match.params.id !== 'create');
					return (
						<Fragment>
							<List
								{...props}
								title={<Title />}
								exporter={false}
								sort={{ field: 'priority', order: 'DESC' }}
								bulkActionButtons={false}
								filters={<CustomFilters />}
							>
								<Datagrid rowClick="edit" optimized {...props}>
									<TextField source="id" />
									<Avatar source="path_movile" label="Vista movil" />
									<Avatar source="path_desktop" label="Vista en escritorio" />
									<TextField source="type" label="Tipo" />
									<TextField label="Prioridad" source="priority" />
									<TextField label="Url" source="url" />

									<EditButton label="Editar" />
									<DeleteButton label="Eliminar" />
								</Datagrid>
							</List>
						</Fragment>
					);
				}}
			</Route>
		</div>
	);
};
export default BannerList;
