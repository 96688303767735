import React from "react";
import CRUD from '../../components/com/crud/CRUD';
import { CountField } from "../../components/com/";

const columns = [
    {
        xtype: "textfield",
        source: "name",
        fullWidth: true,
        label: "Nombre",
        filter: true
    },
    {
        xtype: "textfield",
        source: "integration_id",
        label: "DMS"
    }
];
const fields = [
    {
        xtype: "textfield",
        source: "name",
        fullWidth: true,
        label: "Nombre",
    },
    {
        xtype: "textfield",
        source: "integration_id",
        fullWidth: true,
        showBy: "edit",
        label: "DMS"
    },
];
const Cities = props => {

    return <CRUD
        title="Estados/Departamento"
        actions={{ "delete": false }}
        tools={<div>
            <CountField
                label="Sin DMS"
                reference="locations-states" query={{
                    integration_id: "false"
                }} />
        </div>}
        columns={columns}
        fields={fields}
    />
}
export default Cities;