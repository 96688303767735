import React from "react";
import ExpressProductList from "./ExpressProductList";
import { SCategoryShow } from "./ExpressProdctEdit";
import { ExpressProductCreate } from "./ExpressProductCreate";
const Title = ({ record }) => {
  return <span>{record ? `Editar ${record.name}` : "Listado Marcas"}</span>;
};

const typeList = [
  { id: "scheduled", name: "Programado" },
  { id: "not_scheduled", name: "Express" }
];

const statusList = [
  { id: "active", name: "Activo" },
  { id: "inactive", name: "Inactivo" }
];

const statusLiquidacion = [
  { id: "true", name: "Si" },
  { id: "false", name: "No" }
];

export {
  Title,
  ExpressProductList,
  typeList,
  SCategoryShow,
  statusList,
  ExpressProductCreate,
  statusLiquidacion
};
