import React from 'react';
import { useHistory } from 'react-router-dom';
import { SimpleForm, Edit, TextInput, SelectInput, NumberInput, DateInput } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { makeStyles, Box, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	title: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: '1em',
	},
}));

const DiscountEdit = ({ onCancel, ...props }) => {
	const classes = useStyles();
	const history = useHistory();

	return (
		<Edit title={<div className={classes.title}>
			<Typography variant="h6">Editar código de descuento</Typography>
		</div>} {...props}>
			<SimpleForm>
				<Box style={{ width: '100%' }} display="flex" justifyContent="flex-end">
					<Button color="primary" variant="contained" onClick={() => history.push('/discounts')}>Regresar</Button>
				</Box>
				<TextInput fullWidth source="id" label={false} type="hidden" />
				<Grid container fullWidth spacing={2} display="flex">
					<Grid item sm={12}>
						<Grid item sm={3}>
							<SelectInput
								fullWidth
								source="status"
								label="Estado"
								choices={[
									{ id: 'active', name: 'Activo' },
									{ id: 'inactive', name: 'Inactivo' },
								]}
							/>
						</Grid>
					</Grid>
					<Grid item sm={4}>
						<TextInput source="name" label="Nombre del descuento" fullWidth />
					</Grid>
					<Grid item sm={4}>
						<TextInput source="code" label="Código a utilizar" fullWidth />
					</Grid>
					<Grid item sm={4}>
						<NumberInput source="quantity" label="Cantidad" fullWidth />
					</Grid>
					<Grid item sm={12}>
						<Grid container sm={8}>
							<Grid item sm={6}>
								<NumberInput
									source="value_percentage"
									label="Porcentaje de descuento"
									fullWidth
									helperText="Llene este campo si desea que el descuento sea en base a un porcentaje. Ej: 20"
								/>
							</Grid>
							<Grid item sm={6}>
								<NumberInput
									source="value_amount"
									label="Descuento fijo"
									fullWidth
									helperText="Llene este campo si desea que el descuento sea fijo. Ej: 50000"
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item sm={12}>
						<h3 style={{ marginTop: 10 }}>Condiciones</h3>
					</Grid>
					<Grid item sm={2}>
						<NumberInput fullWidth source="uses_by_user" label="Usos por usuario" />
					</Grid>
					<Grid item sm={2}>
						<DateInput fullWidth label="Fecha de inicio" source="date_start" />
					</Grid>
					<Grid item sm={2}>
						<DateInput fullWidth label="Fecha fin" source="date_end" />
					</Grid>
					<Grid item sm={2}>
						<NumberInput
							fullWidth
							source="order_min_amount"
							label="Valor de comprar mínima"
							helperText="Ingrese 0 si no requiere compra mínima"
						/>
					</Grid>
					<Grid item sm={3}>
						<SelectInput
							fullWidth
							source="can_apply_to_shipment"
							label="¿Puede aplicar al valor del envío?"
							choices={[
								{ id: 'true', name: 'Si' },
								{ id: 'false', name: 'No' },
							]}
						/>
					</Grid>
					<Grid item sm={3}>
						<SelectInput
							fullWidth
							source="can_apply_to_products_sale"
							label="¿Puede a productos en oferta?"
							choices={[
								{ id: 'true', name: 'Si' },
								{ id: 'false', name: 'No' },
							]}
						/>
					</Grid>
				</Grid>
			</SimpleForm>
		</Edit>
	)
}

export default DiscountEdit;