import React from "react";
import CRUD from '../../components/com/crud/CRUD';
const columns = [

    {
        source: "name",
        label: "Name",
    },
    {
        source: "code",
        label: "Code",
        filter: true
    },
    {
        source: "method",
        label: "Method",
        filter: true
    },
    {
        source: "data",
        label: "Data",
    },
    {
        source: "message",
        label: "Message",
    },
    {
        source: "path",
        label: "Path",
    },
    {
        xtype: "datefield",
        source: "createdAt",
        label: "CreatedAt",
    },
];
const View = ({ id, ...props }) => {
    return (<CRUD
        title="DMS Productos Web Logs"
        sort={{ field: 'id', order: 'DESC' }}
        actions={{
            create: false,
            delete: false,
            edit: false,
        }}
        create={false}
        columns={columns}
    />)
}
export default View;