import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import S3File from '../../components/S3-field';
import { bannerService } from '../../utils/Api';

import { SimpleForm, TextInput, useEditController, SelectInput, NumberInput, ReferenceInput, DateInput } from 'react-admin';
import CloseIcon from '@material-ui/icons/Close';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: 40,
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: '1em',
	},
	form: {
		/*  [theme.breakpoints.up("xs")]: {
      width: 400
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginTop: -30
    } */
	},
	inlineField: {
		display: 'inline-block',
		width: '50%',
	},
}));

const CompanyMembershipEdit = ({ onCancel, ...props }) => {
	const classes = useStyles();
	const controllerProps = useEditController(props);
	const history = useHistory();
	const handleClose = useCallback(() => {
		history.push('/banners');
	}, [history]);
	const [type, setType] = useState();
	const handleOnCancel = () => {
		if (onCancel) return onCancel();
		handleClose();
	};
	if (!controllerProps.record) {
		return null;
	}

	const oldType = controllerProps?.record?.type;

	const typeBanner = {
		HOMETOP: 'Para Home: Las medidas deben ser 1920x735. Si las medidas son mas altas conservar la relación.',
		HOMEPROMO: 'Para ofertas:Ancho 400x249. Si las medidas son mas altas guardar la relación.',
    POPUP: ' Para Popup: Ponle ancho 1000px alto personalizado. Si las medidas son mas altas conservar la relación.',
	};
	const typeBannerMobil = {
		HOMETOP: 'Para Home Mobile: las medidas deben ser de 500x656. Si las medidas son mas altas conservar la relación.',
		HOMEPROMO: 'Para ofertas:Ancho 400x249. Si las medidas son mas altas guardar la relación.',
    POPUP: ' Para Popup: Ponle ancho 1000px alto personalizado. Si las medidas son mas altas conservar la relación.',
	};

	const handleUploadFinishMovile = async (url, id) => {
		await bannerService.patch(controllerProps.record.id, { path_movile: url }, {}).then((it) => console.log(it));
	};
	const handleUploadFinishDesktop = async (url, id) => {
		await bannerService.patch(controllerProps.record.id, { path_desktop: url }, {}).then((it) => console.log(it));
	};
	return (
		<div className={classes.root}>
			<div className={classes.title}>
				<Typography variant="h6">Editar Banners</Typography>
				<IconButton onClick={handleOnCancel}>
					<CloseIcon />
				</IconButton>
			</div>
			<SimpleForm
				className={classes.form}
				basePath={controllerProps.basePath}
				record={controllerProps.record}
				save={controllerProps.save}
				version={controllerProps.version}
				redirect="list"
				resource="banner"
			>
				<TextInput fullWidth source="id" label={false} type="hidden" />
				<Grid fullWidth spacing={16}>
					<Grid item xl={12} spacing={6}>
						<TextInput source="url" label="Url" fullWidth />
					</Grid>
					<Grid item xl={12} spacing={6}>
						<NumberInput source="priority" label="Prioridad" fullWidth />
					</Grid>
					<Grid item xl={12} spacing={6}>
						<SelectInput
							source="type"
							label="Tipo"
							choices={[
								{ id: 'HOMETOP', name: 'Home' },
								{ id: 'HOMEPROMO', name: 'Home Promo' },
								{ id: 'POPUP', name: 'Popup' },
								{ id: 'FLASH_SALE', name: 'Flash Sale' }
							]}
							onChange={(e) => {
								setType(e.target.value);
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xl={12}>
							<DateInput source="start_date" label="Fecha de inicio" fullWidth />
							<DateInput source="end_date" label="Fecha de fin" fullWidth />
						</Grid>
					<Grid item xl={12} spacing={6}>
						<S3File
							idComponent="banner-movile"
							path="banner"
							textAlert={typeBannerMobil[type || oldType]}
							source="path_movile"
							handleUploadFinish={handleUploadFinishMovile}
							id={props.match.id}
							nameButton="Subir banner para moviles"
							record={controllerProps.record}
						/>
					</Grid>
					<Grid item xl={12} spacing={6}>
						<S3File
							idComponent="banner-desktop"
							path="banner"
							textAlert={typeBanner[type || oldType]}
							source="path_desktop"
							handleUploadFinish={handleUploadFinishDesktop}
							id={props.match.id}
							nameButton="Subir banner para escritorio"
							record={controllerProps.record}
						/>
					</Grid>
				</Grid>
			</SimpleForm>
		</div>
	);
};
export default CompanyMembershipEdit;
