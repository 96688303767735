import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Drawer, Input, message, Modal, Row, Table } from 'antd';
import { getService } from '../../services';
import styled from 'styled-components';
import SelectField from '../../components/com/form/SelectField';

const Wrapper = styled.div`
	margin: 10px;
`;

const { confirm } = Modal;

const ProductCharacteristics = ({ record, ...props }) => {
	const service = getService('products-characteristics');
	const serviceCharacteristics = getService('characteristics');
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState();
	const [visible, setVisible] = useState(false);
	const [payload, setPayload] = useState({});
	const [characteristics, setCharacteristics] = useState();
	const [optionsCharacteristics, setOptionsCharacteristics] = useState();
	const [idEdit, setIdEdit] = useState();

	const getData = useCallback(
		async (id) => {
			setLoading(true);
			try {
				const response = await service.find({ query: { $limit: 2000, product_id: record.id } });
				const responseCharacteristics = await serviceCharacteristics.find({ query: { $limit: 2000 } });
				setCharacteristics(responseCharacteristics.data);
				setOptionsCharacteristics(responseCharacteristics.data.map((it) => ({ id: it.id, name: it.name })));
				setData(response.data);
				setLoading(false);
			} catch (err) {
				message.error(err.message);
			}
			if (id) {
				try {
					const response = await service.get(id);
					setPayload({
						characteristic_value: response?.characteristic_value,
						characteristic_id: response?.characteristic_id,
					});
					setLoading(false);
				} catch (err) {
					message.error(err.message);
				}
			}
		},
		[serviceCharacteristics, service, record]
	);

	useEffect(() => {
		getData().then();
	}, [getData]);

	function selectableCharacteristic(characteristic_id) {
		return characteristics?.filter((it) => it.id === characteristic_id);
	}
	if (loading) return 'Cargando...';

	async function handleSubmit() {
		if (idEdit) {
			if (!!payload.characteristic_value && !!payload.characteristic_id) {
				try {
					await service.patch(idEdit, { ...payload, product_id: record.id });
					setPayload({});
					setVisible(false);
					setIdEdit(undefined);
					await getData();
					message.success('Caracteristica editada exitosamente');
				} catch (e) {
					message.error(e.message);
				}
			}
		}
		if (!!payload.characteristic_value && !!payload.characteristic_id && !idEdit) {
			try {
				await service.create({ ...payload, product_id: record.id });
				setVisible(false);
				await getData();
				message.success('Caracteristica agregada exitosamente');
			} catch (e) {
				message.error(e.message);
			}
		}
	}

	function handleChange(event) {
		const { name, value } = event.target;
		setPayload({ ...payload, [name]: value });
	}
	function handleDelete(id) {
		confirm({
			title: '¿Estas seguro?',
			content: 'Oprime OK para eliminar el registro.',
			async onOk() {
				await service.remove(id);
				await getData();
				message.success('El registro a sido eliminado exitosamente');
			},
			onCancel() {},
		});
	}

	return (
		<Wrapper>
			<Button
				style={{ marginLeft: '90%' }}
				type="link"
				icon="plus"
				onClick={() => {
					setVisible(true);
				}}
			>
				Agregar
			</Button>

			<Table
				scroll={{
					x: 600,
				}}
				size="large"
				columns={[
					{
						title: 'ID',
						dataIndex: 'id',
						width: 100,
						align: 'center',
					},
					{
						title: 'Caracteristica',
						render: (record) => {
							return selectableCharacteristic(record?.characteristic_id)[0].name;
						},
						width: 100,
						align: 'center',
					},
					{
						title: 'Valor de la caracteristica',
						dataIndex: 'characteristic_value',
						width: 100,
						align: 'center',
					},
					{
						title: 'Accciones',
						render: (id, record) => {
							return (
								<>
									<Button
										type="link"
										icon="edit"
										onClick={() => {
											setVisible(true);
											setIdEdit(record.id);
											getData(record.id).catch((e) => message.error(e.message));
										}}
									/>
									<Button
										type="link"
										icon="delete"
										onClick={() => {
											handleDelete(record.id);
										}}
									/>
								</>
							);
						},
						width: 100,
						align: 'center',
					},
				]}
				dataSource={data}
				pagination={false}
			/>
			<Drawer
				anchor="right"
				title="Agregar o editar una caracteristica"
				size="large"
				visible={visible}
				width={450}
				onClose={() => setVisible(false)}
				keyboard={true}
			>
				<Row gutter={[24, 12]}>
					<Col span={24} align="center">
						<p style={{ fontWeight: 600 }}>Caracteristica</p>
						<SelectField
							style={{ width: '100%' }}
							name="characteristics"
							choices={optionsCharacteristics}
							onSelect={(e) => {
								setPayload({ ...payload, characteristic_id: e });
							}}
							value={payload?.characteristic_id}
						/>
					</Col>
					<Col span={24} align="center">
						<p style={{ fontWeight: 600 }}>Valor de la caracteristica</p>
						<Input name="characteristic_value" onChange={handleChange} value={payload?.characteristic_value} />
					</Col>
					<Col span={24} align="center">
						<Button
							type="primary"
							style={{ backgroundColor: '#05314d', marginTop: 30, borderRadius: 20 }}
							onClick={handleSubmit}
							icon="plus"
						>
							{idEdit ? 'Editar' : 'Agregar'} caracteristica
						</Button>
					</Col>
				</Row>
			</Drawer>
		</Wrapper>
	);
};
export default ProductCharacteristics;
