import React, { useState, useEffect } from 'react';
import { TabbedShowLayout, Tab, Show } from 'react-admin';
import { getService } from "../../services/"
import { message, Input, Button, Row, Col, Icon, Tooltip } from 'antd';
import { Grid } from '../../components/com';
import Prices from "./Prices";
import { sortBy } from '../../utils/Helper';
const IntegrationID = ({ record = {}, onRemove, onSave, defaultValue, source, ...props }) => {

    const [edit, setEdit] = useState(false);
    const [value, setValue] = useState(defaultValue);
    return (<Row gutter={8} type="flex" justify="start" align="middle">
        {edit && <Col>
            <Input
                placeholder="Integración ID"
                value={value}
                onChange={e => setValue(e.target.value)}
                defaultValue={record[source] || defaultValue || ""}
            />
        </Col>
        }
        {!edit && <Col>
            <span> {value || record[source] || defaultValue || ""}</span>
        </Col>}
        <Col>
            <Button type={edit ? "primary" : "dashed"} icon={!edit ? "edit" : "save"}
                onClick={() => {
                    if (onSave && edit)
                        onSave({
                            location_city_id: record.id,
                            [source]: value
                        })
                    setEdit(edit => (!edit))
                }} />
        </Col>
        {edit && <Col>
            <Button type="dashed" icon={"close"} onClick={() => setEdit(false)} />
        </Col>}
        {/* !edit && <Col>
            <Button type="dashed" icon={"delete"} onClick={() => {
                if (onRemove) {
                    setValue(null);
                    onRemove({
                        location_city_id: record.id,
                        [source]: value
                    })
                }
            }} />
        </Col> */}
    </Row>)
}
const AddRemove = ({ record, source, onSubmit, ...props }) => {
    const [add, setAdd] = useState(props.checked || false);
    const [loading, setLoading] = useState(false);
    const handleAdd = async () => {
        setAdd(add => (!add));
        setLoading(true)
        if (onSubmit)
            await onSubmit({
                location_city_id: record.id,
                [source]: record[source]
            });
        setLoading(false);
    }
    return (<>
        <Tooltip title={add ? "Quitar" : "Agregar"}>
            <Button loading={loading}
                style={{
                    color: add ? "#eb2f96" : "#52c41a"
                }}
                icon={!add ? "check-circle" : "close-circle"}
                shape="circle" size="large" type={"link"} onClick={handleAdd} />
        </Tooltip>

    </>)
}
const CoverageAreas = ({ record, ...props }) => {
    let { id } = record;
    const service = getService("fulfillment-cities");
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [cities, setCities] = useState([]);
    const [cities_ids, setCitiesIds] = useState([]);
    const columns = [
        {
            source: "name",
            label: "Ciudad"
        },
        {
            source: "state.name",
            label: "Departamento"
        },
    ];
    const getCities = () => {

        setLoading(true);
        service.find({
            query: {
                fulfillment_company_id: record.id,
                $limit: 500000,
            }
        })
            .then(({ data }) => {
                setCities(data);

                //Ordenar aqui
                /* let new_data = sortBy("") */
                setCitiesIds(data.map(it => (it.location_city_id)))
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                message.error(err.message);
            });
    }
    /* 
        fulfillment_company_id
        location_city_id,
        integration_city_id
    */
    const handleSave = async ({
        location_city_id,
        integration_city_id
    }) => {
        let fulfillment_company_id = id;
        let response = await service.find({
            query: {
                fulfillment_company_id,
                location_city_id
            }
        }).then(({ data }) => (data[0]));

        if (response) {
            return service.patch(response.id, {
                fulfillment_company_id,
                location_city_id,
                integration_city_id
            })
                .then(response => {
                    message.success("Registro Actualizado!")
                    setRefresh(refresh => (!refresh))
                })
                .catch(err => message.error(err.message));
        }
        service.create({
            fulfillment_company_id,
            location_city_id,
            integration_city_id
        })
            .then(response => {
                message.success("Registro Creado!")
                setRefresh(refresh => (!refresh))
            })
            .catch(err => message.error(err.message));
    }
    const onAddRemove = async ({
        location_city_id,
        integration_city_id
    }) => {
        let fulfillment_company_id = id;
        let response = await service.find({
            query: {
                fulfillment_company_id,
                location_city_id
            }
        }).then(({ data }) => (data[0]));


        //return console.log(response);
        if (response)
            return service.remove(response.id)
                .then(response => {
                    message.info("Registro Eliminado!")
                    setRefresh(refresh => (!refresh))
                })
                .catch(err => message.error(err.message));
        service.create({
            fulfillment_company_id,
            location_city_id,
            integration_city_id
        })
            .then(response => {
                message.success("Registro Creado!")
                setRefresh(refresh => (!refresh))
            })
            .catch(err => message.error(err.message));
    }
    useEffect(() => {
        if (record.id)
            getCities();
    }, [record.id])
    if (loading) return null;
    return (<>
        <Grid
            loading={refresh}
            pagination={false}
            search={true}
            searchKey="name"
            actions={{
                edit: false
            }}
            filterDefaultValues={{
                $limit: 500000
            }}
            perPage={500000}
            sort={{
                name: 1
            }}
            source="locations-cities"
            columns={[...columns,
            {
                source: "integration_city_id",
                label: "Integración ID",
                render: (value, record) => {
                    let city = cities.find(it => (it.location_city_id == record.id));
                    if (city) {
                        let { integration_city_id } = city;
                        record["integration_city_id"] = integration_city_id;
                    }
                    return (<>
                        <IntegrationID
                            onSave={handleSave}
                            record={record}
                            source="integration_city_id"
                            defaultValue={city && city.integration_city_id} />
                    </>)
                }
            },
            {
                label: "Agregar/Quitar",
                render: (value, record) => {
                    let city = cities.find(it => (it.location_city_id == record.id));
                    record["checked"] = (typeof city != "undefined");
                    return (<AddRemove
                        onSubmit={onAddRemove}
                        source="integration_city_id"
                        checked={(typeof city != "undefined")} record={record} />)
                }
            }
            ]}
        />
    </>)
}

const FullfillmentCompanyShow = props => {
    return (<Show {...props}>
        <TabbedShowLayout>
            <Tab label="Zonas de cobertura">
                <CoverageAreas />
            </Tab>
            <Tab label="Precios">
                <Prices {...props} />
            </Tab>
        </TabbedShowLayout>
    </Show>)
}
export default FullfillmentCompanyShow;