import React from "react";
import TaxRuleList from "./TaxRuleList";
import TaxRuleEdit from "./TaxRuleEdit";

const Title = ({ record }) => {
  return (
    <span>
      {record ? `Editar ${record.name}` : "Listado de productos express"}
    </span>
  );
};

export { TaxRuleList, Title, TaxRuleEdit };
