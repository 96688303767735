// in src/App.js
import React from "react";
import { Admin, Resource } from "react-admin";
import { createHashHistory } from "history";
import { restClient, authClient } from "ra-data-feathers";
import Api from "./utils/Api";
import Api2 from "./utils/Api2";
import UserList from "./pages/users/UserList";
import Layout from "./layouts/Layout";
import { BrandsList, BrandsCreate } from "./pages/brands";
import { CharacteristicsList, CharacteristicsEdit, CharacteristicsCreate } from './pages/characteristics';
import {
  ExpressProductCategoriesCreate,
  ExpressProductCategoriesList,
  ExpressProductCategoriesShow,
  ExpressProductCategoriesEdit,
} from "./pages/categories/express-products";
import { TaxRuleList } from "./pages/tax-rule";
import Configurations from "./pages/configurations/Configurations";
import {
  ExpressProductList,
  SCategoryShow,
  ExpressProductCreate,
} from "./pages/express-products";
import {
  BlogGuidesList,
  BlogGuidesCreate,
  BlogGuidesEdit,
} from "./pages/blog-and-guides";
import {
  OrderExpress,
  ShowOrderExpress,
  OrdersList,
  ShowOrder
} from "./pages/orders";
import Addresses from "./pages/addresses/Addresses";
import PriceList from "./pages/price_list/PriceList";
import { AuthorsList, AuthorEdit, AuthorCreate } from './pages/authors'
import { PolygonsList, PolygonCreate, PolygonEdit } from './pages/polygons'
import CMS from './pages/cms/CMS'
import Companies from "./pages/companies/Companies";
import Cities from "./pages/cities/Cities";
import States from "./pages/states/States";
import EmeStates from "./pages/eme-states/EmeStates";
import EmeStatusForm from "./pages/eme-states/EmeStatusForm";
import { ReviewsList, ReviewsEdit } from './pages/reviews';
import BannersList from './pages/banners/BannerList';
import BannersEdit from './pages/banners/BannerEdit';
import BannersCreate from './pages/banners/BannerCreate';

import EmeProductsWebLogs from "./pages/eme-productos-web-log/EmeProductsWebLogs";
import EmeTercerosWebLogs from "./pages/eme-terceros-web-log/EmeTercerosWebLogs";
import EmePriceListWebLlogs from "./pages/eme-price-list-web-log/EmePriceListWebLlogs";
import EmeOrdersWebLogs from "./pages/eme-orders-web-log/EmeOrdersWebLogs";
import FulfillmentCompany from "./pages/fulfillment-company/FulfillmentCompany";
import FullfillmentCompanyShow from "./pages/fulfillment-company/FullfillmentCompanyShow";
import CustomPayments from "./pages/custom-payments/CustomPayments";
import { DiscountsList, DiscountCreate, DiscountsEdit } from './pages/discounts'
import { UserComercialEdit, UserCompanyList } from './pages/user-comercial'
import { ImportExportProducts } from './pages/import-export-products'
import PurchaseOrdersList from "./pages/purchase-orders/PurchaseOrdersList"
import { PurchaseOrdersEdit } from "./pages/purchase-orders";
import ProductsNotifications from "./pages/productsNotifications/productsNotifications";
import PaymentConfiguration from "./pages/payment-configuration/PaymentConfiguration";
const restClientOptions = {
  usePatch: true,
};
const authClientOptions = {
  storageKey: "feathers-jwt",
  authenticate: { strategy: "local" },
  permissionsKey: "permissions",
  permissionsField: "roles",
  redirectTo: "/login",
};


/* import simpleRestProvider from 'ra-data-simple-rest'; */
/* const dataProviders = [
  { dataProvider: simpleRestProvider(URL_API_EME), resources: ['eme-integration-status'] },
  { dataProvider: simpleRestProvider(URL_BASE), resources: ['products'] },
];
const customProviders = (type, resource, params) => {
  const dataProviderMapping = dataProviders.find(dp => dp.resources.includes(resource));
  return dataProviderMapping.dataProvider(type, resource, params);
};
*/
const api_resources = [
  'users',
  'price-list',
  'product-price-list',
  'cms',
  'authors',
  'shipping-costs',
  'addresses',
  'orders-status',
  'brands',
  'categories',
  'tax-rule',
  'configurations',
  'locations-states',
  'locations-cities',
  'companies',
  'products',
  'blogs-and-guides',
  'orders',
  'products-orders',
  'payment-confirmations',
  'credit-cards',
  'custom-payments',
  'shipping',
  'fulfillment-company',
  'fulfillment-cities',
  'integration-status',
  'integration-productos-web-log',
  'integration-terceros-web-log',
  'integration-price-list-web-log',
  'integration-orders-web-log',
  'banners',
  'reviews',
  'characteristics',
  'discounts',
  'discount-conditions',
  'export-import-discount-products',
  "purchase-orders",
  'products-notifications',
  'paymethod-config',
  //PROXIMAMENTE
];
const api2_resources = [
  /* 'eme-products-web-log'*/

]

const dataProviders = new Map([
  ...api_resources.map(it => ([it, restClient(Api, restClientOptions)])),
  ...api2_resources.map(it => ([it, restClient(Api2, restClientOptions)]))
]);


const Adapter = async (type, resource, params) => {
  // Get the DataProvider from the map.
  const dataProvider = await dataProviders.get(resource);
  // The DataProvider object is wrapped in a function so we can pass in the parameters to get the result.
  if (dataProvider instanceof Function) {
    return dataProvider(type, resource, params);
  }
};


const history = createHashHistory();
//const App = () => <Admin dataProvider={dataProvider} />;

const Default = () => (<>
  Feature Component 😃
</>)
const App = () => (
  <Admin
    /* dataProvider={restClient(Api, restClientOptions)}
    authProvider={authClient(Api, authClientOptions)} */

    dataProvider={Adapter}
    authProvider={authClient(Api, authClientOptions)}
    history={history}
    appLayout={Layout}
    /* i18nProvider={i18nProvider} */
    locale="en"
  >

    <Resource name="shipping" />
    <Resource name="users" list={UserList} /*  edit={UserEdit} */ />
    <Resource name="price-list" list={PriceList} />
    <Resource name="product-price-list" />

    <Resource name="cms" list={CMS} /*  edit={UserEdit} */ />
    <Resource name="paymethod-config" list={PaymentConfiguration} /*  edit={UserEdit} */ />
    <Resource name="products-notifications" list={ProductsNotifications} />
    <Resource name="authors" list={AuthorsList} create={AuthorCreate} edit={AuthorEdit} />
    <Resource name="shipping-costs" list={PolygonsList} create={PolygonCreate} edit={PolygonEdit} />



    <Resource name="addresses" list={Addresses} /* edit={UserAddressEdit} */ />
    <Resource name="orders-status" />
    <Resource name="credit-cards" />
    <Resource name="payment-confirmations" />
    <Resource name="brands" create={BrandsCreate} list={BrandsList} />
    <Resource name="export-import-discount-products" list={ImportExportProducts} />
    <Resource
      name="categories"
      create={ExpressProductCategoriesCreate}
      list={ExpressProductCategoriesList}
      show={ExpressProductCategoriesShow}
      edit={ExpressProductCategoriesEdit}
    />
    <Resource
      name="tax-rule"
      // create={ExpressProductCategoriesCreate}
      list={TaxRuleList}
    />
    <Resource
      name="configurations"
      // create={ExpressProductCategoriesCreate}
      list={Configurations}
    />
    <Resource
      name="locations-states"
      list={States}
    />
    <Resource
      name="locations-cities"
      list={Cities}
    />
    <Resource
      name="companies"
      // create={ExpressProductCategoriesCreate}
      list={Companies}
    />
    <Resource
      name="custom-payments"
      list={CustomPayments}
    />
    <Resource
      name="products"
      create={ExpressProductCreate}
      list={ExpressProductList}
      edit={SCategoryShow}
    />
    <Resource
      name="blogs-and-guides"
      create={BlogGuidesCreate}
      list={BlogGuidesList}
      edit={BlogGuidesEdit}
    />
    <Resource name="reviews" list={ReviewsList} edit={ReviewsEdit} />
    <Resource
      name="characteristics"
      list={CharacteristicsList}
      edit={CharacteristicsEdit}
      create={CharacteristicsCreate}
    />
    <Resource
      name="orders"
      show={ShowOrder}
      list={OrdersList}
    />
    <Resource name="discounts" list={DiscountsList} create={DiscountCreate} edit={DiscountsEdit} />
    <Resource
      name="products-orders"
      show={ShowOrderExpress}
      list={OrderExpress}
    />
    <Resource
      name="integration-status"
      edit={EmeStatusForm}
      create={EmeStatusForm}
      list={EmeStates}
    />
    <Resource
      name="integration-terceros-web-log"
      //show={ShowOrderExpress}
      list={EmeTercerosWebLogs}
    />
    <Resource
      name="integration-productos-web-log"
      //show={ShowOrderExpress}
      list={EmeProductsWebLogs}
    />
    <Resource
      name="integration-price-list-web-log"
      //show={ShowOrderExpress}
      list={EmePriceListWebLlogs}
    />
    <Resource
      name="integration-orders-web-log"
      //show={ShowOrderExpress}
      list={EmeOrdersWebLogs}
    />
    <Resource
      name="fulfillment-company"
      show={FullfillmentCompanyShow}
      list={FulfillmentCompany}
    />

  <Resource
      name="purchase-orders"
      list={PurchaseOrdersList}
      edit={PurchaseOrdersEdit}
    />

    <Resource name="banners" edit={BannersEdit} create={BannersCreate} list={BannersList} />
    <Resource
      name="user-comercial"
      list={(props) => <UserCompanyList {...props} resource="users" />}
      show={(props) => <UserComercialEdit {...props} resource="users" />}
    />


  </Admin>
);
export default App;
