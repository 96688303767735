import { Tag } from "antd";
import React from "react";
import CRUD from "../../components/com/crud/CRUD";
import {
  Filter,
  NumberInput,
  SelectInput
} from "react-admin";

const columns = [
  {
    xtype: "textfield",
    source: ['product', 'name'],
    fullWidth: true,
    label: "Producto",
  },
  {
    xtype: "textfield",
    source: ['product', 'integration_id'],
    label: 'SKU',
  },
  {
    xtype: 'textfield',
    source: 'full_name',
    label: 'Nombre',
  },
  {
    xtype: 'textfield',
    source: 'email',
    label: 'Email',
  },
  {
    xtype: 'textfield',
    source: 'phone',
    label: 'Teléfono',
  },
  {
    xtype: 'textfield',
    source: 'quantity',
    label: 'Cantidad solicitada',
  },
  {
    xtype: "functionfield",
    source: "status",
    label: "Estado",
    render: record => {
      const names = {
        pending: 'Pendiente',
        notified: 'Notificado'
      }

      return <Tag color={record.status === 'pending' ? 'gold' : 'green'}>{names[record.status]}</Tag>
    }
  },
];
const fields = [
  {
    xtype: "selectfield",
    source: "status",
    fullWidth: true,
    label: "Estado",
    choices: [
      { id: 'pending', name: 'Pendiente' },
      { id: 'notified', name: 'Notificado' },
    ]
  },
];

const Filters = (props) => (
  <Filter {...props}>
      <NumberInput label="Teléfono" source="phone" alwaysOn />
      <NumberInput label="Email" source="email" alwaysOn />
      <SelectInput
        label="Estado"
        source="status"
        choices={[
          { id: 'pending', name: 'Pendiente' },
          { id: 'notified', name: 'Notificado' },
        ]}
        alwaysOn
      />
  </Filter>
);

const ProductsNotifications = (props) => {
  return (
    <CRUD
      title="Solicitudes de stock"
      actions={{ delete: false, create: false }}
      columns={columns}
      fields={fields}
      filters={<Filters />}
    />
  );
};
export default ProductsNotifications;
