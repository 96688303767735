import React, { useState, useEffect } from "react";
import { getService } from "../../services";
import {
  Table,
  Button,
  Row,
  Col,
  Icon,
  InputNumber,
  message,
  Modal,
  Input,
} from "antd";
import { money } from "../../utils/Helper";
import { Avatar, HeadLine } from "../../components/com";
import styled from "styled-components";
import { SelectField } from "../../components/com/form";
import { WrapperCost } from "./Styles";
const service = getService("shipping-details");
const { confirm } = Modal;
const transports = [
  {
    id: "aereo",
    name: "Aéreo",
  },
  {
    id: "terrestre",
    name: "Terrestre",
  },
];
const MyTable = styled(Table)`
  & .ant-table {
    box-shadow: 3px 3px 3px #ccc;
  }
`;
const Section = styled(Row)`
  margin: 10px 0px;
`;
const SliderInput = ({ max, ...props }) => {
  return (
    <InputNumber
      size="large"
      style={{
        width: "100%",
      }}
      min={0}
      placeholder="Cantidad"
      max={max}
      onChange={props.onChange}
      {...props}
      /* value={typeof inputValue === 'number' ? inputValue : 0} */
    />
  );
};
const QuantityRecord = ({ value, record, shipping, id }) => {
  let shipping_detail = 0;
  const [quantity, setQuantity] = useState(0);
  const [shippingdetail, setShippingDetail] = useState();
  useEffect(() => {
    getRecord();
  }, [record]);
  const getRecord = async () => {
    /*  return console.log(value, record, shipping, id) */
    try {
      const [response] = await Promise.all([
        service.find({
          query: {
            shipping_id: Number(shipping.id),
            order_detail_id: Number(id),
          },
        }),
      ]);
      let { data } = response;

      console.log("Shipping Detail: ", data);
      if (data.length > 0) {
        shipping_detail = data[0];
        setShippingDetail(shipping_detail);
        shipping_detail = shipping_detail.quantity;
      }
      setQuantity(shipping_detail);
      return shipping_detail;
    } catch (error) {
      console.log("ERROR>", error);
      setQuantity(shipping_detail);
      return shipping_detail;
    }
  };
  const handleDelete = () => {
    confirm({
      title: "Eliminar Cantidad?",
      content: "Desea eliminar la cantidad",
      okText: "Si",
      okType: "danger",
      cancelText: "No",
      onOk() {
        service
          .remove(shippingdetail.id)
          .then((response) => {
            setQuantity(0);
            message.info(response.message || "Cantidad Eliminada");
          })
          .catch((err) => message.error(err.message));
      },
    });
  };
  return (
    <>
      {quantity > 0 && (
        <>
          <Button onClick={handleDelete} type="danger">
            <span>{`${quantity > 0 ? `(${quantity})` : ""}`} </span>
            <Icon type="delete" />
          </Button>
        </>
      )}
    </>
  );
};
const Shipping = ({
  id,
  visible,
  shipping,
  onCancel,
  fulfillment_company_id,
  ...props
}) => {
  const [record, setRecord] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading_shipping, setLoadingShipping] = useState(false);
  const [method, setMethod] = useState(null);
  const [error, setError] = useState(null);
  const [company_id, setCompanyId] = useState(fulfillment_company_id);
  const [payloads, setPayloads] = useState({});
  const [guia, setGuia] = useState();
  const [fulfillment_company_service_code, setCode] = useState("terrestre");
  const [shipping_cost, setShippingCost] = useState([]);
  //"12 Aereo 0 13 terrestre",
  const handleGenerate = () => {
    const service = getService("shipping");
    setLoading(true);
    service
      .patch(shipping.id, {
        shipping_status_id: 2,
        fulfillment_company_service_code: company_id == 2 ? method : undefined,
        fulfillment_company_id: company_id,
        delivery_number: guia ? guia : undefined,
        ...payloads,
      })
      .then((reponse) => {
        setLoading(false);
        setError(null);
        if (onCancel) onCancel();
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
        message.error(err.message);
      });
  };
  const handleAdd = async (value, new_record) => {
    let max = Number(record["quantity"]) - Number(record.sent);
    if (Number(value) > max) {
      return message.error(
        "El valor ingresado debe ser menor a la cantidad disponible"
      );
    }
    let payloads = {
      shipping_id: Number(shipping.id),
      order_id: Number(id),
      order_detail_id: Number(new_record.id),
      quantity: Number(value),
    };
    /* if (record.id != new_record.id) {
      payloads["quantity"] = value;
      setRecord(new_record);
    } */
    const [response] = await Promise.all([
      service.find({
        query: {
          shipping_id: Number(shipping.id),
          //order_id: Number(id),
          order_detail_id: Number(new_record.id),
        },
      }),
    ]);
    let { data } = response;
    if (data.length > 0) {
      let shipping_detail = data[0];
      return service
        .patch(shipping_detail.id, payloads)
        .then((response) => {
          setError(null);
          getData();
          message.info("Cantidad actualizada");
        })
        .catch((err) => {
          setError(err.message);
          message.error(err.message);
        });
    }
    service
      .create(payloads)
      .then((response) => {
        getData();
        setError(null);
        message.info("Cantidad agregada");
      })
      .catch((err) => {
        setError(err.message);
        message.error(err.message);
      });
  };
  const onChange = (value, record) => {
    record["current_quantity"] = value;
    let max = Number(record["quantity"]) - Number(record.sent);
    if (value <= max) {
      record["quantity_sent"] = value;
      setRecord(record);
    } else {
      return message.error(
        "El valor ingresado debe ser menor a la cantidad disponible"
      );
    }
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: 40,
      key: "id",
    },
    {
      title: "Foto",
      dataIndex: "product_main_image",
      width: 80,
      render: (value, record) => (
        <Avatar record={record} source="product_main_image" size="small" />
      ),
      key: "picture",
    },
    {
      title: "Nombre",
      dataIndex: "product_name",
      key: "name",
    },
    {
      title: "Cantidad Pendiente de Envío",
      dataIndex: "quantity",
      key: "quantity-pending",
      render: (value, record) => `${Number(value) - Number(record.sent)}`,
    },

    {
      title: "Cantidad a Enviar",
      key: "sent-quantity",
      dataIndex: "current_quantity",
      key: "current_quantity",
      render: (value, record) =>
        !loading && (
          <SliderInput
            /* defaultValue={record["current_quiantity"]} */
            onChange={(value) => {
              record["current_quiantity"] = value;
              onChange(value, record);
            }}
            max={record.quantity}
            {...record}
          />
        ),
    },
    {
      key: "actions",

      render: (value, record) => (
        <Button
          onClick={() => {
            handleAdd(record.current_quantity, record);
          }}
        >
          <Icon type="save" /> Agregar
        </Button>
      ),
    },
    {
      title: "Cantidad Agregada",
      dataIndex: "id",
      render: (value, record) => (
        <QuantityRecord
          id={record.id}
          shipping={shipping}
          value={value}
          record={record}
        />
      ),
      key: "id",
    },
  ];
  const [data, setData] = useState([]);
  const Shipping = async () => {
    const service = getService("shipping");
    const [shipping] = await Promise.all([
      service.find({
        query: {
          shipping_status_id: 1,
          sub_order_id: id,
        },
      }),
    ]);
    console.log("Shipping: ", shipping);
  };
  const handleShipping = async () => {
    try {
      const shippingDetails = getService("shipping-details");
      setLoadingShipping(true);
      let { total, error } = await shippingDetails.find({
        query: {
          shipping_id: shipping.id,
        },
      });
      if (total == 0) throw new Error("Debe tener productos agregados.");
      const searchShippingCost = getService("search-shipping-cost");
      let resp = await searchShippingCost.find({
        query: {
          shipping_id: shipping.id,
        },
      });
      setLoadingShipping(false);
      setShippingCost(resp);
      /* alert(JSON.stringify(resp)); */
    } catch (err) {
      setLoadingShipping(false);
      message.error(err.message);
    }
    /* 
    setLoading(true); */
    //search-shipping-cost?shipping_id=28
  };
  const getData = () => {
    const service = getService("orders-details");
    setLoading(true);

    service
      .find({
        query: {
          order_id: id,
        },
      })
      .then(({ data }) => {
        data = data.filter((it) => it.sent < it.quantity);
        setLoading(false);
        setData(data);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.message);
      });
  };
  const hadleMethod = (method) => {
    setMethod(method);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <HeadLine size="medium">Entrega:</HeadLine>
      <Section type="flex" justify="start" gutter={4}>
        <Col>
          <SelectField
            onChange={(field, value) => {
              setPayloads({});
              setCompanyId(value);
            }}
            style={{
              width: 250,
              margin: "8px 0px",
            }}
            defaultValue={company_id}
            reference="fulfillment-company"
            placeholder="Seleccione Compañia"
          />
        </Col>
      </Section>
      {
        <MyTable
          scroll={{ y: 450 /* x: 1300 */ }}
          size="small"
          loading={loading}
          dataSource={data}
          columns={columns}
          pagination={false}
        />
      }
      <Section type="flex" justify="center" gutter={4}>
        <Col align={"center"} span={24}>
          {error && (
            <span
              style={{
                color: "red",
              }}
            >
              {error}
            </span>
          )}
        </Col>
        {company_id == 2 && (
          <Col align={"center"} span={24}>
            {shipping_cost.length > 0 && (
              <span
                style={{
                  color: "rgba(0,0,0,.70)",
                }}
              >
                Seleccione Método de Envío
              </span>
            )}
            <div>
              {shipping_cost
                /* .filter(item => item.status == "success") */
                .map((item) =>
                  item.status == "success" ? (
                    <WrapperCost
                      active={method == item.send_description.toLowerCase()}
                      onClick={() =>
                        hadleMethod(item.send_description.toLowerCase())
                      }
                    >
                      <h2>Costo de Envío {item.send_description}</h2>
                      <h3>{money(item.price)}</h3>
                    </WrapperCost>
                  ) : (
                    <WrapperCost>
                      <h3>Envío {item.send_description}</h3>
                      <p
                        style={{
                          color: "red",
                        }}
                      >
                        {item.respuesta}
                      </p>
                    </WrapperCost>
                  )
                )}
            </div>
          </Col>
        )}
        <Col span={24}>
          <div
            style={{
              margin: 10,
              background: "#f8f8f8",
              padding: 4,
            }}
          >
            <span>Numero de guía</span>
            <Input
              name="delivery_number"
              placeholder="Escriba su numero de guia"
              label="Escriba su numero de guia"
              autosize={{
                minRows: 3,
                maxRows: 5,
              }}
              style={{
                margin: "4px 0px",
              }}
              onChange={(e) => setGuia(e.target.value)}
            />
          </div>
        </Col>
        <Col span={6}>
          <Row type="flex" align="middle" justify="center">
            <Col>
              {company_id == 1 && (
                <div
                  style={{
                    margin: 10,
                    background: "#f8f8f8",
                    padding: 4,
                  }}
                >
                  <span>Escriba nota privada</span>
                  <Input.TextArea
                    name="private_notes"
                    placeholder="Escriba nota privada"
                    label="Escriba nota privada"
                    autosize={{
                      minRows: 3,
                      maxRows: 5,
                    }}
                    style={{
                      margin: "4px 0px",
                    }}
                    onChange={(e) =>
                      setPayloads({
                        private_notes: e.target.value,
                      })
                    }
                  />
                </div>
              )}
              {shipping_cost.length == 0 && company_id == 2 && (
                <Button
                  block
                  style={{
                    width: 250,
                    margin: 8,
                  }}
                  loading={loading_shipping}
                  size="large"
                  type="primary"
                  onClick={handleShipping}
                >
                  Cotizar Envíos
                </Button>
              )}
              {
                <Button
                  block
                  style={{
                    width: 250,
                    margin: 8,
                  }}
                  disabled={!method && company_id == 2}
                  loading={loading}
                  size="large"
                  type="primary"
                  onClick={handleGenerate}
                >
                  {company_id == 2
                    ? `Generar Guía ${method ? "(" + method + ")" : ""}`
                    : "Generar Envío"}
                </Button>
              }
            </Col>
          </Row>
        </Col>
      </Section>
    </>
  );
};
export default Shipping;
