import React, { Component } from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    NumberInput
} from "react-admin";
import Grid from "@material-ui/core/Grid";
export default class PolygonEdit extends Component {
    render() {
        return (
            <Create title={"Crear Autor"} {...this.props}>
                <SimpleForm>
                    <Grid container fullWidth spacing={16}>
                        <Grid item xl={6} spacing={6}>
                            <TextInput fullWidth source="name" label="Nombre" />
                            <TextInput fullWidth multiline source="polygon" label="Polígono" />
                        </Grid>
                        <Grid item xl={6} spacing={6}>
                            <NumberInput fullWidth source="price" label="Precio" />
                            <ReferenceInput fullWidth label="Ciudad" source="city_id" reference="locations-cities">
                                <SelectInput optionText="name" />
                            </ReferenceInput>
                        </Grid>
                    </Grid>
                </SimpleForm>
            </Create>
        );
    }
}
