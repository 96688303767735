import React, {Fragment} from 'react';
import {
    TextField,
    ShowButton,
    EditButton,
    Filter,
    TextInput,
    ReferenceField,
    DateField,
    ReferenceInput,
    SelectInput,
    BooleanField,
    BooleanInput,
} from 'react-admin';
import {MoneyField, CustomList, CustomField, StatusField} from '../../components/com';
import {money} from '../../utils/Helper';
import m from 'moment';

const types = [
    {id: 'general', name: 'Genral'},
    {id: 'express', name: 'Express'},
];
const CustomBooleanInput = ({record, source, ...props}) => {
    record[source] = record[source] === 'true';
    return <BooleanInput {...props} source={source} record={record} sort={false} label="Recurrente"/>;
};
const CustomBooleanField = ({record, source, ...props}) => {
    record[source] = record[source] === 'true';

    return <BooleanField {...props} source={source} record={record} sort={false} label="Recurrente"/>;
};
const Filters = (props) => (
    <Filter {...props}>
        {

            <ReferenceInput
                label="Usuario"
                source="user_id"
                reference="users"
                alwaysOn
            >
                <SelectInput optionText={`first_name`}/>
            </ReferenceInput>


        }
        {
            <ReferenceInput
                label="Orden"
                source="order_id"
                reference="orders"
                alwaysOn
            >
                <SelectInput optionText="id" />
            </ReferenceInput>
        }
        {
            <ReferenceInput
                label="Pruducto"
                source="product_id"
                reference="products"
                alwaysOn
            >
                <SelectInput optionText="name"/>
            </ReferenceInput>
        }


    </Filter>
);
const OrdersList = (props) => {
    return (
        <Fragment>
            <h2>Todas las calificaciones</h2>
            <CustomList
                {...props}
                sort={{
                    field: 'createdAt',
                    order: 'DESC',
                }}
                filters={<Filters/>}
                source={props.source || 'reviews'}
                title="Calificaciones"
            >
                <TextField label="ID" source="id"/>
                <ReferenceField label="Usuario" source="user_id" reference="users">
                    <CustomField
                        source="first_name"
                        render={(record, source) => <span>{`${record[source]} ${record['last_name']}`}</span>}
                    />
                </ReferenceField>
                <ReferenceField label="Orden" source="order_id" reference="orders">
                    <CustomField
                        source="id"
                        render={(record, source) => <span>{`${record[source]}`}</span>}
                    />
                </ReferenceField>
                <ReferenceField label="Producto" source="product_id" reference="products">
                    <CustomField
                        source="name"
                        render={(record, source) => <span>{`${record[source]}`}</span>}
                    />
                </ReferenceField>
                <TextField source='score' label='Puntuación'/>
                <EditButton label={'Ver'}/>
            </CustomList>
        </Fragment>
    );
};

export default OrdersList;
