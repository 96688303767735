import React from "react";
import CRUD from '../../components/com/crud/CRUD';
const columns = [
    {
        source: "id",
        placeholder: "ID",
        label: "ID",
    },
    {
        source: "name",
        placeholder: "Nombre",
        label: "Nombre",
    },
    {
        source: "key",
        placeholder: "Clave",
        label: "Clave",
    },
    {
        source: "value",
        placeholder: "Valor",
        label: "Valor",
    },
];
const fields = [
    {
        source: "name",
        fullWidth: true,
        placeholder: "Nombre",
        label: "Nombre",
    },
    {
        source: "key",
        fullWidth: true,
        placeholder: "Clave",
        label: "Clave",
    },
    {
        source: "value",
        fullWidth: true,
        placeholder: "Valor",
        label: "Valor",
    }
];
const Cities = props => {
    return <CRUD
        title="Configuración"
        actions={{ "delete": false }}
        columns={columns}
        fields={fields}
    />
}
export default Cities;