import React, { Component } from "react";
import { Create, SimpleForm, TextInput, SelectInput, NumberInput } from "react-admin";
import { Grid } from "@material-ui/core";

export default class ConfigurationCreate extends Component {
  render() {
    return (
      <Create {...this.props} title="Crear Membresia">
        <SimpleForm>
          <Grid fullWidth spacing={16}>
            <Grid item xl={12} spacing={6}>
              <TextInput source="name" label="Nombre" fullWidth />
            </Grid>
            <Grid item xl={12} spacing={6}>
              <SelectInput
                fullWidth
                source="status"
                choices={[
                  { id: "active", name: "Activo" },
                  { id: "inactive", name: "Inactivo" },
                ]}
              />
            </Grid>
            <Grid item xl={12} spacing={6}>
              <TextInput
                source="description"
                label="Descripción"
                fullWidth
                multiline
              />
            </Grid>
            <Grid item xl={12} spacing={6}>
              <NumberInput source="priority" label="Prioridad" fullWidth />
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
    );
  }
}
