import React, {Component} from 'react';
import {Create, SimpleForm, TextInput} from 'react-admin';
import {Grid} from '@material-ui/core';


export default class ConfigurationCreate extends Component {
    render() {
        return (
            <Create {...this.props} title="Crear una caracteristica">
                <SimpleForm redirect="list" resource="faq">
                    <Grid fullWidth spacing={16}>
                        <Grid item xl={12} spacing={6}>
                            <TextInput source="name" label="Ingrese el nombre de la caracteristicas" fullWidth/>
                        </Grid>
                    </Grid>
                </SimpleForm>
            </Create>
        );
    }
}
