import React from 'react'
import { useHistory } from 'react-router-dom';
import { SimpleForm, Edit, TextInput, SelectInput, NumberInput, DateInput } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { makeStyles, Box, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	title: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: '1em',
	},
}));

const PurchaseOrdersEdit = ({ onCancel, ...props }) => {
	const classes = useStyles();
	const history = useHistory();
  return (
    <Edit title={<div className={classes.title}>
			<Typography variant="h6">Editar estado de la orden</Typography>
		</div>} {...props}>
			<SimpleForm>
				<Box style={{ width: '100%' }} display="flex" justifyContent="flex-end">
					<Button color="primary" variant="contained" onClick={() => history.push('/purchase-orders')}>Regresar</Button>
				</Box>
				<TextInput fullWidth source="id" label={false} type="hidden" />
				<Grid container fullWidth spacing={2} display="flex">
					<Grid item sm={3}>
						<SelectInput
							fullWidth
							source="status"
							label="Estado"
							choices={[
                                { id: "Pendiente", name: "Pendiente" },
                                { id: "Procesando", name: "Procesando" },
                                { id: "Procesado", name: "Procesado" },
                                { id: "Rechazada", name: "Rechazada" },
							]}
						/>
					</Grid>
				</Grid>
			</SimpleForm>
		</Edit>
  )
}

export default PurchaseOrdersEdit