import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { SimpleForm, TextInput, useEditController, SelectInput, NumberInput, ReferenceInput } from 'react-admin';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: 40,
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: '1em',
	},
	form: {
		/*  [theme.breakpoints.up("xs")]: {
  width: 400
},
[theme.breakpoints.down("xs")]: {
  width: "100vw",
  marginTop: -30
} */
	},
	inlineField: {
		display: 'inline-block',
		width: '50%',
	},
}));
const statusList = [
	{ id: 'active', name: 'Activo' },
	{ id: 'inactive', name: 'Inactivo' },
];

const CompanyMembershipEdit = ({ onCancel, ...props }) => {
	const classes = useStyles();
	const controllerProps = useEditController(props);
	const history = useHistory();
	const handleClose = useCallback(() => {
		history.push('/reviews');
	}, [history]);

	const handleOnCancel = () => {
		if (onCancel) return onCancel();
		handleClose();
	};
	if (!controllerProps.record) {
		return null;
	}
	delete controllerProps.record.user;
  console.log(controllerProps.record);

	return (
		<div className={classes.root}>
			<div className={classes.title}>
				<Typography variant="h6">Editar calificacion</Typography>
				<IconButton onClick={handleOnCancel}>
					<CloseIcon />
				</IconButton>
			</div>
			<SimpleForm
				className={classes.form}
				basePath={controllerProps.basePath}
				record={controllerProps.record}
				save={controllerProps.save}
				version={controllerProps.version}
				redirect="list"
				resource="reviews"
			>
				<TextInput fullWidth source="id" label={false} type="hidden" />

				<Grid fullWidth spacing={16}>
					<Grid item xl={12} spacing={6}>
						<ReferenceInput label="Usuario" source="user_id" reference="users" alwaysOn>
							<SelectInput optionText={`first_name`} optionValue="id" fullWidth />
						</ReferenceInput>
					</Grid>
					<Grid item xl={12} spacing={6}>
						<ReferenceInput label="Orden" source="order_id" reference="orders" alwaysOn>
							<SelectInput optionText="id" optionValue="id" fullWidth />
						</ReferenceInput>
					</Grid>

					<Grid item xl={12} spacing={6}>
						<ReferenceInput label="Pruducto" source="product_id" reference="products" alwaysOn>
							<SelectInput optionText="name" optionValue="id" fullWidth />
						</ReferenceInput>
					</Grid>
					<Grid item xl={12} spacing={6}>
						<NumberInput source="score" label="Puntuación" fullWidth />
					</Grid>
				</Grid>
			</SimpleForm>
		</div>
	);
};
export default CompanyMembershipEdit;
