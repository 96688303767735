import React from "react";
import CRUD from '../../components/com/crud/CRUD';
import {
    Filter,
    NumberInput,
} from "react-admin";
const Filters = (props) => (
    <Filter {...props}>
        <NumberInput label="Compañia ID" source="company_id" alwaysOn />
    </Filter>
);

const columns = [
    {
        source: "company_id",
        label: "Compañia ID",
        placeholder: "Compañia ID"
    },
    {
        source: "company.name",
        label: "Compañia",
        placeholder: "Compañia"
    },
    {
        source: "address",
        label: "Direccción",
        placeholder: "Direccción"
    },
    {
        xtype: "textfield",
        source: "state_id",
        label: "Estado",
        reference: "locations-states",
        placeholder: "Estado"
    },
    {
        xtype: "textfield",
        source: "city_id",
        label: "Ciudad",
        reference: "locations-cities",
        placeholder: "Ciudad"
    },
];
const fields = [
    {
        source: "name",
        label: "Nombre",
        placeholder: "Nombre"
    },
    {
        source: "address",
        label: "Direccción",
        placeholder: "Direccción"
    },
    {
        source: "company_id",
        label: "Compañia ID",
        placeholder: "Compañia ID"
    },
    {
        xtype: "textfield",
        source: "state_id",
        label: "Estado",
        perPage: 5000000,
        sort: { field: "name", order: "ASC" },
        reference: "locations-states",
        placeholder: "Estado"
    },
    {
        xtype: "textfield",
        source: "city_id",
        label: "Ciudad",
        perPage: 5000000,
        sort: { field: "name", order: "ASC" },
        reference: "locations-cities",
        placeholder: "Ciudad"
    },
    {
        source: "lat",
        label: "Latitud",
        placeholder: "Latitud"
    },
    {
        source: "lng",
        label: "Longitud",
        placeholder: "Longitud"
    },
    {
        source: "integration_nit",
        label: "Integration NIT",
        placeholder: "Integration NIT"
    },
    {
        xtype: "numberfield",
        source: "integration_codigo_direccion",
        label: "Integration Código Dirección",
        placeholder: "Integration Código Dirección"
    },
    {
        source: "details",
        label: "Detalles",
        multiline: true,
        fullWidth: true,
        placeholder: "Detalles"
    },
];
const Cities = props => {
    return <CRUD
        title="Direcciones"
        /* actions={{ "delete": false }} */
        filters={<Filters />}
        columns={columns}
        fields={fields}
    />
}
export default Cities;