import React from "react";
import CRUD from '../../components/com/crud/CRUD';
import { CountField } from "../../components/com/";
const columns = [
    {
        xtype: "textfield",
        source: "name",
        fullWidth: true,
        filter: true,
        label: "Nombre"
    },
    {
        xtype: "selectfield",
        perPage: 50000,
        sort: { field: "name", order: "ASC" },
        source: 'state_id',
        reference: "locations-states",
        filter: true,
        label: "Estado"
    },
    {
        xtype: "textfield",
        source: "integration_id",
        label: "DMS"
    },

];
const fields = [
    {
        xtype: "textfield",
        source: "name",
        fullWidth: true,
        label: "Nombre"
    },
    {
        xtype: "textfield",
        source: "integration_id",
        fullWidth: true,
        showBy: "edit",
        label: "DMS"
    },
    {
        xtype: "selectfield",
        perPage: 50000,
        sort: { field: "name", order: "ASC" },
        source: 'state_id',
        fullWidth: true,
        reference: "locations-states",
        label: "Estado"
    },

];
const Cities = props => {

    return <CRUD
        title="Cuidades"
        actions={{ "delete": false }}
        tools={<div>
            <CountField
                label="Sin DMS"
                reference="locations-cities" query={{
                    integration_id: "false"
                }} />
        </div>}
        columns={columns}
        fields={fields}
    />
}
export default Cities;