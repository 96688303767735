import feathers from "@feathersjs/feathers";
import restFeathers from "@feathersjs/rest-client";
import feathersAuthClient from "@feathersjs/authentication-client";

import { URL_BASE, URL_AUTHENTICATION } from "../constants";
const api = feathers();
const restClient = restFeathers(URL_BASE);
const authClient = feathersAuthClient({
  header: "Authorization",
  path: "/authentication",
  jwtStrategy: "jwt",
  entity: "user",
  service: "users",
  storage: window.localStorage,
});
api.configure(restClient.fetch(window.fetch.bind(window)));
api.configure(authClient);

export const brandsService = api.service("brands");
export const expressCategoriesService = api.service("categories");
export const expressHubsService = api.service("hubs");
export const expressProductMediaService = api.service("products-media");
export const blogsAndGuidesService = api.service("blogs-and-guides");
export const expressProductHubsService = api.service("products-hubs");
export const bannerService = api.service('banners');
export const userCommercialCompaniesService = api.service('user-commercial-companies')
export const CompaniesService = api.service('companies')
export const get_service = (service) => api.service(service);
export const getService = (service) => api.service(service);

export default api;
