import React from "react";
/* import ExpressProductList from "./AuthorsList";
import { SCategoryShow } from "./ExpressProdctEdit";
import { ExpressProductCreate } from "./ExpressProductCreate"; */
import AuthorsList from "./AuthorsList"
import ShowOrder from "./ShowOrder"
import OrderExpress from "./OrderExpress"
import ShowOrderExpress from "./ShowOrderExpress"
import AuthorEdit from "./AuthorEdit"
import AuthorCreate from "./AuthorCreate"

const Title = ({ record }) => {
  return <span>{record ? `Editar ${record.name}` : "Listado Marcas"}</span>;
};

const typeList = [
  { id: "scheduled", name: "Programado" },
  { id: "not_scheduled", name: "Express" }
];

const statusList = [
  { id: "active", name: "Activo" },
  { id: "inactive", name: "Inactivo" }
];

export {
  Title,
  typeList,
  statusList,
  ShowOrder,
  AuthorsList,
  AuthorEdit,
  AuthorCreate,
  OrderExpress,
  ShowOrderExpress
};
