import React, { useState, Fragment } from "react"
import keyBy from 'lodash/keyBy'
import { useQuery, Datagrid, TextField, Pagination, Loading, ShowButton, useEditController, List } from 'react-admin'
import { Route, useHistory, withRouter } from "react-router-dom";
import { useEffect } from "react";

const CustomList = (props) => {
    const { source, resource } = props;
    const [page, setPage] = useState(1);
    const perPage = props.perPage || 10;
    const [initialized, setInitialized] = useState(false);

    const { data, total, loading, error } = useQuery({
        type: 'GET_LIST',
        resource: source || resource,
        payload: {
            pagination: { page, perPage },
            sort: props.sort || { field: 'id', order: 'ASC' },
            filter: props.filterDefaultValues || {},
        }
    });

    useEffect(() => {
        if (!loading) {
            setInitialized(true);
        }
    }, [props.location, loading])
    if (!initialized) {
        return <Loading />
    }
    if (error) {
        return <p>ERROR: {error}</p>
    }
    return (
        <List
            exporter={props.exporter || false}
            bulkActionButtons={props.bulkActionButtons || false}
            {...props} />
    )
}
export default withRouter(CustomList);