import React from "react";
import CRUD from '../../components/com/crud/CRUD';
const types = [
    {
        id: "weight",
        name: "Peso"
    },
    {
        id: "units",
        name: "Unidades"
    },
    {
        id: "price",
        name: "Precio"
    },
    {
        id: "volume",
        name: "Volumen"
    },
]
const columns = [
    {
        source: "id",
        label: "ID",
    },
    {
        source: "name",
        fullWidth: true,
        label: "Nombre",
    },
    {
        multiline: true,
        fullWidth: true,
        source: "description",
        label: "Descripción",
    },
    {
        source: "path",
        label: "Path",
    },
    {
        xtype: "selectfield",
        source: "type",
        label: "Tipo",
        choices: types
    },
    {
        xtype: "statusfield",
        source: "status",
        label: "Estado",
    },
    {
        xtype: "booleanfield",
        source: "integration",
        label: "Integración",
    },

];
const fields = [
    {
        source: "name",
        fullWidth: true,
        label: "Nombre",
    },
    {
        multiline: true,
        fullWidth: true,
        source: "description",
        label: "Descripción",
    },
    {
        source: "path",
        label: "Path",
    },
    {
        xtype: "selectfield",
        source: "type",
        label: "Tipo",
        choices: types,
        defaultValue: "weight",
    },
    {
        xtype: "statusfield",
        source: "status",
        label: "Estado",
    },
    {
        xtype: "booleanfield",
        source: "integration",
        label: "Integración",
    },
    {
        xtype: "numberfield",
        source: "discount_percentage",
        label: "Porcentaje de descuento:",
    },
    {
        xtype: "numberfield",
        source: "oversize_discount_percentage",
        label: "Porcentaje de descuento oversize",
    },
];
const View = props => {

    return <CRUD
        title="Transportadoras"
        actions={{ "delete": false, show: true }}
        columns={columns}
        fields={fields}
    />
}
export default View;