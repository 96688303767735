import React, { useState, useEffect } from "react"
import { Row, Col, Table, Card, Button, Skeleton, Icon } from "antd";
import moment from "moment"
import { getService } from '../../services'
import styled from 'styled-components'
import { money } from "../../utils/Helper"
import { Avatar, HeadLine } from "../../components/com";
import UserCard from '../../components/UserCard';
import HistoryTable from "../../components/orders/HistoryTable";
import { Link } from "react-router-dom";
const MyTable = styled(Table)`
& .ant-table{
  box-shadow:3px 3px 3px #ccc;
}
`
const CardContainer = styled(Card)`
  box-shadow:3px 3px 3px #ccc;
`
const columns_payments = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Referencia',
        dataIndex: 'payment_reference',
        key: 'payment_reference',
    },
    {
        title: 'Valor',
        dataIndex: 'value',
        render: value => (money(value)),
        key: 'value',
    },
    {
        title: 'Estado',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: 'Respuesta',
        dataIndex: 'response',
        key: 'response',
    },
    {
        title: 'Codigo Respuesta',
        dataIndex: 'cod_response',
        key: 'cod_response',
    },
    {
        title: 'Banco',
        dataIndex: 'bank',
        key: 'bank',
    },
    {
        title: 'Franquicia',
        dataIndex: 'franchise',
        key: 'franchise',
    },
    {
        title: 'Ip',
        dataIndex: 'ip',
        key: 'ip',
    },
    {
        title: 'Fecha',
        dataIndex: 'date',
        //render: value => (moment(value).format("DD/MM/YYYY H:m:s")),
        key: 'date',
    },
]
const columns_products = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Tipo',
        dataIndex: 'type_dispatch',
        key: 'type_dispatch',
    },
    {
        title: 'Estado',
        dataIndex: 'order_status.name',
        key: 'status',
    },
    {
        title: 'Precio',
        dataIndex: 'total_price_tax_incl',
        render: value => (money(value)),
        key: 'price',
    },
    {
        title: 'Acciones',
        key: 'action',
        render: (text, record) => (
            <span>
                <Link to={`/products-orders/${record.id}/show`}>
                    <Icon type="eye" />{" "}
                    Ver
                 </Link>
            </span>
        ),
    },
]

const ShowOrder = (props) => {
    const [payments, setPayments] = useState([]);
    const [express, setExpress] = useState([]);
    const [record, setRecord] = useState({});
    const [order_id, setOrderId] = useState();
    const [user_id, setUserId] = useState();
    const [subTotal, setSubTotal] = useState(0);
    const [iva, setIva] = useState(0);
    const [total, setTotal] = useState(0);
    const [shippingaddress, setShippingAddreess] = useState({});
    const getOrderExpress = id => {
        const service = getService("products-orders");
        service.find({
            query: {
                order_id: id
            }
        })
            .then(({ data }) => {
                console.log("Express orders: ", data)
                data.map(it => {
                    setSubTotal(subtotal => (subtotal + Number(it.total_price_tax_excl)))
                    setIva(iva => (iva + Number(it.total_tax)))
                    setTotal(total => (total + Number(it.total_price_tax_incl)))
                })
                setExpress(data);
            });
    }
    const getData = id => {
        const service = getService("orders");
        service.get(id)
            .then(record => {
                setRecord(record);
                getOrderExpress(id);
                if (record.express_product_order) {
                    let shipping = JSON.parse(record.express_product_order.shipping_address_meta_data)
                    setUserId(shipping.user_id)
                    setShippingAddreess(shipping);
                }
            });
    }
    const getPayments = (id) => {
        const service = getService("payment-confirmations");
        service.find({
            query: {
                invoice: `FitHub-${id}`,
                $sort: {
                    id: -1,
                }
            }
        }).then(({ data }) => {
            setPayments(data);
        });
    }
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: record => ({
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
        }),
    };
    useEffect(() => {
        let { match } = props;
        let { id } = match.params;
        getData(id);
        setOrderId(id);
        getPayments(id);
    }, [props.id])
    return (
        <div>
            <HeadLine>Pedido #{order_id}</HeadLine>
            <Row gutter={8}>
                <Col span={12}>
                    <div style={{
                        margin: "10px 0px"
                    }}>
                        <HeadLine size="medium" style={{
                            marginBottom: 0
                        }}>Estados del pedido</HeadLine>
                    </div>
                    {<HistoryTable id={order_id} />}
                </Col>
                <Col span={12}>
                    <div>
                        <Row>
                            <Col>
                                <div style={{
                                    margin: "10px 0px"
                                }}>
                                    <HeadLine size="medium" style={{
                                        marginBottom: 0
                                    }}>Datos del cliente</HeadLine>
                                </div>
                                <UserCard id={user_id} params={shippingaddress} />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div style={{
                        margin: "10px 0px"
                    }}>
                        <HeadLine size="medium" style={{
                            marginBottom: 0
                        }}>Subpedidos Asosiados</HeadLine>
                    </div>
                    {<MyTable
                        /* rowSelection={rowSelection} */
                        footer={() => {

                            return (<Row type="flex" justify="end">
                                <Col span={8} style={{
                                    padding: "0px 4px"
                                }}>
                                    <Row gutter={16} type="flex" justify="space-between">
                                        <Col><strong>SubTotal:</strong></Col>
                                        <Col>{money(subTotal)}</Col>
                                    </Row>
                                    <Row gutter={16} type="flex" justify="space-between">
                                        <Col><strong>Iva:</strong></Col>
                                        <Col>{money(iva)}</Col>
                                    </Row>
                                    <Row gutter={16} type="flex" justify="space-between">
                                        <Col><strong>Total:</strong></Col>
                                        <Col>{money(total)}</Col>
                                    </Row>
                                </Col>
                            </Row>)
                        }}
                        scroll={{ y: 450 }} size="small"
                        dataSource={express}
                        columns={columns_products}
                        pagination={false}
                    />}
                </Col>
                {<Col span={24}>
                    <div style={{
                        margin: "10px 0px"
                    }}>
                        <HeadLine size="medium" style={{
                            marginBottom: 0
                        }}>Historial de Pagos</HeadLine>
                    </div>
                    {/* <div className="table-operations">
                        <Button type="primary">Generar</Button>
                    </div> */}
                    {<MyTable
                        /* rowSelection={rowSelection} */
                        scroll={{ y: 450 }}
                        size="small"
                        pagination={false}
                        columns={columns_payments}
                        dataSource={payments}
                    />}
                </Col>}
            </Row>

        </div>
    )
}
export default ShowOrder;