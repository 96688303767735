import React from "react";
import CRUD from "../../components/com/crud/CRUD";

const columns = [
  {
    xtype: "textfield",
    source: "title",
    fullWidth: true,
    label: "Titulo",
  },
  {
    xtype: "statusfield",
    source: "status",
    label: "Estado",
  },
];
const fields = [
  {
    xtype: "textfield",
    source: "title",
    fullWidth: true,
    label: "Titulo",
  },
  {
    xtype: "statusfield",
    source: "status",
    fullWidth: true,
    label: "Estado",
  },
  {
    xtype: "selectfield",
    source: "layout",
    label: "Diseño",
    fullWidth: true,
    choices: [
      {
        id: "fullwidth",
        name: "Completo",
      },
      {
        id: "contain",
        name: "Contener",
      },
    ],
  },

  {
    xtype: "textfield",
    source: "body",
    multiline: true,
    fullWidth: true,
    label: "Descripción",
  },
];
const Cities = (props) => {
  return (
    <CRUD
      title="CMS"
      create={true}
      actions={{ delete: false }}
      columns={columns}
      fields={fields}
    />
  );
};
export default Cities;
