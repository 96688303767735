import React from "react";
import CRUD from '../../components/com/crud/CRUD';
import { CountField } from "../../components/com/";
import moment from "moment";

const columns = [
    {
        xtype: "textfield",
        source: "name",
        fullWidth: true,
        label: "Nombre"
    },
    {
        xtype: "textfield",
        source: "integration_id",
        label: "Integration ID"
    },
    {
        xtype: "customfield",
        source: "updatedAt",
        label: "Ultima Actualización",
        render: (value, record) => (moment(value).format("DD-MM-YYYY HH:mm:ss A"))
    },
];
const fields = [
    {
        xtype: "textfield",
        source: "name",
        fullWidth: true,
        label: "Nombre"
    },
    {
        xtype: "textfield",
        showBy: "edit",
        source: "integration_id",
        label: "Integration ID"
    },
];
const PriceList = props => {

    return <CRUD
        title="Lista de Precios"
        actions={{ "delete": false }}
        tools={<div>
            <CountField
                label="Sin Integration ID"
                reference="price-list" query={{
                    integration_id: "false"
                }} />
        </div>}
        columns={columns}
        fields={fields}
    />
}
export default PriceList;