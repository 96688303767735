import feathers from "@feathersjs/feathers";
import restFeathers from "@feathersjs/rest-client";
import feathersAuthClient from "@feathersjs/authentication-client";
import { URL_API_EME, URL_AUTHENTICATION } from "../constants";
const api = feathers();
const restClient = restFeathers(URL_API_EME);
const authClient = feathersAuthClient({
    header: "Authorization",
    path: "/authentication",
    jwtStrategy: "jwt",
    entity: "user",
    service: "users",
    storage: window.localStorage,
});
api.configure(restClient.fetch(window.fetch.bind(window)));
api.configure(authClient);
export const getService = (service) => api.service(service);
export default api;
