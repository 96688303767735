import React, { Component } from "react";
import PropTypes from "prop-types";
import SubMenu from "./subMenu";
import { MenuItemLink, Responsive, DashboardMenuItem } from "react-admin";
import { Icon } from "antd";

import { Category, BubbleChart, FormatAlignJustify } from "@material-ui/icons";

const Demo = () => <div>Hola</div>;
export default class Menu extends Component {
  state = {
    config: false,
    wiki: false,
    express: false,
    pedidos: false,
    users: false
  };

  static propTypes = {
    onMenuClick: PropTypes.func,
    logout: PropTypes.object
  };

  handleToggle = menu => {
    this.setState(state => ({ [menu]: !state[menu] }));
  };

  render() {
    const { onMenuClick, logout } = this.props;
    return (
      <div>
        <MenuItemLink
          className={"menuItems"}
          to={`/orders`}
          leftIcon={<Icon type="shopping-cart" />}
          primaryText={"Pedidos"}
          onClick={onMenuClick}
        />
        <MenuItemLink
          className={'menuItems'}
          to={`/discounts`}
          leftIcon={<Icon type="tags" />}
          primaryText={'Códigos de descuento'}
          onClick={onMenuClick}
        />
        <MenuItemLink
          className={"menuItems"}
          to={`/custom-payments`}
          primaryText={"Otros Pagos"}
          onClick={onMenuClick}
          leftIcon={<Icon type="percentage" />}
        />
        {/* <MenuItemLink
          className={"menuItems"}
          to={`/recurring-shopping-cart`}
          leftIcon={<Icon type="bell" />}
          primaryText={"Plantillas Recurrentes"}
          onClick={onMenuClick}
        /> */}
        <MenuItemLink
          className={"menuItems"}
          to={`/products`}
          primaryText={"Productos"}
          onClick={onMenuClick}
          leftIcon={<Icon type="shopping" />}
        />
        <MenuItemLink
          className={"menuItems"}
          to={`/export-import-discount-products`}
          primaryText={"Import/Export Products"}
          onClick={onMenuClick}
          leftIcon={<Icon type="shopping" />}
        />
        <MenuItemLink
          className={'menuItems'}
          to={`/characteristics`}
          primaryText={'Caracteristicas'}
          onClick={onMenuClick}
          leftIcon={<Icon type="apartment" />}
        />
        <MenuItemLink
          className={"menuItems"}
          to={`/price-list`}
          primaryText={"Lista de Precios"}
          onClick={onMenuClick}
          leftIcon={<Icon type="dollar" />}
        />
        <MenuItemLink
          className={"menuItems"}
          to={`/categories`}
          primaryText={"Categorias"}
          onClick={onMenuClick}
          leftIcon={<Icon type="tags" />}
        />
        <MenuItemLink
          className={"menuItems"}
          to={`/brands`}
          primaryText={"Marcas"}
          onClick={onMenuClick}
          leftIcon={<Icon type="star" />}
        />
        <MenuItemLink
          className={"menuItems"}
          to={`/banners`}
          primaryText={"Banners"}
          onClick={onMenuClick}
          leftIcon={<Icon type="file-image" />}
        />
        <MenuItemLink
          className={"menuItems"}
          to={`/products-notifications`}
          primaryText={"Solicitudes de stock"}
          onClick={onMenuClick}
          leftIcon={<Icon type="dropbox" />}
        />
        <MenuItemLink
          className={"menuItems"}
          to={`/blogs-and-guides`}
          primaryText={"Blogs y Guías"}
          onClick={onMenuClick}
          leftIcon={<Icon type="book" />}
        />
        <MenuItemLink
          className={'menuItems'}
          to={`/reviews`}
          primaryText={'Calificaciones'}
          onClick={onMenuClick}
          leftIcon={<Icon type="star" />}
        />
        <SubMenu
          handleToggle={() => this.handleToggle("users")}
          isOpen={this.state.users}
          name="Compañias y Usuarios"
          icon={<Icon type="read" />}
        >
          <MenuItemLink
            className={"menuItems"}
            to={`/companies`}
            primaryText={"Compañias"}
            onClick={onMenuClick}
            leftIcon={<Icon type="shopping" />}
          />
          <MenuItemLink
            className={"menuItems"}
            to={`/users`}
            primaryText={"Usuarios"}
            onClick={onMenuClick}
            leftIcon={<Icon type="user-add" />}
          />
          <MenuItemLink
            className={"menuItems"}
            to={`/user-comercial`}
            primaryText={"Usuarios Comerciales"}
            onClick={onMenuClick}
            leftIcon={<Icon type="user-add" />}
          />
          <MenuItemLink
            className={"menuItems"}
            to={"/addresses"}
            primaryText={"Direcciones Usuarios"}
            onClick={onMenuClick}
            leftIcon={<Icon type="environment" />}
          />
        </SubMenu>

        <SubMenu
          handleToggle={() => this.handleToggle("config")}
          isOpen={this.state.config}
          name="Configuración"
          icon={<Icon type="read" />}
        >
          <MenuItemLink
            className={"menuItems"}
            to={`/tax-rule`}
            primaryText={"Iva"}
            onClick={onMenuClick}
            leftIcon={<Icon type="percentage" />}
          />
          {/* <MenuItemLink
            className={"menuItems"}
            to={`/shipping-costs`}
            primaryText={"Zonas de Envio"}
            onClick={onMenuClick}
            leftIcon={<Icon type="rocket" />}
          /> */}
          <MenuItemLink
            className={"menuItems"}
            to={`/cms`}
            leftIcon={<Icon type="shopping-cart" />}
            primaryText={"CMS"}
            onClick={onMenuClick}
          />
          <MenuItemLink
            className={"menuItems"}
            to={`/locations-cities`}
            leftIcon={<Icon type="environment" />}
            primaryText={"Ciudades"}
            onClick={onMenuClick}
          />
          <MenuItemLink
            className={"menuItems"}
            to={`/locations-states`}
            leftIcon={<Icon type="environment" />}
            primaryText={"Estados"}
            onClick={onMenuClick}
          />
          <MenuItemLink
            className={"menuItems"}
            to={`/fulfillment-company`}
            primaryText={"Transportadoras"}
            onClick={onMenuClick}
          /* leftIcon={<Icon type="percentage" />} */
          />
          <MenuItemLink
            className={"menuItems"}
            to={`/configurations`}
            leftIcon={<Icon type="setting" />}
            primaryText={"Variables del Sistema"}
            onClick={onMenuClick}
          />
        </SubMenu>
        <SubMenu
          handleToggle={() => this.handleToggle("integration")}
          isOpen={this.state.integration}
          name="Integración DMS"
        /* icon={<Icon type="read" />} */
        >
          <MenuItemLink
            className={"menuItems"}
            to={`/integration-status`}
            primaryText={"DMS Estados"}
            onClick={onMenuClick}
          /* leftIcon={<Icon type="percentage" />} */
          />
          <MenuItemLink
            className={"menuItems"}
            to={`/integration-productos-web-log`}
            primaryText={"DMS Productos"}
            onClick={onMenuClick}
          /* leftIcon={<Icon type="percentage" />} */
          />
          <MenuItemLink
            className={"menuItems"}
            to={`/integration-terceros-web-log`}
            primaryText={"DMS Terceros"}
            onClick={onMenuClick}
          /* leftIcon={<Icon type="percentage" />} */
          />
          <MenuItemLink
            className={"menuItems"}
            to={`/integration-price-list-web-log`}
            primaryText={"DMS Listas de Precio"}
            onClick={onMenuClick}
          /* leftIcon={<Icon type="percentage" />} */
          />
          <MenuItemLink
            className={"menuItems"}
            to={`/integration-orders-web-log`}
            primaryText={"DMS Pedidos"}
            onClick={onMenuClick}
          /* leftIcon={<Icon type="percentage" />} */
          />
        </SubMenu>

        <MenuItemLink
          className={'menuItems'}
          to={`/purchase-orders`}
          primaryText={'Órdenes de compra'}
          onClick={onMenuClick}
          leftIcon={<Icon type="shopping-cart" />}
        />
        <MenuItemLink
          className={'menuItems'}
          to={`/paymethod-config`}
          primaryText={'Configuración de pago'}
          onClick={onMenuClick}
          leftIcon={<Icon type="shopping-cart" />}
        />
        <Responsive
          small={logout}
          medium={null} // Pass null to render nothing on larger devices
        />
      </div>
    );
  }
}
