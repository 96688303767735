import React, { useCallback, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  bannerService,
  CompaniesService,
  userCommercialCompaniesService,
} from "../../utils/Api";

import {
  TextField,
  SimpleShowLayout,
  useEditController,
  Show,
} from "react-admin";
import confirm from "antd/lib/modal/confirm";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/";
import { Button, message, Modal, Select, Spin, Table, Tooltip } from "antd";
import { useEffect } from "react";
import { SelectField } from "../../components/com/form";
import { debounce } from "lodash";
import UserRemoteSelect from "./component/selectSearch";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 40,
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "1em",
  },
  inlineField: {
    display: "inline-block",
    width: "50%",
  },
}));

const UserComercialEdit = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userCompanies, setUserCompanies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [value, setValue] = useState();
  const [searchParam, setSearchParam] = useState();
  const [loading, setLoading] = useState(false);

  const getUserCompanies = async () => {
    await userCommercialCompaniesService
      .find({
        query: { $limit: 9999, user_id: props.id },
      })
      .then((res) => setUserCompanies(res.data));
  };

  const getCompanies = async (searchParam) => {
    setLoading(true);
    await CompaniesService.find({
      query: { $limit: Number.MAX_SAFE_INTEGER, $client: { searchParam } },
    }).then((res) => {
      setLoading(false);
      setCompanies(res.data);
    });
  };

  const handleDelete = async (id) => {
    confirm({
      title: "¿Seguro que desea eliminar esta compañia?",
      cancelText: "Cancelar",
      okText: "Si, eliminar",
      okButtonProps: { danger: true },
      onOk: async () => {
        await userCommercialCompaniesService
          .remove(id)
          .then(() => {
            message.success("Se ha eliminado la compañia.");
            getUserCompanies();
          })
          .catch((err) => {
            message.error(err?.message || "Ups! algo ha salido mal.");
          });
      },
    });
  };

  //   const handleChange = (v) => {
  //     setValue(v);
  //   };

  const handleSave = async () => {
    await userCommercialCompaniesService
      .create({
        user_id: Number(props.id),
        company_id: Number(value.id),
      })
      .then(() => {
        message.success("Compañia asociada correctamente");
        getUserCompanies();
        setSearchParam("");
        setCompanies([]);
        setIsModalOpen(false);
        setValue(undefined);
      })
      .catch((err) => {
        message.error(err?.message || "Ups! algo ha salido mal.");
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;

    setSearchParam(value);
    if (value.length >= 3) {
      getCompanies(value);
    } else {
      setCompanies([]);
    }
  };

  useEffect(() => {
    getUserCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <Typography>Usuario Comercial</Typography>
        <TextField source="first_name" label="Nombre" />
        <TextField source="last_name" label="Apellido" />
        <TextField source="email" label="Email" />
        <Grid>
          <Button
            style={{ margin: "30px 0px 10px" }}
            type="primary"
            onClick={() => showModal()}
          >
            Agregar Compañía
          </Button>
          <Modal
            style={{ textAlign: "center" }}
            title="Compañías"
            footer={false}
            visible={isModalOpen}
            onCancel={handleCancel}
            onOk={handleOk}
          >
            {value && <p>Compañia seleccionada {value.name}</p>}
            <input
              type="search"
              id="search"
              style={{ width: "100%" }}
              value={searchParam}
              onChange={handleInputChange}
              placeholder="Ingresa el nombre o NIT de la compañía"
            />
            {loading && <Spin />}
            <ul className="list_companies">
              {companies.map((it) => (
                <li
                  onClick={() => setValue(it)}
                  key={it.id}
                >{`${it.name} - ${it.nit}`}</li>
              ))}
            </ul>
            {/* <Select
              showSearch
              onChange={handleChange}
              placeholder="Seleccione la compañía"
              style={{ width: "100%" }}
              filterOption={(input, option) => {
                const a = option.props.children
                  .toLowerCase()
                  .match(input.toLowerCase());
                return a !== null;
              }}
            >
              {companies.map((it) => (
                <Select.Option
                  key={it.id + ""}
                >{`${it.name} - ${it.nit}`}</Select.Option>
              ))}
            </Select> */}
            <Button
              type="primary"
              className="btn_save"
              style={{ marginBottom: 36, marginTop: 15 }}
              onClick={handleSave}
            >
              Guardar
            </Button>
          </Modal>
          <Grid>
            <Table
              dataSource={userCompanies}
              columns={[
                {
                  title: "Compañia",
                  dataIndex: ["company", "name"],
                },
                {
                  title: "nit",
                  dataIndex: ["company", "nit"],
                },
                {
                  title: "Acciones",
                  render: (_, record) => (
                    <Tooltip title="Eliminar">
                      <Button
                        type="primary"
                        onClick={() => handleDelete(record.id)}
                      >
                        Eliminar
                      </Button>
                    </Tooltip>
                  ),
                },
              ]}
            />
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
};
export default UserComercialEdit;
