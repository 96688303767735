import React from "react";
import PolygonsList from "./PolygonsList"
import PolygonEdit from "./PolygonEdit"
import PolygonCreate from "./PolygonCreate"

const Title = ({ record }) => {
  return <span>{record ? `Editar ${record.name}` : "Listado Polígonos"}</span>;
};

const typeList = [
  { id: "scheduled", name: "Programado" },
  { id: "not_scheduled", name: "Express" }
];

const statusList = [
  { id: "active", name: "Activo" },
  { id: "inactive", name: "Inactivo" }
];

export {
  Title,
  typeList,
  statusList,
  PolygonsList,
  PolygonEdit,
  PolygonCreate,
};
