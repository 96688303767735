import React, { useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ShowButton,
  Filter,
  TextInput,
  ReferenceField,
  BooleanField,
  SelectField,

  ReferenceInput,
  SelectInput
} from "react-admin";
import styled from "styled-components";
import { MoneyField, Avatar, StatusField, StatusSelectInput } from "../../components/com"
import { typeList, statusList } from "./";
import { getService } from "../../services";
import { useState } from "react";
import { message } from "antd";

const WrapperList = styled.div`
    display:flex;
    justify-content:start;
    align-items:center;
`;
const WrapperItem = styled.div`
  text-align: center;
  padding: 10px 20px;
  margin: 10px;
  font-size: 16px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 3px 3px 3px rgba(0,0,0,.2);

  & .value{
    font-size: 24px;
  }
  & .label{
    font-weight:bold;
  }
`;

const CountField = ({ reference, label, query, ...props }) => {
  const [value, setValue] = useState(0);
  const getCount = () => {
    let service = getService(reference);
    service.find({
      query: {
        $limit: 0,
        ...query
      }
    })
      .then(({ total }) => {
        setValue(total);
      })
      .catch(err => message.error(err.message));
  }
  useEffect(() => {
    if (reference)
      getCount();
  }, [reference]);
  return (<WrapperItem>
    {label && <div className="label">{label}</div>}
    <div className="value">{value}</div>
  </WrapperItem>)
}

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="ID" source="id" alwaysOn />
    <TextInput label="Buscar" source="data_value" alwaysOn />
    <TextInput source="code" label="Código" alwaysOn />
    <ReferenceInput
      source="category_id"
      reference="categories"
      label="Categoría"
      sort={{
        field: "name",
        order: "ASC"
      }}
      perPage={100000} alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="brand_id"
      reference="brands"
      label="Marca"
      sort={{
        field: "name",
        order: "ASC"
      }}
      perPage={100000} alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <StatusSelectInput initialValue={null} alwaysOn />
    <StatusSelectInput
      label="Imágenes"
      source="images"
      initialValue={null}
      choices={[
        {
          id: true,
          name: "Con Imágenes"
        },
        {
          id: false,
          name: "Sin Imágenes"
        }
      ]} alwaysOn />

  </Filter>
);

const ExpressProductList = (props) => {
  return (<>
    <WrapperList>
      <CountField label="Sin Marca" query={{ brand_id: 1 }} reference="products" />
      <CountField label="Sin Categoría" query={{ category_id: 1 }} reference="products" />
      <CountField label="Sin Imagen" query={{ images: false }} reference="products" />
      <CountField label="Activo Sin Cantidad" query={{ status: "active", quantity: 0 }} reference="products" />
    </WrapperList>
    <List
      {...props}
      filters={<Filters />}
      exporter={false}
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField source="id" label="id" />
        <Avatar source="media" label="Foto" />
        <TextField source="name" label="Nombre" />
        <TextField source="code" label="Código" />

        <ReferenceField
          label="Categoria"
          source="category_id"
          reference="categories"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Marca" source="brand_id" reference="brands">
          <TextField source="name" />
        </ReferenceField>
        <MoneyField source="price" label="Precio sin IVA" />
        <TextField source="quantity" label="Cantidad" />
        <StatusField
          source="status"
          label="Estado"
        />
        <ReferenceField label="Iva" source="tax_rule_id" reference="tax-rule">
          <TextField source="value" />
        </ReferenceField>
        <EditButton label="Editar" />
      </Datagrid>
    </List>

  </>
  );
};

export default ExpressProductList;
