import React, { useState } from "react";
import { Avatar, Button, message, Row, Col, Card } from "antd";
import { Grid } from "../../components/com";
import { URL_S3, DEFAULT_AVATAR_IMAGE } from "../../constants";
import { getService } from "../../services";
const columns = [
  {
    title: "Foto",
    dataIndex: "order_detail.product_main_image",
    width: 80,
    render: (value, record) => (
      <Avatar
        src={value ? `${URL_S3}/${value}` : DEFAULT_AVATAR_IMAGE}
        size="small"
      />
    ),
    key: "picture"
  },
  {
    title: "Nombre",
    dataIndex: "order_detail.product_name",
    key: "name"
  },
  {
    title: "Cantidad Comprada",
    dataIndex: "order_detail.quantity",
    key: "quantity"
  },
  {
    title: "Cantidad Enviada",
    dataIndex: "quantity",
    key: "quantity-sent"
  },
  {
    title: "Cantidad Pendiente por Envío",
    dataIndex: "order_detail",
    key: "quantity-pending",
    render: (value, record) => `${Number(value.quantity) - Number(value.sent)}`
  }
];
const ShippingDetail = ({ record, onCancel, ...props }) => {
  const [loading, setLoading] = useState(false);
  const handleMarkSent = () => {
    const service = getService("shipping");
    if (record.id) {
      setLoading(true);
      service
        .patch(record.id, {
          shipping_status_id: 3
        })
        .then(resp => {
          setLoading(false);
          if (onCancel) onCancel();
          message.success("Envío Entregado!");
        })
        .catch(err => {
          setLoading(false);
          message.error(err.message);
        });
    }
  };
  return (
    <>
      {record.private_notes && (
        <Row type="flex" justify="start" align="middle">
          <Col span={24}>
            <Card title={<h3>Notas Privadas:</h3>} hoverable bordered>
              <div>
                <article>{record.private_notes}</article>
              </div>
            </Card>
          </Col>
        </Row>
      )}
      <Grid
        title="Detalle del envío"
        filterDefaultValues={{
          shipping_id: record.id
        }}
        actions={false}
        source="shipping-details"
        columns={columns}
      />
      {record.shipping_status_id == 2 && (
        <Row type="flex" justify="center" align="middle">
          <Col>
            <Button
              loading={loading}
              block
              type="primary"
              size="large"
              onClick={handleMarkSent}
            >
              Marcar como Entregado
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};
export default ShippingDetail;
