import styled from 'styled-components';
import { Col } from "antd";
export const Container = styled(Col)`
    margin:8px 0px!important;
    & .ant-card-extra{
        padding:0px!important;
        margin:0px!important;
    }
    & .btn-show{
        color:#fff!important;
    }
    & .head-title{
        cursor:pointer;
    }
    & .ant-form-horizontal{
      display:flex!important;
  }
  & .ant-form-horizontal .footer-advanced-form .ant-row-flex .ant-divider  {
      display:none!important;
  }
  & .ant-form-horizontal .footer-advanced-form .login-form-button{
    margin: 15px 0px!important;
    font-size: 24px!important;
    width: auto!important;
    border-radius: 4px!important;
    padding: 4px 10px!important;
    min-height: auto!important;
    height: auto!important;
    margin-top: 27px!important;
    margin-bottom: 0px!important;
  }
  & .ant-form-horizontal .footer-advanced-form {
      margin: 10px!important;
      padding: 10px!important;
      height: auto!important;
  }
  & .card-closed .ant-card-body {
    padding: 0px!important;
  }
  & .ant-card-body {
    padding: 10px 20px!important;
  }
  & .ant-card-head{
    min-height: auto!important;
    padding: 0 10px!important;
    background:#062f87!important;
    border:0px!important;
    color:#FFF!important;
  }
  & .card-closed .ant-card-head{
    /* background-color:var(--gray-dark)!important; */
  }
  &  .ant-card-head-title {
    padding: 4px 0!important;
    text-transform: uppercase!important;
  }
`;