import React from "react";
import ExpressProductCategoriesList from "./ProductsList";
import ExpressProductCategoriesEdit from "./ProductsEdit";
import ExpressProductCategoriesCreate from "./ProductsCreate";
import ExpressProductCategoriesShow from "./ProductsShow";

const Title = ({ record }) => {
  return <span>{record ? `Editar ${record.name}` : "Listar usuarios"}</span>;
};

const ImgField = ({ record }) => {
  return (
    <img
      src={`https://estrategia-web.s3.amazonaws.com/${record.path_image}`}
      height="40px"
      width="40px"
    />
  );
};

export {
  ExpressProductCategoriesList,
  ExpressProductCategoriesEdit,
  Title,
  ExpressProductCategoriesCreate,
  ExpressProductCategoriesShow,
  ImgField
};
