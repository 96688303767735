import React from "react";
import CRUD from "../../components/com/crud/CRUD";

/* 
type
user_id
company_id
reference
payment_confirmation_id
credit_card_id
amount
status
method
*/
const payment_methods = [
  {
    id: "credit_card",
    name: "Tarjeta de Crédito"
  },
  {
    id: "pse",
    name: "PSE"
  }
];
const types = [
  {
    id: "wallet",
    name: "Cartera"
  },
  {
    id: "others",
    name: "Otro"
  }
];
const status = [
  {
    id: "pending_payment",
    name: "Pago Pendiente"
  },
  {
    id: "rejected",
    name: "Rechazado"
  },
  {
    id: "paid",
    name: "Pagado"
  },
  {
    id: "paid_and_processed",
    name: "Pagado y Procesado"
  }
];
const columns = [
  {
    xtype: "textfield",
    label: "Id",
    source: "id",
    filter: true
  },
  {
    xtype: "selectfield",
    source: "type",
    label: "Tipo",
    choices: types,
    filter: true
  },
  {
    xtype: "textfield",
    source: "user_id",
    filter: true,
    label: "User Id"
  },
  {
    xtype: "customfield",
    reference: "users",
    source: "user_id",
    label: "Usuario",
    render: (value, record) =>
      `${record.firstname || ""} ${record.lastname || ""}`
  },
  {
    xtype: "selectfield",
    source: "company_id",
    filter: true,
    reference: "companies",
    perPage: 50000,
    sort: { field: "name", order: "ASC" },
    label: "Compañia"
  },
  {
    xtype: "textfield",
    source: "payment_id",
    filter: true,
    label: "Payment Id"
  },
  {
    source: "reference",
    label: "Referencia"
  },
  {
    xtype: "selectfield",
    source: "payment_confirmation_id",
    reference: "payment-confirmations",
    label: "Confirmación de Pago"
  },
  {
    xtype: "selectfield",
    source: "credit_card_id",
    reference: "credit-cards",
    optionText: "masked_number",
    label: "Tarjeta de Crédito"
  },
  {
    xtype: "numberfield",
    source: "amount",
    label: "Cantidad"
  },
  {
    xtype: "statusfield",
    source: "status",
    label: "Estado",
    filter: true,
    choices: status
  },
  {
    xtype: "selectfield",
    source: "method",
    label: "Metodo",
    choices: payment_methods
  }
];
const fields = [
  {
    xtype: "statusfield",
    source: "status",
    label: "Estado",
    choices: status,
    fullWidth: true
  },
  {
    xtype: "selectfield",
    source: "type",
    disabled: true,
    fullWidth: true,
    label: "Tipo",
    choices: types
  },
  {
    xtype: "customfield",
    reference: "users",
    source: "user_id",
    disabled: true,
    label: "Usuario",
    render: (value, record) =>
      `${record.firstname || ""} ${record.lastname || ""}`
  },
  {
    xtype: "selectfield",
    source: "company_id",
    disabled: true,
    reference: "companies",
    label: "Compañia"
  },
  {
    source: "reference",
    disabled: true,
    label: "Referencia"
  },
  {
    xtype: "selectfield",
    source: "payment_confirmation_id",
    disabled: true,
    reference: "payment-confirmations",
    label: "Confirmación de Pago"
  },
  {
    xtype: "selectfield",
    source: "credit_card_id",
    disabled: true,
    reference: "credit-cards",
    label: "Tarjeta de Crédito"
  },
  {
    xtype: "numberfield",
    source: "amount",
    disabled: true,
    label: "Cantidad"
  },
  {
    xtype: "selectfield",
    source: "method",
    disabled: true,
    label: "Metodo",
    choices: payment_methods
  }
];

/* 
Filtrar por ID

Filtrar por Type ENUM('wallet', 'others')

Filtrar por user_id

Filtrar por company_id

Filtrar por status ENUM('pending_payment', 'rejected', 'paid', 'paid_and_processed')

Filtrar por payment_id
*/
const View = props => {
  return (
    <CRUD
      title="Otros Pagos"
      create={true}
      showId={false}
      actions={{ delete: false }}
      columns={columns}
      fields={fields}
    />
  );
};
export default View;
