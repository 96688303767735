import React, { useState, useEffect } from "react";
import {
  Table,
  message,
  Row,
  Col,
  Input,
  InputNumber,
  Button,
  Popconfirm
} from "antd";
import { getService } from "../../services";
import moment from "moment";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: 10px;
`;

const PriceField = ({
  list_price = {},
  integration_hash,
  id,
  onSubmit,
  product_id,
  ...props
}) => {
  const [value, setValue] = useState();
  const [initialized, setInitialized] = useState(false);
  const [edit, setEdit] = useState(false);
  const handleChange = value => {
    setValue(value);
  };
  const handleDelete = id => {
    const service = getService("product-price-list");
    service
      .remove(id)
      .then(resp => {
        message.success("Precio Eliminado de la Lista.");
        if (onSubmit) onSubmit();
      })
      .catch(err => message.error(err.message));
  };
  const handleSave = () => {
    let payloads = {
      price_list_id: id,
      product_id
    };
    const service = getService("product-price-list");
    console.log(value, id, list_price);

    setEdit(edit => !edit);
    service
      .find({
        query: {
          ...payloads
        }
      })
      .then(({ total }) => {
        if (total > 0) {
          return service
            .patch(list_price.id, {
              ...payloads,
              price: value
            })
            .then(response => {
              message.success("Precion Actualizado!");
              if (onSubmit) onSubmit();
            })
            .catch(err => message.error(err.message));
        }
        service
          .create({
            ...payloads,
            price: value
          })
          .then(response => {
            message.success("Precion Agregado!");
            if (onSubmit) onSubmit();
          })
          .catch(err => message.error(err.message));
      })
      .catch(err => message.error(err.message));
  };
  useEffect(() => {
    if (!initialized) {
      setValue(list_price.price);
      setInitialized(true);
    }
  }, [list_price]);
  return (
    <Row gutter={4} type="flex" justify="space-between" align="middle">
      {initialized && (
        <Col>
          {edit && (
            <InputNumber
              disabled={
                list_price.integration_hash != "false" &&
                typeof list_price.integration_hash != "undefined"
              }
              defaultValue={list_price.price}
              placeholder="precio"
              min={0}
              onKeyDown={event => {
                if (event.key === "Enter") {
                  handleSave();
                }
              }}
              onChange={handleChange}
            />
          )}
          {!edit && (
            <span
              style={{
                minWidth: 80,
                padding: 5
              }}
            >
              {value || list_price.price}
            </span>
          )}
        </Col>
      )}
      {initialized && (
        <Col>
          <Row gutter={4} type="flex" justify="space-between" align="middle">
            {edit && (
              <Col>
                <Button
                  disabled={
                    list_price.integration_hash != "false" &&
                    typeof list_price.integration_hash != "undefined"
                  }
                  type="dashed"
                  icon={"save"}
                  onClick={handleSave}
                />
              </Col>
            )}
            {!edit && (
              <Col>
                <Button
                  disabled={
                    list_price.integration_hash != "false" &&
                    typeof list_price.integration_hash != "undefined"
                  }
                  type="primary"
                  icon={"edit"}
                  onClick={() => {
                    setEdit(edit => !edit);
                  }}
                />
              </Col>
            )}
            {!edit && list_price.id && (
              <Col>
                <Popconfirm
                  placement="top"
                  title={"Desea eliminar el precio de la lista?"}
                  onConfirm={() => handleDelete(list_price.id)}
                  okText="Si"
                  cancelText="No"
                >
                  <Button
                    disabled={
                      list_price.integration_hash != "false" &&
                      typeof list_price.integration_hash != "undefined"
                    }
                    type="danger"
                    icon={"delete"}
                  />
                </Popconfirm>
              </Col>
            )}
          </Row>
        </Col>
      )}
    </Row>
  );
};
const IntegrationHash = ({ record = {}, source, ...props }) => {
  const [edit, setEdit] = useState(false);
  //return list_price && list_price.integration_hash ? list_price.integration_hash : ''
  return (
    <Row gutter={8} type="flex" justify="start" align="middle">
      {edit && (
        <Col span={20}>
          <Input defaultValue={record[source] || ""} />
        </Col>
      )}
      {!edit && (
        <Col>
          <span> {record[source] || ""}</span>
        </Col>
      )}
      <Button
        type="dashed"
        icon={!edit ? "edit" : "save"}
        onClick={() => setEdit(edit => !edit)}
      />
    </Row>
  );
};
const PriceList = ({ record, ...props }) => {
  let { id } = record;
  const [data, setData] = useState([]);
  const [prices, setPrices] = useState([]);
  const [loading, setLoading] = useState(false);
  /* 
        price_list_id,
        product_id,
        price,
        id
    */
  const getData = async () => {
    setData([]);
    await getPriceList();
    const service = getService("price-list");
    service
      .find({
        query: {}
      })
      .then(({ data }) => {
        setData(data);
      })
      .catch(err => message.error(err.message));
  };
  const getPriceList = record => {
    const service = getService("product-price-list");
    setLoading(true);
    setPrices([]);
    return service
      .find({
        query: {
          product_id: id
        }
      })
      .then(({ data }) => {
        setLoading(false);
        setPrices(data);
      })
      .catch(err => message.error(err.message));
  };
  const save = record => {
    let { id } = record;
    alert("ID: " + id);
    const service = getService("product-price-list");
    service
      .patch({
        query: {}
      })
      .then(({ data }) => {
        setData(data);
      })
      .catch(err => message.error(err.message));
  };
  const renderPrice = (value, record) => {
    return (
      <PriceField
        onSubmit={() => {
          getData();
        }}
        product_id={id}
        {...record}
      />
    );
  };
  useEffect(() => {
    getData();
  }, []);
  if (loading) return "Cargando...";
  return (
    <Wrapper>
      {
        <Table
          scroll={{
            x: 600
          }}
          size="small"
          columns={[
            {
              dataIndex: "id",
              title: "ID Lista",
              width: 100
            },
            {
              dataIndex: "name",
              title: "Lista",
              width: 100
            },
            {
              title: `Precio sin IVA`,
              xtype: "customfield",
              source: "price",
              width: 150,
              render: (value, record) => {
                if (!record["list_price"])
                  record["list_price"] = prices.find(item => {
                    if (record.integration_id == 52)
                      console.log(
                        `Record::`,
                        record,
                        item,
                        item.price_list_id,
                        record.id
                      );
                    return item.price_list_id == record.id;
                  });

                console.log(`ListPrice::`, record["list_price"]);
                return renderPrice(value, record);
              }
            },
            {
              xtype: "textfield",
              dataIndex: "price_with_tax",
              title: "Precio con IVA",
              render: (value, record) => {
                let list_price = prices.find(
                  item => item.price_list_id == record.id
                );
                return list_price && list_price.price_with_tax
                  ? list_price.price_with_tax
                  : "";
                //return (<IntegrationHash source={"integration_hash"} record={list_price} />)
              }
            },
            {
              xtype: "textfield",
              dataIndex: "integration_id",
              title: "Integration ID",
              render: (value, record) => {
                let list_price = prices.find(
                  item => item.price_list_id == record.id
                );
                return list_price && list_price.integration_id
                  ? list_price.integration_id
                  : "";
                //return (<IntegrationHash source={"integration_hash"} record={list_price} />)
              }
            },
            {
              xtype: "textfield",
              dataIndex: "updatedAt",
              title: "Ultima Actualización",
              render: (value, record) => {
                let list_price = prices.find(
                  item => item.price_list_id == record.id
                );
                return list_price && list_price.updatedAt
                  ?  moment(list_price.updatedAt).format("DD-MM-YYYY HH:mm:ss A")
                  : "";
                //return (<IntegrationHash source={"integration_hash"} record={list_price} />)
              }

            },
            {
              xtype: "textfield",
              dataIndex: "integration_hash",
              title: "Integration Hash",
              render: (value, record) => {
                let list_price = prices.find(
                  item => item.price_list_id == record.id
                );
                return list_price && list_price.integration_hash
                  ? list_price.integration_hash
                  : "";
                //return (<IntegrationHash source={"integration_hash"} record={list_price} />)
              }
            }
          ]}
          dataSource={data}
          pagination={false}
        />
      }
    </Wrapper>
  );
};
export default PriceList;
