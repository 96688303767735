import React from "react";
import { List, Datagrid, TextField, EditButton, ShowButton } from "react-admin";
import { Avatar } from "../../../components/com";
const ExpressProductCategoriesList = props => (
  <List
    {...props}
    exporter={false}
    filterDefaultValues={{ parent_id: 1 }}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source="id" />
      <Avatar source="path_image" label="Imágen " />
      <TextField source="name" />
      <ShowButton label="Mostrar hijos" />
      <EditButton />
    </Datagrid>
  </List>
);

export default ExpressProductCategoriesList;
