import React, {Fragment, useCallback, useState} from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    SelectField,
    SelectInput,
    Filter,
    TextInput
} from 'react-admin';
import {Title} from '.';
import {Route, useHistory} from 'react-router-dom';
import StatusField from '../../components/com/StatusField'
import {makeStyles} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 400,
    },
    drawerPaper: {
        zIndex: 100,
    },
}));
const statusList = [
    {id: 'active', name: 'Activo'},
    {id: 'inactive', name: 'Inactivo'},
];

const CustomFilters = (props) => (
    <Filter {...props}>
        <SelectInput label="Estado" source="status" choices={statusList} optionText="name" optionValue="id" alwaysOn/>
        <TextInput label="Buscar" source="data_value" alwaysOn/>
    </Filter>
);
const CharacteristicsList = (props) => {
    const [isMatch, setIsMatch] = useState(false);
    const classes = useStyles();
    const history = useHistory();
    const handleClose = useCallback(() => {
        history.push('/faq');
    }, [history]);


    return (
        <div>
            <Route path="/characteristics/:id">
                {({match}) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <Fragment>
                            <List
                                {...props}
                                title={<Title/>}
                                exporter={false}
                                bulkActionButtons={false}
                                filters={<CustomFilters/>}
                            >
                                <Datagrid rowClick="edit" optimized {...props}>
                                    <TextField source="id"/>
                                    <TextField source='name' label='Nombre'/>
                                    <EditButton label="Editar"/>
                                </Datagrid>
                            </List>
                        </Fragment>
                    );
                }}
            </Route>
        </div>
    );
};
export default CharacteristicsList;
