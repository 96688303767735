import React, { useState } from 'react';
import { Card, Button } from "antd";
import { Container } from "./Styles";
const Box = ({ children, ...props }) => {
    const [show, setShow] = useState(props.show || false);
    const handleShowHidde = () => {
        setShow(show => (!show));
    }
    return (<Container className={props.className}>
        <Card
            bordered
            className={`card-${show ? "opened" : "closed"}`}
            extra={<>
                <Button className="btn-show" icon={show ? "minus" : "plus"} type="link"
                    onClick={handleShowHidde} />
            </>}
            title={props.title && <div className="head-title" onClick={handleShowHidde}>
                {props.title}
            </div>}
        >
            <>{show && children}</>
        </Card>
    </Container>
    );
}
export default Box;