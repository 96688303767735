import React from "react";
import CRUD from '../../components/com/crud/CRUD';

const order_needs_approval = [
    { id: "true", name: "Si" },
    { id: "false", name: "No" }
  ];

const columns = [
    {
        xtype: "textfield",
        source: "name",
        fullWidth: true,
        filter: true,
        label: "Nombre"
    },
    {
        source: 'nit',
        multiline: true,
        fullWidth: true,
        filter: true,
        label: "NIT"
    },
    {
        xtype: "selectfield",
        source: 'price_list_id',
        filter: true,
        fullWidth: true,
        label: "Lista de Precios",
        reference: "price-list"
    },
    {
        xtype: "selectfield",
        source: 'order_needs_approval',
        filter: true,
        fullWidth: true,
        label: "Pedidos requieren aprobación del administrador",
        choices: order_needs_approval,
    },
    {
        xtype: "selectfield",
        source: 'override_credit',
        filter: false,
        fullWidth: true,
        label: "Permitir sobrecupo de crédito",
        choices: order_needs_approval,
    }
];
const fields = [
    {
        xtype: "textfield",
        source: "name",
        fullWidth: true,
        label: "Nombre"
    },
    {
        source: 'nit',
        multiline: true,
        fullWidth: true,
        label: "NIT"
    },
    {
        xtype: "selectfield",
        source: 'price_list_id',
        fullWidth: true,
        label: "Lista de Precios",
        reference: "price-list"
    },
    {
        xtype: "selectfield",
        source: 'order_needs_approval',
        filter: true,
        fullWidth: true,
        label: "Pedidos requieren aprobación del administrador",
        choices: order_needs_approval,
    },
    {
        xtype: "selectfield",
        source: 'override_credit',
        filter: false,
        fullWidth: true,
        label: "Permitir sobrecupo de crédito",
        choices: order_needs_approval,
    }
];
const Companies = props => {

    return <CRUD
        title="Compañias"
        /* actions={{ "delete": false }} */
        columns={columns}
        fields={fields}
    />
}
export default Companies;