import React, { useState, useEffect } from "react"
import { Row, Col, Table, Card, Button, Skeleton, Icon } from "antd";
import moment from "moment"
import { getService } from '../../services'
import styled from 'styled-components'
import { money } from "../../utils/Helper"
import { Avatar, HeadLine } from "../../components/com";
import UserCard from '../../components/UserCard';
import HistoryTable from "../../components/orders/HistoryTable";
const MyTable = styled(Table)`
& .ant-table{
  box-shadow:3px 3px 3px #ccc;
}
`
const CardContainer = styled(Card)`
  box-shadow:3px 3px 3px #ccc;
`
const columns = [
    {
        title: 'Nombre',
        dataIndex: 'order_status.name',
        key: 'name',
    },
    {
        title: 'Fecha',
        dataIndex: 'createdAt',
        render: value => (moment(value).format("DD/MM/YYYY H:m:s")),
        key: 'date',
    },
]
const columns_products = [
    {
        title: 'Foto',
        dataIndex: 'express_product_main_image',
        render: value => (<Avatar size="small" src={value} />),
        key: 'picture',
    },
    {
        title: 'Nombre',
        dataIndex: 'express_product_name',
        key: 'name',
    },
    {
        title: 'Precio',
        dataIndex: 'unit_price_tax_incl',
        render: value => (money(value)),
        key: 'price',
    },
    {
        title: 'Cantidad',
        dataIndex: 'quantity',
        key: 'quantity',
    },
]

const ShowOrder = (props) => {
    const [historyList, sethistoryList] = useState([]);
    const [record, setRecord] = useState({});
    const [user, setUserData] = useState({});
    const [order_id, setOrderId] = useState();
    const [order_express_id, setOrderExpressId] = useState();
    const [details, setDetails] = useState([]);
    const [shippingaddress, setShippingAddreess] = useState({});
    const [user_id, setUserId] = useState();

    const getProductOrderDetails = id => {
        const service = getService("products-orders-details");
        service.find({
            query: {
                express_product_order_id: id
            }
        })
            .then(({ data }) => {
                setDetails(data)
            })
    }
    const getData = id => {
        const service = getService("products-orders");
        service.get(id)
            .then(record => {

                console.log("RECORD: ", record)
                setRecord(record);
                setOrderId(record.order_id);
                if (record.shipping_address_meta_data) {
                    let shipping = JSON.parse(record.shipping_address_meta_data);
                    setUserId(shipping.user_id);
                    setShippingAddreess(shipping);
                }
            });
    }
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: record => ({
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
        }),
    };
    useEffect(() => {
        let { match } = props;
        let { id } = match.params;
        setOrderExpressId(id);
        getData(id);
        getProductOrderDetails(id);
    }, [props, props.id])
    return (
        <Row gutter={8}>
            <Col>
                <HeadLine>Pedido # {order_id} - {order_express_id}</HeadLine>
                <Row gutter={8}>
                    <Col span={12}>
                        <div style={{
                            margin: "10px 0px"
                        }}>
                            <HeadLine size="medium" style={{
                                marginBottom: 0
                            }}>Estados de Pedidos</HeadLine>
                            {/* user && <div>
                            <span>
                                {user.first_name}
                            </span>
                        </div> */}
                        </div>
                        {<HistoryTable id={order_express_id} />}
                    </Col>
                    <Col span={12}>
                        <div>
                            <Row>
                                <Col>
                                    <div style={{
                                        margin: "10px 0px"
                                    }}>
                                        <HeadLine size="medium" style={{
                                            marginBottom: 0
                                        }}>Datos del cliente</HeadLine>
                                    </div>
                                    <UserCard id={user_id} params={shippingaddress} />
                                </Col>
                            </Row>
                        </div>

                    </Col>
                </Row>
            </Col>
            <Col>
                <Row gutter={8}>
                    <Col span={12}>
                        <div className="table-operations">
                            <HeadLine size="medium" style={{
                                marginBottom: 0
                            }}>Entrega Inmediata</HeadLine>
                            <Button type="primary">Generar</Button>
                        </div>
                        {<MyTable
                            /*  rowSelection={rowSelection} */
                            scroll={{ y: 450 }} size="small"
                            dataSource={details
                                .filter(it => it.type_product == "not_scheduled")
                            } columns={
                                columns_products

                            } pagination={false} />}
                    </Col>
                    <Col span={12}>
                        <div className="table-operations">
                            <HeadLine size="medium" style={{
                                marginBottom: 0
                            }}>Entrega Programada</HeadLine>
                            <Button type="primary">Generar</Button>
                        </div>
                        {details && <MyTable
                            /*  rowSelection={rowSelection} */
                            scroll={{ y: 450 }} size="small" dataSource={details
                                .filter(it => it.type_product != "not_scheduled")
                            } columns={columns_products
                            } pagination={false} />}
                    </Col>
                </Row>
                <Row gutter={8} type="flex" justify="center">

                    <Col span={24} style={{
                        padding: "0px 4px"
                    }}>
                        <HeadLine size="medium">
                            <span>Resumen</span>
                        </HeadLine>
                        <Card>
                            <Row gutter={16} type="flex" justify="space-between">
                                <Col><strong>SubTotal:</strong></Col>
                                <Col>{money(record.total_price_tax_excl)}</Col>
                            </Row>
                            <Row gutter={16} type="flex" justify="space-between">
                                <Col><strong>Iva:</strong></Col>
                                <Col>{money(record.total_tax)}</Col>
                            </Row>
                            <Row gutter={16} type="flex" justify="space-between">
                                <Col><strong>Total:</strong></Col>
                                <Col>{money(record.total_price_tax_incl)}</Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </Row>

    )
}
export default ShowOrder;