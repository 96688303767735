import React, { Fragment } from 'react';
import {
	List,
	Datagrid,
	TextField,
	EditButton,
	SelectInput,
	Filter,
	// TextInput,
	DeleteButton,
} from 'react-admin';
import { Route } from 'react-router-dom';
import { StatusField } from '../../components/com';

const statusList = [
	{ id: 'active', name: 'Activo' },
	{ id: 'inactive', name: 'Inactive' }
];

const CustomFilters = (props) => (
	<Filter {...props}>
		{/* <TextInput label="Buscar" source="q" alwaysOn /> */}
		<SelectInput label="Estado" source="status" choices={statusList} optionText="name" optionValue="id" alwaysOn />
	</Filter>
)

const DiscountsList = (props) => {
	return (
		<div>
			<Route path="/discounts/:id">
				{() => (
					<Fragment>
						<List
							{...props}
							title="Descuentos"
							exporter={false}
							bulkActionButtons={false}
							sort={{ field: 'id', order: 'DESC' }}
							filters={<CustomFilters />}
						>
							<Datagrid rowClick='edit' optimized {...props}>
								<TextField source="id" />
								<TextField source="name" label="Nombre" />
								<TextField source="code" label="Código" />
								<StatusField source="status" label="Estado" />
								<EditButton label='Editar' />
								<DeleteButton label="Eliminar" />
							</Datagrid>
						</List>
					</Fragment>
				)}
			</Route>
		</div>
	)
}

export default DiscountsList