import React from "react";
import SimpleForm from "./SimpleForm";
import styled from "styled-components";
import {
  Input,
  Select,
  InputNumber,
  Divider,
  Checkbox,
  DatePicker
} from "antd";
import SelectField from "./SelectField";
const { TextArea } = Input;
const Form = styled(SimpleForm)`
  &.ant-form {
    width: 100% !important;
    max-width: 100% !important;
  }
  & .ant-form {
    width: 100% !important;
  }
`;
const HeadLine = styled.h2`
  color: rgb(0, 0, 0, 0.8);
  font-size: 24px;
  text-align: center;
  margin-bottom: 0px !important;
`;
const SubHeadLine = styled.h2`
  font-style: italic;
  color: rgb(0, 0, 0, 0.5);
`;
const DividerHeadLine = props => (
  <div {...props} flex={1}>
    <SubHeadLine
      style={{
        marginBottom: 0,
        ...props.style
      }}
    >
      {props.text}
    </SubHeadLine>
  </div>
);
const Combo = ({
  data = [],
  valueField = "value",
  displayField = "text",
  ...props
}) => {
  const { Option } = Select;
  return (
    <Select {...props} size="large" style={{ width: "100%", minWidth: 220 }}>
      {data.map((item, index) => (
        <Option key={item[valueField] || index}>
          {item[displayField] || "--- None ---"}
        </Option>
      ))}
    </Select>
  );
};
const Builder = ({ xtype = "text", ...field }, index) => {
  let { required } = field;

  if (required) {
    field["validations"] = field["validations"] || [];
    field["validations"].push({
      required: field.required,
      message: field.message || `This field is required`
    });
    delete field.required;
  }

  switch (xtype) {
    case "textfield":
      if (field.multiple)
        return <TextArea size="large" key={index} {...field}
          name={field.source || field.name}
        />;
      return <Input size="large" key={index} {...field}
        name={field.source || field.name}
      />;
      break;
    case "datefield":
      return <DatePicker key={index} {...field}
        name={field.source || field.name}
      />;
      break;
    case "selectfield":
      return <SelectField key={index} {...field}
        name={field.source || field.name}
      />;
      break;
    case "checkbox":
      return (
        <Checkbox key={index} xtype={xtype} {...field}
          name={field.source || field.name}
        >
          <>{field.text && <span>{field.text}</span>}</>
        </Checkbox>
      );
      break;
    case "combo":
      return <Combo key={index} {...field}
        name={field.source || field.name}
      />;
      break;
    case "number":
      return <InputNumber size="large" key={index} {...field}
        name={field.source || field.name}
        size="large" />;
      break;
    case "divider":
      return field.text ? (
        <DividerHeadLine {...field}
          name={field.source || field.name}
          flex={1} />
      ) : (
          <Divider key={index} {...field}
            name={field.source || field.name}
            flex={1} />
        );
      break;
    case "headline":
      return field.text ? (
        <HeadLine {...field}
          name={field.source || field.name}
          flex={1}>
          {field.text}
        </HeadLine>
      ) : (
          <Divider key={index} {...field}
            name={field.source || field.name}
            flex={1} />
        );
      break;
    case "referenceinput":
      console.log("referenceinput: ", field);
      return <Input type="hidden" key={index} {...field}
        name={field.source || field.name}
      />;
      break;
    case "hidden":
      return <Input type="hidden" key={index} {...field}
        name={field.source || field.name}
      />;
      break;
    default:
      return <Input size="large" key={index} {...field}
        name={field.source || field.name}
      />;
      break;
  }
};
const DinamicForm = ({ fields, ...props }) => {
  const getFields = () => {
    return fields.map((item, index) => Builder(item, index));
  };
  return (
    <Form autoSubmit={true} {...props}>
      {getFields()}
    </Form>
  );
};
export default DinamicForm; 